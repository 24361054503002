import { handle } from "redux-pack";
import { map, filter, get } from "lodash";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import api from "utils/api";

export const STRATEGY_REMOVE = "strategies/STRATEGY_REMOVE";
export const STRATEGY_ADD = "strategies/STRATEGY_ADD";
export const STRATEGY_SELECTION_UPDATE = "strategies/STRATEGY_SELECTION_UPDATE";
export const STRATEGY_VESSEL_OVERRIDE = "strategies/STRATEGY_VESSEL_OVERRIDE";
export const STRATEGY_NAME_UPDATE = "strategies/STRATEGY_NAME_UPDATE";
export const STRATEGIES_GET = "strategies/STRATEGIES_GET";
export const STRATEGY_GET = "strategies/STRATEGY_GET";
export const ACTIVITY_GET = "strategies/ACTIVITY_GET";
export const ACTIVITY_UPDATE_FORMULA = "strategies/ACTIVITY_UPDATE_FORMULA";
export const ACTIVITY_SAVE_CUSTOM_TASK = "strategies/ACTIVITY_SAVE_CUSTOM_TASK";
export const ACTIVITY_SAVE_GLOBAL_TASK = "strategies/ACTIVITY_SAVE_GLOBAL_TASK";
export const ACTIVITY_DELETE_CUSTOM_TASK = "strategies/ACTIVITY_DELETE_CUSTOM_TASK";
export const ACCORDION_KEYS_CHANGE = "strategies/ACCORDION_KEYS_CHANGE";
export const TASK_CLEAR_ERROR = "strategies/TASK_CLEAR_ERROR";
export const PREVIEW_TASK_FORMULA = "strategies/PREVIEW_TASK_FORMULA";
export const SAVE_TASK = "strategies/SAVE_TASK";

const initialState = {
  isLoading: false,
  strategy: null,
  strategyError: null,
  activityError: null,
  taskError: null,
  taskFormulaError: null,
  accordionOpenKeys: [],
  summary: [],
  activity: null,
};

function formatActivity(prevState, payload) {
  const { Name, facilityName, Activities, FacilityID, PhaseID, StrategyID, phase } = payload.data;
  const activity = Activities[0];

  return {
    ...prevState,
    activity: {
      ...activity,
      Tasks: activity.Tasks.map((t, i) => ({
        ...t,
        Sequence: i + 1,
      })),
      methodSelection: activity.StrategySelection.method,
      facilityName,
      strategyName: Name,
      phaseName: phase.Name,
      FacilityID,
      PhaseID,
      StrategyID,
    },
  };
}

function reducer(state = initialState, action) {
  const { type, payload } = action;
  // gather potential error message
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    case "@@router/LOCATION_CHANGE": {
      // This reducer case will reset the strategy state by checking to see if
      // facility is no longer included in the URL path. The thought is that facility
      // related routes need the facility to be present, otherwise we clear out the
      // facility and strategy state.
      const path = action.payload.location.pathname.split("/");

      if (!path.includes("facility")) {
        return initialState;
      }

      return state;
    }
    case ACCORDION_KEYS_CHANGE:
      return { ...state, accordionOpenKeys: payload };
    case TASK_CLEAR_ERROR:
      return { ...state, taskError: null };
    case STRATEGIES_GET:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategyError: "",
          strategy: null,
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not get strategies",
        }),
        success: prevState => ({ ...prevState, summary: payload.data }),
      });
    case STRATEGY_GET:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategyError: "",
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not get strategy",
        }),
        success: prevState => ({ ...prevState, strategy: payload.data }),
      });
    case ACTIVITY_SAVE_CUSTOM_TASK:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          taskError: "",
        }),
        failure: prevState => ({
          ...prevState,
          taskError: message || "Could not add the task",
        }),
        success: prevState => formatActivity(prevState, payload),
      });
    case ACTIVITY_SAVE_GLOBAL_TASK:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          taskError: "",
        }),
        failure: prevState => ({
          ...prevState,
          taskError: message || "Could not add the task",
        }),
        success: prevState => formatActivity(prevState, payload),
      });
    case ACTIVITY_DELETE_CUSTOM_TASK:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          taskError: "",
        }),
        failure: prevState => ({
          ...prevState,
          taskError: message || "Could not delete the task",
        }),
        success: prevState => formatActivity(prevState, payload),
      });
    case SAVE_TASK:
    case PREVIEW_TASK_FORMULA:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          taskFormulaError: "",
        }),
        failure: prevState => ({
          ...prevState,
          taskFormulaError: message || "Could not change the formula",
        }),
        success: prevState => formatActivity(prevState, payload),
      });
    case ACTIVITY_UPDATE_FORMULA:
    case ACTIVITY_GET:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          activityError: "",
          taskError: "",
          taskFormulaError: "",
        }),
        failure: prevState => ({
          ...prevState,
          activityError: message || "Could not get activity",
        }),
        success: prevState => formatActivity(prevState, payload),
      });
    case STRATEGY_REMOVE: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategyError: "",
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not remove the strategy",
        }),
        success: prevState => ({
          ...prevState,
          summary: map(prevState.summary, sum => ({
            ...sum,
            strategies: filter(sum.strategies, strat => strat.StrategyID !== action.meta.strategyID),
          })),
        }),
      });
    }
    case STRATEGY_SELECTION_UPDATE: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategyError: "",
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not update strategy selection",
        }),
        success: prevState => ({ ...prevState, strategy: payload.data }),
      });
    }
    case STRATEGY_VESSEL_OVERRIDE: {
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategyError: "",
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not override selected vessel",
        }),
        success: prevState => ({ ...prevState, strategy: payload.data }),
      });
    }
    case STRATEGY_NAME_UPDATE:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategyError: "",
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not update strategy name",
        }),
        success: prevState => ({
          ...prevState,
          strategy: {
            ...prevState.strategy,
            ...payload.data,
          },
        }),
      });
    case STRATEGY_ADD:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          strategy: null,
          strategyError: "",
        }),
        failure: prevState => ({
          ...prevState,
          strategyError: message || "Could not add strategy",
        }),
        success: prevState => ({ ...prevState, strategy: payload.data }),
      });
    default:
      return state;
  }
}

export const getStrategies = facilityID => ({
  type: STRATEGIES_GET,
  promise: api.strategies.getStrategies(facilityID),
});

export const getStrategy = strategyID => ({
  type: STRATEGY_GET,
  promise: api.strategies.getStrategy(strategyID),
});

export const getActivity = (strategyID, activityID) => ({
  type: ACTIVITY_GET,
  promise: api.strategies.getActivity(strategyID, activityID),
});

export const updateActivityFormula = (strategyID, activityID, payload) => ({
  type: ACTIVITY_UPDATE_FORMULA,
  promise: api.strategies.formulaOverride(strategyID, activityID, payload),
});

export const saveCustomTask = (strategyID, activityID, payload) => ({
  type: ACTIVITY_SAVE_CUSTOM_TASK,
  promise: api.strategies.saveCustomTask(strategyID, activityID, payload),
});

export const saveGlobalTask = (strategyID, activityID, payload) => ({
  type: ACTIVITY_SAVE_GLOBAL_TASK,
  promise: api.strategies.saveGlobalTask(strategyID, activityID, payload),
});

export const deleteCustomTask = (strategyID, activityID, payload) => ({
  type: ACTIVITY_DELETE_CUSTOM_TASK,
  promise: api.strategies.deleteCustomTask(strategyID, activityID, payload),
});

export const previewTaskFormula = (strategyID, activityID, payload) => ({
  type: PREVIEW_TASK_FORMULA,
  promise: api.strategies.previewFormula(strategyID, activityID, payload),
});

export const saveTask = (strategyID, activityID, payload) => ({
  type: SAVE_TASK,
  promise: api.strategies.saveTask(strategyID, activityID, payload),
});

export const removeStrategy = strategyID => ({
  type: STRATEGY_REMOVE,
  promise: api.strategies.removeStrategy(strategyID),
  meta: { strategyID },
});

export const addNewStrategy = (facilityID, phaseID) => ({
  type: STRATEGY_ADD,
  promise: api.strategies.addNewStrategy(facilityID, phaseID),
});

export const updateStrategySelection = (strategySelectionID, methodID) => ({
  type: STRATEGY_SELECTION_UPDATE,
  promise: api.strategies.updateStrategySelection(strategySelectionID, methodID),
});

export const vesselOverride = (StrategyID, ActivityID, VesselTypeID, RoleName, Included, Reason) => ({
  type: STRATEGY_VESSEL_OVERRIDE,
  promise: api.strategies.vesselOverride(StrategyID, ActivityID, VesselTypeID, RoleName, Included, Reason),
});

export const updateStrategyName = (strategyID, name) => ({
  type: STRATEGY_NAME_UPDATE,
  promise: api.strategies.updateStrategyName(strategyID, name),
});

export const accordionUpdateKeys = keys => ({
  type: ACCORDION_KEYS_CHANGE,
  payload: keys,
});

export const clearTaskErrors = () => ({ type: TASK_CLEAR_ERROR });

export default reducer;
