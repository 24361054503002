import PropTypes from "prop-types";
import TopsidesType from "types/topsides";
import SubstructureType from "types/substructure";

export default PropTypes.shape({
  LegCount: PropTypes.number.isRequired,
  PlatformID: PropTypes.number.isRequired,
  WaterDepth: PropTypes.number.isRequired,
  RiserCount: PropTypes.number.isRequired,
  ShallowCaissonCount: PropTypes.number.isRequired,
  DeepCaissonCount: PropTypes.number.isRequired,
  JtubeCount: PropTypes.number.isRequired,
  ConductorCount: PropTypes.number.isRequired,
  DrillCuttingPile: PropTypes.bool.isRequired,
  NumberOfBeds: PropTypes.number.isRequired,
  topsides: TopsidesType.isRequired,
  substructure: SubstructureType.isRequired,
});
