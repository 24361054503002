import React, { Component } from "react";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";
import { Alert, Icon, Spin, Table, Button, Tooltip, Select } from "antd";
import { ActivityWithTasksType } from "types/activity";
import HistoryType from "types/history";
import MainLayout from "layout/Main";
import TotalTimeCost from "components/TotalTimeCost";
import FieldDetailModal from "components/FieldDetailModal";
import CustomTaskModal from "components/CustomTaskModal";
import { withHomeIcon } from "utils/breadcrumbs/home";
import { colors } from "utils/shell-colors";
import classNames from "classnames";
import { formatCostDisplay } from "utils/format";

import "./style.less";
import GlobalTaskModal from "components/GlobalTaskModal";

const { Column } = Table;

const { Option } = Select;

class ActivityMethodDetails extends Component {
  static propTypes = {
    activity: ActivityWithTasksType,
    history: HistoryType.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        strategyid: PropTypes.string.isRequired,
        activityid: PropTypes.string.isRequired,
      }),
    }).isRequired,
    getActivity: PropTypes.func.isRequired,
    updateActivityFormula: PropTypes.func.isRequired,
    saveCustomTask: PropTypes.func.isRequired,
    saveGlobalTask: PropTypes.func.isRequired,
    deleteCustomTask: PropTypes.func.isRequired,
    taskError: PropTypes.string,
    taskFormulaError: PropTypes.string,
    clearTaskErrors: PropTypes.func.isRequired,
    previewTaskFormula: PropTypes.func.isRequired,
    saveTask: PropTypes.func.isRequired,
  };

  static defaultProps = {
    activity: null,
    taskError: "",
    taskFormulaError: "",
  };

  // customTaskCounter ensures that each time users click to open the Add Custom
  // Task or Edit Custom Task dialog, they get a fresh state, without any stale
  // state from a previous invocation such as uncontrolled input fields or
  // validation error messages. It's incremented each time the user opens the
  // dialog, and serves as the key that lets React know when to reuse an
  // existing component.
  state = {
    activeRow: null,
    showFieldModal: false,
    showCustomTaskModal: false,
    showGlobalTaskModal: false,
    fieldTitle: "",
    fieldKey: "",
    taskName: "",
    customTaskCounter: 0,
    toggleEditSequenceOrder: false,
  };

  componentDidMount() {
    const {
      match: {
        params: { strategyid, activityid },
      },
    } = this.props;

    this.props.getActivity(strategyid, activityid);
  }

  handleBackToStrategy = () => this.props.history.goBack();

  handleRowActive = record => () => this.setState({ activeRow: record.Sequence });

  handleRowInactive = () => this.setState({ activeRow: null });

  handleCloseFieldModal = () => this.setState({ showFieldModal: false });

  handleCloseTaskModal = () => {
    this.setState({ showCustomTaskModal: false });
    this.props.clearTaskErrors();
  };

  handleCloseGlobalTaskModal = () => {
    this.setState({ showGlobalTaskModal: false });
    this.props.clearTaskErrors();
  };

  handleAfterCloseModal = () => this.setState({ taskName: "", fieldKey: "", fieldTitle: "" });

  handleSaveCustomTask = async task => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;

    const res = await this.props.saveCustomTask(StrategyID, ActivityID, { MethodID, ...task });
    if (!res.error) {
      this.handleCloseTaskModal();
    }
  };

  handleSaveGlobalTask = async task => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;

    const res = await this.props.saveGlobalTask(StrategyID, ActivityID, { MethodID, ...task });
    if (!res.error) {
      this.handleCloseGlobalTaskModal();
    }
  };

  handlePreviewFormula = taskFormula => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;

    this.props.previewTaskFormula(StrategyID, ActivityID, { MethodID, tasks: [taskFormula] });
  };

  handleSaveFormula = taskFormula => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;

    this.props.saveTask(StrategyID, ActivityID, { MethodID, tasks: [taskFormula] });

    this.handleCloseFieldModal();
  };

  handleUpdateCustomTask = editedCustomTask => {
    this.setState({ draftCustomTask: editedCustomTask });
  };

  handleUpdateGlobalTask = editedGlobalTask => {
    this.setState({ draftGlobalTask: editedGlobalTask });
  };

  handleDeleteCustomTask = CustomTaskID => () => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;

    this.props.deleteCustomTask(StrategyID, ActivityID, { MethodID, CustomTaskID });
  };

  handleUpdateFormula = (TaskName, FormulaName, Value = null) => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;

    this.props.updateActivityFormula(StrategyID, ActivityID, { MethodID, TaskName, FormulaName, Value });

    this.handleCloseFieldModal();
  };

  handleGetActivity = () => {
    const {
      match: {
        params: { strategyid, activityid },
      },
    } = this.props;

    this.props.getActivity(strategyid, activityid);
  };

  handleRevertOverride = (TaskName, FormulaName) => () => {
    this.handleUpdateFormula(TaskName, FormulaName);
  };

  handleOpenCustomTaskModal = customTaskRecord => () => {
    this.setState(({ customTaskCounter }) => ({
      showCustomTaskModal: true,
      customTaskCounter: customTaskCounter + 1,
      draftCustomTask: customTaskRecord,
    }));
  };

  handleOpenGlobalTaskModal = () => {
    this.setState(({ showGlobalTaskModal }) => ({
      showGlobalTaskModal: !showGlobalTaskModal,
      draftGlobalTask: { name: "" },
    }));
  };

  renderFieldFormulaModal = (taskName, fieldTitle, fieldKey) => () =>
    this.setState({ showFieldModal: true, taskName, fieldTitle, fieldKey });

  renderTableCellWithTooltip = (text, field, taskName, fieldTitle, fieldKey, isCustomTask) =>
    text ? (
      <span>
        <div>
          {get(field, "originalValue") ? (
            <span className={classNames({ updated: true, error: !!field.error })}>
              {field.error ? "error" : text}
              <Tooltip placement="bottom" title="revert">
                <Icon type="undo" onClick={this.handleRevertOverride(taskName, fieldKey)} />
              </Tooltip>
            </span>
          ) : (
            <span className={classNames({ "custom-task-value": isCustomTask, error: !!field.error })}>
              {field.error ? "error" : text}
            </span>
          )}
          {!isCustomTask && (
            <Tooltip placement="bottom" title="edit">
              <Icon type="edit" theme="filled" onClick={this.renderFieldFormulaModal(taskName, fieldTitle, fieldKey)} />
            </Tooltip>
          )}
        </div>
        {get(field, "originalValue") && <div className="updated-label">updated</div>}
      </span>
    ) : (
      <span className="no-value">-</span>
    );

  handleUpdateTaskSequence = Name => Sequence => {
    const {
      StrategyID,
      ActivityID,
      StrategySelection: { MethodID },
    } = this.props.activity;
    this.props.saveTask(StrategyID, ActivityID, { MethodID, tasks: [{ Name, Sequence }] });
  };

  handleToggleTaskSequenceReorder = () => {
    this.setState(({ toggleEditSequenceOrder }) => ({
      toggleEditSequenceOrder: !toggleEditSequenceOrder,
    }));
  };

  render() {
    const { activity, taskError, taskFormulaError } = this.props;
    const {
      activeRow,
      showFieldModal,
      showCustomTaskModal,
      showGlobalTaskModal,
      fieldKey,
      taskName,
      fieldTitle,
      customTaskCounter,
      draftCustomTask,
      draftGlobalTask,
      toggleEditSequenceOrder,
    } = this.state;

    if (!activity) {
      return <Spin spinning={true} />;
    }

    const breadcrumbs = [
      withHomeIcon(),
      {
        title: `Strategies for: "${activity.facilityName}"`,
        path: `/facility/${activity.FacilityID}/strategies`,
      },
      {
        title: `Strategy “${activity.strategyName}” for Phase “${activity.phaseName}”`,
        path: `/facility/${activity.FacilityID}/strategies/${activity.StrategyID}/phase/${activity.PhaseID}/edit`,
      },
      {
        title: activity.Name || "",
      },
    ];

    // after a selection is made we will store taskName, fieldKey, and fieldTitle in state which will do the
    // field lookup here to display in our feild modal.
    const field = taskName && fieldKey ? activity.Tasks.find(task => task.Name === taskName).fields[fieldKey] : {};

    const selectOptions = activity.Tasks.filter(task => !task.CustomTaskID).map(task => task.Sequence);

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="activity-method-details-page">
        <div className="activity-method-details">
          <h1>{activity.Name}</h1>
          <h2>{activity.methodSelection.Name}</h2>
          {activity.Error && (
            <Alert
              message={activity.Error}
              description={<div className="preformatted-message">{activity.ErrorDetails}</div>}
              type="error"
              showIcon
            />
          )}
          <TotalTimeCost items={activity.Tasks} activeItem={activeRow} />
          <Table
            className="activity-method-details-table scrollable-table"
            rowClassName="details-row"
            pagination={false}
            dataSource={activity.Tasks.map(t => ({ ...t, key: t.Sequence }))}
            rowKey="Name"
            onRow={record => ({
              onMouseEnter: this.handleRowActive(record),
              onMouseLeave: this.handleRowInactive,
              "data-testid": "details-row",
            })}
            useFixedHeader={true}
          >
            <Column
              title="Sequence"
              dataIndex="Sequence"
              key="Sequence"
              render={(text, record) => (
                <div className="task-table-task-sequence">
                  <div
                    style={{
                      ...colors[(record.Sequence - 1) % colors.length],
                      width: "5px",
                      opacity: activeRow && record.Sequence !== activeRow ? "0.2" : "1",
                    }}
                    className={`task-sequence-marker task-sequence-marker-${record.Sequence}`}
                  />
                  {!record.CustomTaskID && toggleEditSequenceOrder ? (
                    <Select
                      value={text}
                      dropdownMatchSelectWidth={false}
                      onChange={this.handleUpdateTaskSequence(record.Name)}
                    >
                      {selectOptions.map(selectOption => (
                        <Option key={`${text}${selectOption}`} value={selectOption}>
                          {selectOption}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <span>{`${text}.`}</span>
                  )}
                </div>
              )}
              width="8em"
            />
            <Column
              title="Task"
              dataIndex="Name"
              key="Name"
              render={(text, record) => (
                <div className="task-table-task-name">
                  <span>{text}</span>
                  {!!record.CustomTaskID && (
                    <Tooltip placement="right" title="Edit Custom Task">
                      <Icon
                        type="edit"
                        data-testid="custom-task-edit"
                        onClick={this.handleOpenCustomTaskModal(record)}
                      />
                    </Tooltip>
                  )}
                  {!!record.CustomTaskID && (
                    <Tooltip placement="right" title="Delete Custom Task">
                      <Icon
                        type="delete"
                        data-testid="custom-task-delete"
                        onClick={this.handleDeleteCustomTask(record.CustomTaskID)}
                      />
                    </Tooltip>
                  )}
                </div>
              )}
            />
            <Column
              title="Level of Effort"
              className="task-detail"
              dataIndex="fields.LevelOfEffort.value"
              key="fields.LevelOfEffort.value"
              render={(text, record) =>
                this.renderTableCellWithTooltip(
                  text,
                  record.fields.LevelOfEffort,
                  record.Name,
                  "Level of Effort",
                  "LevelOfEffort",
                  !!record.CustomTaskID
                )
              }
              width="11em"
            />
            <Column
              title="DayRate"
              className="task-detail"
              dataIndex="fields.DayRate.value"
              key="fields.DayRate.value"
              render={(text, record) =>
                this.renderTableCellWithTooltip(
                  text,
                  record.fields.DayRate,
                  record.Name,
                  "Day Rate",
                  "DayRate",
                  !!record.CustomTaskID
                )
              }
              width="11em"
            />
            <Column
              title="PoB"
              className="task-detail"
              dataIndex="fields.RequiredPoB.value"
              key="fields.RequiredPoB.value"
              render={(text, record) =>
                this.renderTableCellWithTooltip(
                  text,
                  record.fields.RequiredPoB,
                  record.Name,
                  "PoB",
                  "RequiredPoB",
                  !!record.CustomTaskID
                )
              }
              width="9em"
            />
            <Column
              title="Duration"
              className="task-detail"
              dataIndex="fields.Duration.value"
              key="fields.Duration.value"
              render={(text, record) =>
                this.renderTableCellWithTooltip(
                  text,
                  record.fields.Duration,
                  record.Name,
                  "Duration",
                  "Duration",
                  !!record.CustomTaskID
                )
              }
              width="9em"
            />
            <Column
              title="Cost"
              className="task-detail"
              dataIndex="fields.Cost.value"
              key="fields.Cost.value"
              render={(text, record) =>
                this.renderTableCellWithTooltip(
                  formatCostDisplay(text),
                  record.fields.Cost,
                  record.Name,
                  "Cost",
                  "Cost",
                  !!record.CustomTaskID
                )
              }
              width="9em"
            />
          </Table>
          <div className="activity-method-details-controls">
            <div>
              <Button className="custom-task" onClick={this.handleOpenCustomTaskModal({})}>
                Add a Custom Task
              </Button>
              <Button className="global-task" onClick={this.handleOpenGlobalTaskModal}>
                Add a Global Task
              </Button>
              <Button className="reorder-sequence" onClick={this.handleToggleTaskSequenceReorder}>
                {toggleEditSequenceOrder ? "Done Reordering" : "Reorder Task Sequence"}
              </Button>
            </div>
            <Button onClick={this.handleBackToStrategy} type="primary">
              Return to Strategy
            </Button>
          </div>
        </div>
        {!isEmpty(field) && (
          <FieldDetailModal
            onConfirm={this.handleUpdateFormula}
            onPreviewFormula={this.handlePreviewFormula}
            onSaveFormula={this.handleSaveFormula}
            showModal={showFieldModal}
            onCancel={this.handleCloseFieldModal}
            field={field}
            fieldTitle={fieldTitle}
            taskName={taskName}
            fieldKey={fieldKey}
            onAfterCloseModal={this.handleAfterCloseModal}
            FacilityID={this.props.activity.FacilityID}
            onGetActivity={this.handleGetActivity}
            taskFormulaError={taskFormulaError}
          />
        )}
        {draftCustomTask && (
          <CustomTaskModal
            showModal={showCustomTaskModal}
            onCancel={this.handleCloseTaskModal}
            onOk={this.handleSaveCustomTask}
            taskError={taskError}
            draftTask={draftCustomTask}
            onUpdateTask={this.handleUpdateCustomTask}
            key={customTaskCounter}
          />
        )}
        {draftGlobalTask && (
          <GlobalTaskModal
            showModal={showGlobalTaskModal}
            onCancel={this.handleCloseGlobalTaskModal}
            onOk={this.handleSaveGlobalTask}
            taskError={taskError}
            draftTask={draftGlobalTask}
            onUpdateTask={this.handleUpdateGlobalTask}
          />
        )}
      </MainLayout>
    );
  }
}

export default ActivityMethodDetails;
