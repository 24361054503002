import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { middleware as reduxPackMiddleware } from "redux-pack";
import reducer from "redux/reducer";

// custom middlewares
import logger from "redux-logger";
import logoutMiddleware from "redux/middleware/logout";
import addComplexMiddleware from "redux/middleware/addComplex";
import strategyAddMiddleware from "redux/middleware/strategyAdd";
import addToPortfolioMiddleware from "redux/middleware/addToPortfolio";
import createFacilityMiddleware from "redux/middleware/createFacility";
import adminResetEnvMiddleware from "redux/middleware/adminResetEnv";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();

const middlewares = [
  reduxPackMiddleware,
  routerMiddleware(history),
  logoutMiddleware,
  addComplexMiddleware,
  strategyAddMiddleware,
  addToPortfolioMiddleware,
  createFacilityMiddleware,
  adminResetEnvMiddleware,
];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export default function configureStore(preloadedState) {
  const store = createStore(reducer(history), preloadedState, composeEnhancer(applyMiddleware(...middlewares)));

  return store;
}
