import { RESET_ENV_DATA, resetReduxState } from "redux/ducks/admin";
import { push } from "connected-react-router";
import { packSuccess } from "utils/redux-pack";

const adminResetEnv = ({ dispatch }) => next => action => {
  if (packSuccess(action, RESET_ENV_DATA)) {
    dispatch(push("/"));
    dispatch(resetReduxState());
  }
  return next(action);
};

export default adminResetEnv;
