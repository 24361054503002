import React from "react";
import PropTypes from "prop-types";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import {
  Map as LeafletMap,
  TileLayer as LeafletTileLayer,
  Popup as LeafletPopup,
  Marker as LeafletMarker,
} from "react-leaflet";
// The leaflet-defaulticon-compatibility plugin is required when using an asset
// packager that modifies URLs in CSS, which affects us.
// eslint's unresolvable-import check is disabled when importing this plugin,
// because its package.json includes a "jsnext:main" line that points to a file
// in its src/ directory, and that directory isn't shipped in the npm package.
// The import works anyway because the normal "main" line points a file in dist/,
// which is shipped.
import "leaflet-defaulticon-compatibility"; // eslint-disable-line import/no-unresolved
import MarkerClusterGroup from "react-leaflet-markercluster";
import LabeledItem from "components/LabeledItem";
import { isNumber } from "lodash";
import { FacilitiesType } from "types/facility";

import "./style.less";

const FacilitiesMap = ({ filteredFacilities, renderMarkerFooter }) => {
  const allMapCoordinates = filteredFacilities
    .map(facility => [facility.lat, facility.lng])
    .filter(item => item[0] !== null && item[1] !== null && !Number.isNaN(item[0]) && !Number.isNaN(item[1]));

  // rendering react-leaflet is not supported for testing. This conditional will allow us to
  // test the map functionality without rendering react-leaflet.
  if (process.env.NODE_ENV === "test") {
    return (
      <div data-testid="facility-map">
        {allMapCoordinates.map(([lat, lng], i) => (
          <div key={`map-coordinate-${lat + lng + i}`}>
            <div>{lat}</div>
            <div>{lng}</div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <LeafletMap
      zoom={5}
      maxZoom={10}
      bounds={allMapCoordinates.length ? allMapCoordinates : null}
      boundsOptions={{ padding: [50, 50] }}
      className="facilities-map"
    >
      <LeafletTileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <MarkerClusterGroup>
        {filteredFacilities.map(
          facility =>
            isNumber(facility.lat) &&
            isNumber(facility.lng) && (
              <LeafletMarker position={[facility.lat, facility.lng]} key={facility.FacilityID}>
                <LeafletPopup className="facilities-map-popup">
                  <h4>{facility.Name}</h4>
                  <LabeledItem label="COP Date:" value={facility.CoPDate} />
                  <LabeledItem
                    label="Water Depth:"
                    value={facility.waterDepth || "—"}
                    singularUnit="Meter"
                    pluralUnits="Meters"
                  />
                  <LabeledItem
                    label="Topsides Total Weight:"
                    value={facility.topsidesTotalWeight || "—"}
                    singularUnit="Tonne"
                    pluralUnits="Tonnes"
                  />
                  <LabeledItem
                    label="Substructure Total Jacket Weight:"
                    value={facility.substructureTotalJacketWeight || "—"}
                    singularUnit="Tonne"
                    pluralUnits="Tonnes"
                  />
                  <LabeledItem label="Function:" value={facility.Function} />
                  {renderMarkerFooter(facility)}
                </LeafletPopup>
              </LeafletMarker>
            )
        )}
      </MarkerClusterGroup>
    </LeafletMap>
  );
};

FacilitiesMap.propTypes = {
  filteredFacilities: FacilitiesType.isRequired,
  renderMarkerFooter: PropTypes.func.isRequired,
};

export default FacilitiesMap;
