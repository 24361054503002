import React, { Component } from "react";
import { Button, Slider } from "antd";
import PropTypes from "prop-types";
import "./style.less";

// `limits` sets the endpoints of the range for each filter control.
// The FacilitiesFilter.applyToList class method executes the actual filtering.
const limits = {
  COP: [2018, 2040],
  topsidesTotalWeight: [100, 20000],
  substructureTotalJacketWeight: [100, 10000],
  waterDepth: [10, 2000],
};

export const FiltersType = PropTypes.shape({
  COP: PropTypes.arrayOf(PropTypes.number.isRequired),
  topsidesTotalWeight: PropTypes.arrayOf(PropTypes.number.isRequired),
  substructureTotalJacketWeight: PropTypes.arrayOf(PropTypes.number.isRequired),
  waterDepth: PropTypes.arrayOf(PropTypes.number.isRequired),
});

const SliderForNamedField = ({ filters, name, units, onFilterRangeChange }) => {
  const [minLimit, maxLimit] = limits[name];
  // if no filter specified for this field, default to full range
  const filterValues = filters[name] !== null ? filters[name] : limits[name];
  // When units are given, use toLocaleString() to add thousands separators.
  // Unitless values like years shouldn't have the thousands separator.
  return (
    <Slider
      range
      marks={
        units
          ? {
              [minLimit]: `${minLimit.toLocaleString()} ${units}`,
              [maxLimit]: `${maxLimit.toLocaleString()} ${units}`,
            }
          : { [minLimit]: minLimit, [maxLimit]: maxLimit }
      }
      min={minLimit}
      max={maxLimit}
      step={1}
      onChange={newRange => {
        onFilterRangeChange(name, newRange);
      }}
      value={filterValues}
    />
  );
};

SliderForNamedField.propTypes = {
  filters: FiltersType.isRequired,
  name: PropTypes.string.isRequired,
  units: PropTypes.string,
  onFilterRangeChange: PropTypes.func.isRequired,
};

SliderForNamedField.defaultProps = {
  units: "",
};

class FacilitiesFilter extends Component {
  static propTypes = {
    // props.filters contains previously-applied filters
    // (and is overridden by state.filters when there are unapplied changes)
    filters: FiltersType.isRequired,
    onFilterChange: PropTypes.func.isRequired,
  };

  static applyToList(facilitiesList, filters, searchText) {
    // Return all facilities that aren't rejected by any enabled filter ranges
    // or search text.
    // The comparisons against "undefined" and "null" disable that filter for a
    // particular record if that record is missing the field, so that unknown values
    // don't cause a record to never match. Since the current data is missing
    // several fields, this is needed to allow the filter to return any data at all.
    // TODO: revisit this once we have a fuller dataset; at that point we may want
    // to enforce that any field with a filter active must be valued, so that any
    // facilities for which the field is missing will be hidden.
    return facilitiesList.filter(item => {
      // Check for rejection based on name search text.
      if (searchText && !item.Name.toLowerCase().startsWith(searchText.toLowerCase())) {
        return false;
      }

      // Check for rejection based on the year part of the CoPDate.
      // Note that the CoPDate field is stored as a full ISO date string. To
      // compare the year (respecting the year as written, without risking a
      // timezone conversion changing it) take the initial year part--the first
      // 4 chars--and compare numerically to the requested start and end years.
      // This also ensures that any date within the end year passes the filter
      // as expected.
      if (filters.COP !== null && item.CoPDate !== undefined && item.CoPDate !== null) {
        const CoPYear = Number(item.CoPDate.substring(0, 4));
        if (filters.COP[0] > CoPYear || filters.COP[1] < CoPYear) {
          return false;
        }
      }

      // Check for rejection based on total weight.
      if (
        filters.topsidesTotalWeight !== null &&
        item.topsidesTotalWeight !== undefined &&
        item.topsidesTotalWeight !== null &&
        (filters.topsidesTotalWeight[0] > item.topsidesTotalWeight ||
          filters.topsidesTotalWeight[1] < item.topsidesTotalWeight)
      ) {
        return false;
      }

      // Check for rejection based on total jacket weight.
      if (
        filters.substructureTotalJacketWeight !== null &&
        item.substructureTotalJacketWeight !== undefined &&
        item.substructureTotalJacketWeight !== null &&
        (filters.substructureTotalJacketWeight[0] > item.substructureTotalJacketWeight ||
          filters.substructureTotalJacketWeight[1] < item.substructureTotalJacketWeight)
      ) {
        return false;
      }

      // Check for rejection based on water depth.
      if (
        filters.waterDepth !== null &&
        item.waterDepth !== undefined &&
        item.waterDepth !== null &&
        (filters.waterDepth[0] > item.waterDepth || filters.waterDepth[1] < item.waterDepth)
      ) {
        return false;
      }

      // All active filters passed.
      return true;
    });
  }

  state = {
    // state.filters is either a full set of filters that are as yet unapplied
    // (enabling the Apply button, which will push them to an ancestor)
    // or null if they haven't been edited & the Apply button should be disabled.
    filters: null,
  };

  // `emptyFilters` is the base set of filters, used as a new or cleared filter object.
  // It maps each known filter key to null, meaning no filtering for that key.
  // The filter keys come from `limits` above. The resulting object has the same
  // shape as `limits`, but all values are null.
  static getEmptyFilters = () => {
    const emptyFilters = {};
    Object.keys(limits).forEach(key => {
      emptyFilters[key] = null;
    });
    return emptyFilters;
  };

  handleFilterRangeChange = (name, newRange) => {
    const { filters } = this.state;
    this.setState({
      filters: {
        ...FacilitiesFilter.getEmptyFilters(),
        ...this.props.filters,
        ...filters,
        [name]: newRange,
      },
    });
  };

  handleClear = () => {
    this.props.onFilterChange(this.getEmptyFilters());
    this.setState({ filters: null });
  };

  handleApply = () => {
    if (this.state.filters) {
      const newFilters = { ...this.state.filters };
      Object.entries(newFilters).forEach(([filterName, filterValues]) => {
        if (filterValues) {
          if (filterValues[0] === limits[filterName][0]) {
            newFilters[filterName][0] = -Infinity;
          }
          if (filterValues[1] === limits[filterName][1]) {
            newFilters[filterName][1] = Infinity;
          }
        }
      });
      this.props.onFilterChange(newFilters);
      this.setState({ filters: null });
    }
  };

  render() {
    // state.filters overrides original props.filters if non-null
    // (meaning that there are unapplied changes)
    const filters = this.state.filters || this.props.filters;
    const hasAppliedFilters = !!Object.values(this.props.filters).find(item => item !== null);
    return (
      <div className="facility-filters" data-testid="facility-filters">
        <div className="clear-button-container">
          <Button className="clear-button" disabled={!hasAppliedFilters} onClick={this.handleClear} type="danger">
            Clear Filters
          </Button>
        </div>
        <div className="apply-button-container">
          <Button
            className="apply-button"
            disabled={this.state.filters === null}
            onClick={this.handleApply}
            type="primary"
          >
            Apply
          </Button>
        </div>
        <div>
          <h4>COP</h4>
          <SliderForNamedField filters={filters} name="COP" onFilterRangeChange={this.handleFilterRangeChange} />
        </div>
        <div>
          <h4>Topsides Total Weight</h4>
          <SliderForNamedField
            filters={filters}
            name="topsidesTotalWeight"
            units="tonnes"
            onFilterRangeChange={this.handleFilterRangeChange}
          />
        </div>
        <div>
          <h4>Total Jacket Weight</h4>
          <SliderForNamedField
            filters={filters}
            name="substructureTotalJacketWeight"
            units="tonnes"
            onFilterRangeChange={this.handleFilterRangeChange}
          />
        </div>
        <div>
          <h4>Water Depth</h4>
          <SliderForNamedField
            filters={filters}
            name="waterDepth"
            units="meters"
            onFilterRangeChange={this.handleFilterRangeChange}
          />
        </div>
      </div>
    );
  }
}

export default FacilitiesFilter;
