import { ADD_TO_PORTFOLIO } from "redux/ducks/portfolio";
import { push } from "connected-react-router";
import { packSuccess } from "utils/redux-pack";

const strategyAddMiddleware = ({ dispatch }) => next => action => {
  if (packSuccess(action, ADD_TO_PORTFOLIO)) {
    dispatch(push("/"));
  }

  next(action);
};

export default strategyAddMiddleware;
