import React, { Component } from "react";
import { Form, Icon, Input, Button, Alert } from "antd";
import { isEmpty } from "lodash";
import AntFormType from "types/ant-form";
import PasswordHelp from "components/PasswordHelp";
import PropTypes from "prop-types";
import "./style.less";

const FormItem = Form.Item;

class ChangePassword extends Component {
  static propTypes = {
    form: AntFormType.isRequired,
    onChangePassword: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: "",
  };

  state = {
    confirmDirty: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onChangePassword(values.password);
      }
    });
  };

  handleBlur = e => {
    const { value } = e.target;
    if (value && !this.state.confirmDirty) {
      this.setState({ confirmDirty: true });
    }
  };

  componentDidMount() {
    this.props.form.getFieldInstance("password").focus();
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Your passwords must match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const {
      form: { getFieldDecorator },
      error,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="change-password-form">
        {!isEmpty(error) && <Alert message={error} type="error" showIcon />}
        <PasswordHelp />
        <FormItem>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Please input your Password!" },
              {
                validator: this.validatetoNextPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("confirm", {
            rules: [
              {
                required: true,
                message: "Please confirm your Password!",
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Confirm Password"
              onBlur={this.handleBlur}
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="change-password-button">
            Change Password
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(ChangePassword);
