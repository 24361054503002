import { Auth } from "aws-amplify";
import MockAuth from "config/mock-auth";

export default ["development", "test"].includes(process.env.NODE_ENV || "development") ? MockAuth : Auth;

export const completeNewPasswordChallenge = (cognitoUser, password) =>
  new Promise((resolve, reject) =>
    cognitoUser.completeNewPasswordChallenge(
      password,
      {},
      {
        onSuccess(response) {
          resolve(response);
        },
        onFailure(err) {
          reject(err);
        },
      }
    )
  );
