import React, { Component } from "react";
import { Form, Icon, Input, Button } from "antd";
import AntFormType from "types/ant-form";
import PropTypes from "prop-types";
import "./style.less";

const FormItem = Form.Item;

class RequestForgotPassword extends Component {
  static propTypes = {
    form: AntFormType.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values.username);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="forgot-password-form">
        <FormItem>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Username" />)}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Request Reset
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(RequestForgotPassword);
