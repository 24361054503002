import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Button, Modal, notification, Table, Select } from "antd";
import MainLayout from "layout/Main";
import { withHomeIcon } from "utils/breadcrumbs/home";
import { loadVesselTypesWithCapabilities, loadCapabilities, updateVesselType } from "redux/ducks/vessel-types";
import { loadCountries, loadCountry } from "redux/ducks/countries";
import { VesselWithCapabilitiesType, CapabilityType } from "types/vessel";
import { getCountries } from "redux/selectors/countries";
import CountryType from "types/country";
import VesselEditModal from "components/VesselEditModal";
import api from "utils/api";

import "./style.less";

const { Option } = Select;

class Vessels extends Component {
  static propTypes = {
    vessels: PropTypes.arrayOf(VesselWithCapabilitiesType),
    capabilities: PropTypes.arrayOf(CapabilityType),
    loading: PropTypes.bool.isRequired,
    country: CountryType.isRequired,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    loadVesselTypesWithCapabilities: PropTypes.func.isRequired,
    loadCapabilities: PropTypes.func.isRequired,
    loadCountries: PropTypes.func.isRequired,
    loadCountry: PropTypes.func.isRequired,
    updateVesselType: PropTypes.func.isRequired,
  };

  static defaultProps = {
    vessels: [],
    capabilities: [],
  };

  state = {
    showVesselEditModal: false,
    vesselTypeToEdit: {},
  };

  componentDidMount() {
    const countryID = localStorage.getItem("CountryID");
    if (countryID) {
      this.props.loadCountry(countryID);
      this.props.loadVesselTypesWithCapabilities(countryID);
    }
    this.props.loadCountries();
    this.props.loadCapabilities();
  }

  handleRowClick = record => {
    this.setState({ showVesselEditModal: true, vesselTypeToEdit: record });
  };

  handleCloseModal = () => this.setState({ showVesselEditModal: false });

  handleAfterCloseModal = () => this.setState({ vesselTypeToEdit: {} });

  handleVesselsDownload = () => {
    api
      .downloadVessels()
      .then(filename => {
        notification.success({
          message: "Download complete",
          description: `The file “${filename}” has been downloaded.`,
        });
      })
      .catch(err => {
        Modal.error({
          title: "Download failed",
          content: String(err.message),
        });
      });
  };

  handleVesselRatesDownload = () => {
    api
      .downloadVesselRates()
      .then(filename => {
        notification.success({
          message: "Download complete",
          description: `The file “${filename}” has been downloaded.`,
        });
      })
      .catch(err => {
        Modal.error({
          title: "Download failed",
          content: String(err.message),
        });
      });
  };

  handleUpdateVesselType = async (VesselTypeID, CountryID, edits) => {
    await this.props.updateVesselType(VesselTypeID, CountryID, edits);

    this.handleCloseModal();
  };

  handleCountrySelect = value => {
    this.props.loadCountry(value);
    this.props.loadVesselTypesWithCapabilities(value);
  };

  columns = () => [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      width: "18em",
      render: (text, record) => <span>{`(${record.Code}) - ${text}`}</span>,
    },
    {
      title: "Day Rate",
      dataIndex: "DayRate",
      key: "DayRate",
      width: "8em",
      render: DayRate => <span>{DayRate || "-"}</span>,
    },
    {
      title: "Lift Capacity",
      dataIndex: "liftCapacity",
      key: "liftCapacity",
      width: "10em",
      render: liftCapacity => <span>{liftCapacity || "-"}</span>,
    },
    {
      title: "Positioning Duration",
      dataIndex: "positioningDuration",
      key: "positioningDuration",
      width: "10em",
    },
    {
      title: "Quantity",
      dataIndex: "Quantity",
      key: "Quantity",
      width: "10em",
      render: Quantity => <span>{Quantity !== null ? Quantity : "-"}</span>,
    },
    {
      title: "Capabilities",
      dataIndex: "capabilities",
      key: "capabilites",
      width: "25em",
      render: capabilities => <span>{capabilities.join(", ")}</span>,
    },
  ];

  render() {
    const { vessels, loading, capabilities, countries, country } = this.props;
    const { showVesselEditModal, vesselTypeToEdit } = this.state;

    const breadcrumbs = [withHomeIcon(), { title: "Settings" }, { title: "Vessels" }];

    const data = vessels.map(vessel => ({
      key: vessel.VesselTypeID,
      ...vessel,
    }));

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="vessel-capabilities-page">
        <div className="vessel-capabilities-content">
          <div className="vessel-capabilities-content-title">
            <div className="title-inner">
              <h1>Vessels</h1>
              <span className="buttons">
                <Button icon="download" onClick={this.handleVesselsDownload}>
                  Download Vessels.csv
                </Button>
                <Button icon="download" onClick={this.handleVesselRatesDownload}>
                  Download Vessel Rates.csv
                </Button>
              </span>
            </div>

            <div className="country-select-block">
              <h3>Show Day Rates for:</h3>
              <Select
                dropdownMatchSelectWidth={false}
                className="country-select"
                value={country.CountryID}
                onSelect={this.handleCountrySelect}
              >
                {countries.map(c => (
                  <Option key={c.CountryID} value={c.CountryID}>
                    {c.Name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            loading={loading}
            className="vessel-capabilities__table scrollable-table"
            dataSource={data}
            pagination={false}
            useFixedHeader={true}
            columns={this.columns()}
            onRow={record => ({
              onClick: () => this.handleRowClick(record),
            })}
          />
        </div>
        {!isEmpty(vesselTypeToEdit) && (
          <VesselEditModal
            onCancel={this.handleCloseModal}
            onAfterCloseModal={this.handleAfterCloseModal}
            vesselType={vesselTypeToEdit}
            showModal={showVesselEditModal}
            capabilities={capabilities}
            onConfirm={this.handleUpdateVesselType}
            country={country}
          />
        )}
      </MainLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadVesselTypesWithCapabilities: CountryID => dispatch(loadVesselTypesWithCapabilities(CountryID)),
    loadCapabilities: () => dispatch(loadCapabilities()),
    loadCountries: () => dispatch(loadCountries()),
    loadCountry: countryID => dispatch(loadCountry(countryID)),
    updateVesselType: (VesselTypeID, CountryID, edits) => dispatch(updateVesselType(VesselTypeID, CountryID, edits)),
  };
}

function mapStateToProps({ vesselTypes, countries }) {
  return {
    countries: getCountries(countries),
    country: countries.country,
    vessels: vesselTypes.vesselsWithCapabilities || [],
    loading: vesselTypes.loading,
    error: vesselTypes.error,
    capabilities: vesselTypes.capabilities,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vessels);
