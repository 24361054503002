import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FacilitiesList from "components/FacilitiesList";
import Main from "layout/Main";
import { withRouter } from "react-router-dom";
import { FacilitiesType, FacilityFunctionPropType } from "types/facility";
import CountryType from "types/country";
import ComplexType from "types/complex";
import SubArchetypeType from "types/subArchetype";
import { createFacility, loadFacilitiesForCountry } from "redux/ducks/facilities";
import { loadCountries, loadCountry } from "redux/ducks/countries";
import { addComplex, loadComplexes, processAddComplexResponse } from "redux/ducks/complexes";
import { getCountries } from "redux/selectors/countries";
import { getComplexes } from "redux/selectors/complexes";
import { loadFacilityFunctions } from "redux/ducks/facility-functions";
import { loadSubArchetypes } from "redux/ducks/sub-archetypes";
import { getFacilitiesForCountry, getFacilitiesError, areFacilitiesLoading } from "redux/selectors/facilities";
import { addToPortfolio } from "redux/ducks/portfolio";
import { withHomeIcon } from "utils/breadcrumbs/home";

import "./style.less";

class Facilities extends Component {
  static propTypes = {
    addComplex: PropTypes.func.isRequired,
    country: CountryType.isRequired,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    complexes: PropTypes.arrayOf(ComplexType).isRequired,
    loadCountries: PropTypes.func.isRequired,
    loadComplexes: PropTypes.func.isRequired,
    addToPortfolio: PropTypes.func.isRequired,
    loadFacilities: PropTypes.func.isRequired,
    createFacility: PropTypes.func.isRequired,
    loadCountry: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadFacilityFunctions: PropTypes.func.isRequired,
    facilityFunctions: PropTypes.arrayOf(FacilityFunctionPropType).isRequired,
    loadSubArchetypes: PropTypes.func.isRequired,
    subArchetypes: PropTypes.arrayOf(SubArchetypeType).isRequired,
    facilities: FacilitiesType.isRequired,
    isAddingToPortfolio: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const countryID = localStorage.getItem("CountryID");

    if (countryID) {
      this.props.loadCountry(countryID);
      this.props.loadFacilities(countryID);
    }

    this.props.loadFacilityFunctions();
    this.props.loadCountries();
    this.props.loadComplexes();
    this.props.loadSubArchetypes();
  }

  handleCountrySelect = CountryID => {
    localStorage.setItem("CountryID", CountryID);
    this.props.loadCountry(CountryID);
    this.props.loadFacilities(CountryID);
  };

  handleAddToPortfolio = facilityIDs => {
    this.props.addToPortfolio(facilityIDs);
  };

  handleCreateFacility = facility => {
    this.props.createFacility(facility);
  };

  // handleRefreshFacilities refreshes the existing facility list
  // (for a previously-selected country, if any), useful for when the
  // list may have changed, such as after importing a Facilities.csv file.
  // Complexes and FacilityFunctions may also be added as a side effect
  // of loading a Facilities.csv file, so refresh them too. Countries, on
  // the other hand, cannot be added as a side effect, so don't attempt to
  // reload them.
  handleRefreshFacilities = () => {
    if (this.props.country) {
      this.props.loadFacilities(this.props.country.CountryID);
    }
    this.props.loadComplexes();
    this.props.loadFacilityFunctions();
  };

  render() {
    const {
      isAddingToPortfolio,
      loading,
      facilities,
      country,
      facilityFunctions,
      countries,
      complexes,
      subArchetypes,
    } = this.props;

    const breadcrumbs = [withHomeIcon(), { title: "Add Facilities" }];

    return (
      <Main breadcrumbs={breadcrumbs} className="facilities-list-page card-layout">
        <FacilitiesList
          countries={countries}
          complexes={complexes}
          onAddComplex={this.props.addComplex}
          onCountrySelect={this.handleCountrySelect}
          onAddToPortfolio={this.handleAddToPortfolio}
          onCreateFacility={this.handleCreateFacility}
          onRefreshFacilities={this.handleRefreshFacilities}
          facilitiesLoading={loading}
          facilities={facilities}
          subArchetypes={subArchetypes}
          country={country}
          facilityFunctions={facilityFunctions}
          isAddingToPortfolio={isAddingToPortfolio}
        />
      </Main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addComplex: (countryID, complexName) => processAddComplexResponse(dispatch(addComplex(countryID, complexName))),
    loadCountries: () => dispatch(loadCountries()),
    createFacility: facility => dispatch(createFacility(facility)),
    loadComplexes: () => dispatch(loadComplexes()),
    loadFacilities: countryID => dispatch(loadFacilitiesForCountry(countryID)),
    loadCountry: countryID => dispatch(loadCountry(countryID)),
    loadFacilityFunctions: () => dispatch(loadFacilityFunctions()),
    loadSubArchetypes: () => dispatch(loadSubArchetypes()),
    addToPortfolio: facilityIDs => dispatch(addToPortfolio(facilityIDs)),
  };
}

function mapStateToProps({ facilities, countries, complexes, facilityFunctions, portfolio, subArchetypes }) {
  return {
    countries: getCountries(countries),
    complexes: getComplexes(complexes),
    loading: areFacilitiesLoading(facilities),
    error: getFacilitiesError(facilities),
    facilities: getFacilitiesForCountry(facilities),
    country: countries.country,
    facilityFunctions: facilityFunctions.list || [],
    subArchetypes: subArchetypes.list || [],
    isAddingToPortfolio: portfolio.isAddingToPortfolio,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Facilities)
);
