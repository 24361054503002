import PropTypes from "prop-types";

export const TopsidesModulesPropType = PropTypes.shape({
  Weight: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  ExtraModule: PropTypes.bool.isRequired,
});

export const TopsideInstallationMethodPropType = PropTypes.shape({
  Name: PropTypes.string,
  TopsidesOriginalInstallationMethodID: PropTypes.number,
});

export default PropTypes.shape({
  topsidesOriginalInstallationMethod: PropTypes.shape({
    Name: PropTypes.string.isRequired,
  }),
  Weight: PropTypes.number.isRequired,
  PieceSmallWeight: PropTypes.number.isRequired,
  topsidesModules: PropTypes.arrayOf(TopsidesModulesPropType),
  totalWeight: PropTypes.number.isRequired,
  maxPrimaryModuleWeight: PropTypes.number.isRequired,
  maxExtraModuleWeight: PropTypes.number.isRequired,
});
