import React, { Component } from "react";
import { Layout } from "antd";
import PropTypes from "prop-types";
import GlobalHeader from "components/GlobalHeader";
import { BreadcrumbsType } from "types/breadcrumbs";
import classNames from "classnames";

import "./style.less";

const { Content } = Layout;

class Main extends Component {
  static propTypes = {
    breadcrumbs: BreadcrumbsType.isRequired,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    className: "",
  };

  generateTimeOfDayGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 4 || hour > 22) return "Good night";
    if (hour <= 11) return "Good morning";
    if (hour >= 12 && hour < 17) return "Good afternoon";
    if (hour >= 17) return "Good evening";

    return "Hello";
  };

  render() {
    const { breadcrumbs, children, className } = this.props;
    return (
      <div className="App">
        <Layout className={classNames("main-layout", className)}>
          <GlobalHeader breadcrumbs={breadcrumbs} timeOfDayGreeting={this.generateTimeOfDayGreeting()} />
          <Content className="main-layout-content">{children}</Content>
        </Layout>
      </div>
    );
  }
}

export default Main;
