const mockSession = {
  accessToken: {
    jwtToken: "test-token",
    payload: {
      username: "dingo",
      userGroups: [],
    },
  },
  refreshToken: {},
};

const mockSignIn = {
  username: "dingo",
  signInUserSession: {
    ...mockSession,
  },
};

const MockAuth = {};

MockAuth.currentSession = () =>
  new Promise((resolve, reject) => {
    if (window.localStorage.getItem("dingo.accessToken") === "test-token") {
      resolve(mockSession);
    } else {
      // The following eslint disabler is required to attain parity with AWS Amplify
      /* eslint-disable-next-line prefer-promise-reject-errors */
      reject("not authenticated");
    }
  });

MockAuth.signIn = (username, password) =>
  new Promise((resolve, reject) => {
    if (username === "dingo" && password === "dingostar") {
      window.localStorage.setItem("dingo.accessToken", "test-token");
      resolve(mockSignIn);
    } else if (username === "dingo") {
      reject(new Error("Incorrect username or password."));
    } else {
      reject(new Error("User does not exist."));
    }
  });

MockAuth.signOut = () =>
  new Promise(resolve => {
    window.localStorage.removeItem("dingo.accessToken");
    resolve();
  });

export default MockAuth;
