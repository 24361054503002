import React, { Component } from "react";
import { isEmpty, capitalize } from "lodash";
import PropTypes from "prop-types";
import { Avatar, Breadcrumb, Menu, Dropdown, Button, Icon, Modal, Input, Spin } from "antd";
import logo from "assets/dingo-logo.svg";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { BreadcrumbsType } from "types/breadcrumbs";
import { logout } from "redux/ducks/auth";
import { resetEnvData } from "redux/ducks/admin";
import HistoryType from "types/history";

import "./style.less";

const userSafeguardInputTarget = "RESET";

const { Item } = Breadcrumb;

const MenuContent = ({ onLogout }) => (
  <Menu>
    <Menu.Item>
      <Button type="danger" onClick={onLogout} className="global-header__log-out">
        Log out
      </Button>
    </Menu.Item>
  </Menu>
);

MenuContent.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

const SettingsMenu = ({ onToggleDataResetModal }) => (
  <Menu data-testid="settings-menu">
    <Menu.Item>
      <Link to="/settings/timeframes">Activity Timeframes</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/countries/rates">Country Rates</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/settings/facilities">Facilities</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/settings/overhead-costs">Overhead Costs</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/settings/vessels">Vessels</Link>
    </Menu.Item>
    <Menu.Item>
      <Button type="danger" onClick={onToggleDataResetModal}>
        Reset All Data
      </Button>
    </Menu.Item>
  </Menu>
);

SettingsMenu.propTypes = {
  onToggleDataResetModal: PropTypes.func.isRequired,
};

export class GlobalHeader extends Component {
  static propTypes = {
    breadcrumbs: BreadcrumbsType.isRequired,
    timeOfDayGreeting: PropTypes.string.isRequired,
    logout: PropTypes.func.isRequired,
    resetEnvData: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
    history: HistoryType.isRequired,
    resettingData: PropTypes.bool.isRequired,
  };

  state = {
    showDataResetModal: false,
    userSafeguardInput: null,
  };

  handleToggleDataResetModal = () => {
    this.setState(({ showDataResetModal }) => ({ showDataResetModal: !showDataResetModal }));
  };

  handleLogout = () => {
    this.props.logout();
  };

  handleResetEnvData = () => {
    this.props.resetEnvData();
    this.handleToggleDataResetModal();
  };

  handleChangeSafeguardInput = ({ target: { value } }) => {
    this.setState({ userSafeguardInput: value });
  };

  render() {
    const { username, breadcrumbs, timeOfDayGreeting, history, resettingData } = this.props;
    const { showDataResetModal, userSafeguardInput } = this.state;

    return (
      <div className="global-header">
        <div className="global-header-main">
          <Link to="/">
            <img className="global-header-main__logo" alt="Shell DINGO" src={logo} />
          </Link>
          <div className="global-header-main__controls">
            <Dropdown
              overlay={<SettingsMenu onToggleDataResetModal={this.handleToggleDataResetModal} />}
              placement="bottomRight"
            >
              <Icon data-testid="settings-dropdown" type="setting" theme="filled" />
            </Dropdown>
            <Avatar icon="user" size="small" />
            <Dropdown overlay={<MenuContent onLogout={this.handleLogout} />}>
              <span className="user-name">
                {username}
                <Icon type="down" />
              </span>
            </Dropdown>
          </div>
        </div>
        {history.location.pathname.length > 1 ? (
          <Breadcrumb className="global-header-breadcrumb">
            {breadcrumbs.map(({ title, path, onClick, component }) => (
              <Item key={`${path || ""}#${title}`}>
                {path || onClick ? (
                  <Link to={path} onClick={onClick}>
                    {!isEmpty(component) ? component : title}
                  </Link>
                ) : (
                  title
                )}
              </Item>
            ))}
          </Breadcrumb>
        ) : (
          <div className="global-header-greeting">
            {`${timeOfDayGreeting} ${capitalize(username)}, welcome to Dingo.`}
          </div>
        )}
        <Modal
          centered
          visible={resettingData}
          wrapClassName="portfolio-list-modal-loading"
          closable={false}
          footer={null}
        >
          <Spin tip="Resetting Environment…" indicator={<Icon type="loading" />} />
        </Modal>
        <Modal
          visible={showDataResetModal}
          wrapClassName="global-header-reset-env-modal"
          title="Reset all Environment Data"
          onOk={this.handleResetEnvData}
          okText="Reset"
          okButtonProps={{ type: "danger", disabled: userSafeguardInputTarget !== userSafeguardInput }}
          onCancel={this.handleToggleDataResetModal}
          destroyOnClose={true}
        >
          <div>{`All data will be reset. If you are sure that you would like to proceed, please type ${userSafeguardInputTarget} then press Reset.`}</div>
          <Input onChange={this.handleChangeSafeguardInput} />
        </Modal>
      </div>
    );
  }
}

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  resetEnvData: () => dispatch(resetEnvData()),
});

const mapStateToProps = ({ auth, admin }) => ({
  username: auth.username,
  resettingData: admin.resettingData,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GlobalHeader)
);
