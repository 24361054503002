import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Login from "containers/Login";
import Facility from "containers/Facility";
import Strategies from "containers/Strategies";
import Strategy from "containers/Strategy";
import Portfolio from "containers/Portfolio";
import FacilitySettings from "containers/FacilitySettings";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { verifyUser } from "redux/ducks/auth";
import Facilities from "containers/Facilities";
import ActivityMethodDetails from "containers/ActivityMethodDetails";
import CountryRates from "containers/CountryRates";
import ActivityTimeframes from "containers/ActivityTimeframes";
import OverheadCosts from "containers/OverheadCosts";
import Vessels from "containers/Vessels";

import "App.less";

class App extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    verifyUser: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.verifyUser();
  }

  render() {
    const { isAuthenticated } = this.props;

    return isAuthenticated ? (
      <Switch>
        <Route exact path="/" component={Portfolio} />
        <Route path="/countries/rates" render={() => <CountryRates />} />
        <Route path="/settings/facilities" render={() => <FacilitySettings />} />
        <Route path="/settings/timeframes" render={() => <ActivityTimeframes />} />
        <Route path="/settings/overhead-costs" render={() => <OverheadCosts />} />
        <Route path="/settings/vessels" render={() => <Vessels />} />
        <Route path="/strategies/:strategyid/activity/:activityid" render={() => <ActivityMethodDetails />} />
        <Route path="/facilities" component={Facilities} />
        <Switch>
          <Route
            exact
            path="/facility/:id"
            render={({ match }) => <Redirect to={`/facility/${match.params.id}/general`} />}
          />
          <Route path="/facility/:id/general" render={({ match }) => <Facility facilityID={match.params.id} />} />
          <Route
            path="/facility/:id/strategies/:strategyid/phase/:phaseid/edit"
            render={({ match }) => <Strategy facilityID={match.params.id} strategyID={match.params.strategyid} />}
          />
          <Route path="/facility/:id/strategies" render={({ match }) => <Strategies facilityID={match.params.id} />} />
          <Redirect from="*" to="/" />
        </Switch>
      </Switch>
    ) : (
      <Login />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    verifyUser: () => dispatch(verifyUser()),
  };
}

function mapStateToProps({ auth }) {
  const { isAuthenticated } = auth;
  return {
    isAuthenticated,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
