import axios from "axios";
import countries from "utils/api/countries";
import complexes from "utils/api/complexes";
import locationTypes from "utils/api/location-types";
import facilities from "utils/api/facilities";
import strategies from "utils/api/strategies";
import portfolio from "utils/api/portfolio";
import Auth from "utils/auth";
import downloadFile from "js-file-download";
import topsides from "utils/api/topsides";
import substructure from "utils/api/substructure";
import vesselTypes from "utils/api/vessel-types";
import subArchetypes from "utils/api/sub-archetypes";
import settings from "utils/api/settings";
import admin from "utils/api/admin";
import pipelineConfigurations from "utils/api/pipeline-configurations";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function get(url, config = {}) {
  return instance.get(url, config);
}

function post(url, config = {}) {
  return instance.post(url, config);
}

function patch(url, config = {}) {
  return instance.patch(url, config);
}

function del(url, config = {}) {
  return instance.delete(url, config);
}

instance.interceptors.request.use(
  config =>
    Auth.currentSession()
      .then(session => {
        // User is logged in. Set auth header on all requests
        config.headers.common["X-AUTH-TOKEN"] = session.accessToken.jwtToken;
        return config;
      })
      .then(() => Promise.resolve(config))
      .catch(() => Promise.resolve(config)),
  error => Promise.reject(error)
);

function throwOnDownloadResponseError(error, errorMessagePrefix) {
  const renderErrorMessage = message => errorMessagePrefix + (message ? `: ${message}` : ".");

  if (
    error.response &&
    error.response.status === 500 &&
    error.response.headers["content-type"].indexOf("application/json") === 0
  ) {
    // Extract custom server-specified error message from a JSON response
    // with a status of 500 (Internal Server Error). Prefix-match the Content-Type
    // header, which may have "; charset=UTF-8" appended.

    // The request had specified a Blob response, which means that error responses
    // also come in as Blobs, which have to be read and parsed as JSON.
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        // Parse JSON of a successfully read Blob, and read its `error` field
        const responseJSON = JSON.parse(reader.result) || {};

        reject(new Error(renderErrorMessage(responseJSON.error)));
      };
      // Could not read the Blob that would have a specific error message,
      // so show a general error.
      reader.onerror = () => reject(new Error(renderErrorMessage()));

      // Start reading the Blob
      reader.readAsText(error.response.data);
    });
  }

  // No custom server-sent error message available, so show generic error.
  throw new Error(renderErrorMessage(error.message));
}

// handleDownload handles XHR download of a CSV or JSON file, including
// saving the file to disk and putting up an alert on error. XHR is needed
// rather than a plain download link so that the auth token can be sent.
// It returns a promise that resolves to the downloaded filename on success,
// or is rejected on error.
const handleDownload = (options, defaultFilename, contentType) => {
  return instance({ headers: { Accept: contentType }, method: "POST", ...options, responseType: "blob" })
    .then(response => {
      const contentDisposition = response.headers["content-disposition"];
      const filenameMatch = contentDisposition ? contentDisposition.match(/filename="(.+)"/) : [];
      const filename = filenameMatch[1] || defaultFilename;
      downloadFile(response.data, filename, contentType);
      return filename;
    })
    .catch(error => throwOnDownloadResponseError(error, "Could not download the file"));
};

function downloadAudit(facilityIDs) {
  // Download the audit log for an Actenum export. This can't be done
  // with a simple download link, because the auth token header must
  // be sent. So download a blob via XHR with Axios, and use a
  // cross-browser JS downloader to save the blob to disk.
  return instance({
    url: "api/export",
    method: "POST",
    headers: {
      Accept:
        "application/json,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    data: { facilityIDs, audit: "audit" },
    responseType: "blob",
  })
    .then(response => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/json") {
        downloadFile(response.data, "Audit.json", contentType);
      } else if (
        contentType === "application/vnd.ms-excel" ||
        contentType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        downloadFile(response.data, "Audit.xlsx", contentType);
      } else {
        throwOnDownloadResponseError(
          new Error("unknown response content type"),
          "Could not download the Actenum export audit log"
        );
      }
      return "Export success";
    })
    .catch(error => throwOnDownloadResponseError(error, "Could not download the Actenum export audit log"));
}

function downloadExport(facilityIDs) {
  // Download the Excel-format export for Actenum. This can't be done
  // with a simple download link, because the auth token header must
  // be sent. So download a blob via XHR with Axios, and use a
  // cross-browser JS downloader to save the blob to disk.
  return instance({
    url: "api/export",
    method: "POST",
    headers: { Accept: "application/vnd.ms-excel" },
    data: { facilityIDs },
    responseType: "blob",
  })
    .then(response => {
      downloadFile(response.data, "Actenum.xlsx", "application/vnd.ms-excel");
      return "Export success";
    })
    .catch(error => throwOnDownloadResponseError(error, "Could not download the Actenum export"));
}

// downloadFacilities exports a CSV file for the given archetype and
// optional countryID. Use a similar strategy to downloadExport, which
// uses XHR to send the auth token.
// countryID is optional, with a falsy value meaning "all".
// It returns a promise that resolves to the downloaded filename on success,
// or is rejected on error.
const downloadFacilities = (archetype, countryID) =>
  handleDownload(
    {
      url: "api/facilities/download",
      data: { archetype, countryID },
    },
    "Facilities.csv",
    "text/csv"
  );

const downloadVessels = () => handleDownload({ url: "api/vessels/download" }, "Vessels.csv", "text/csv");

const downloadVesselRates = () => handleDownload({ url: "api/vessels/download-rates" }, "Vessel Rates.csv", "text/csv");

const api = {
  get,
  post,
  patch,
  delete: del,
  facilities,
  countries,
  downloadAudit,
  downloadExport,
  downloadFacilities,
  downloadVessels,
  downloadVesselRates,
  strategies,
  portfolio,
  locationTypes,
  topsides,
  substructure,
  complexes,
  vesselTypes,
  subArchetypes,
  settings,
  pipelineConfigurations,
  admin,
};

export default api;
