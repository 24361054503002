import api from "utils/api";

function addNewStrategy(PhaseID, FacilityID) {
  return api.post("/api/strategies", { FacilityID, PhaseID, Name: "Strategy Name" });
}

function updateStrategySelection(StrategySelectionID, MethodID) {
  return api.patch(`/api/strategySelections/${StrategySelectionID}`, { MethodID });
}

function updateStrategyName(StrategyID, Name) {
  return api.patch(`/api/strategies/${StrategyID}`, { Name });
}

function getStrategies(FacilityID) {
  return api.get("/api/strategies/summaries", { params: { FacilityID } });
}

function getStrategy(StrategyID) {
  return api.get(`/api/strategies/${StrategyID}`);
}

function removeStrategy(StrategyID) {
  return api.delete(`/api/strategies/${StrategyID}`);
}

function vesselOverride(StrategyID, ActivityID, VesselTypeID, RoleName, Included = null, Reason = null) {
  return api.post(`/api/strategies/${StrategyID}/vesselOverride`, {
    ActivityID,
    VesselTypeID,
    RoleName,
    Included,
    Reason,
  });
}

function getActivity(StrategyID, ActivityID) {
  return api.get(`/api/strategies/${StrategyID}/activity/${ActivityID}`);
}

function formulaOverride(StrategyID, ActivityID, { MethodID, TaskName, FormulaName, Value }) {
  return api.post(`/api/strategies/${StrategyID}/activity/${ActivityID}/formulaOverride`, {
    MethodID,
    TaskName,
    FormulaName,
    Value,
  });
}

function saveCustomTask(StrategyID, ActivityID, { MethodID, CustomTaskID, Name, Cost, Duration }) {
  return api.post(`api/strategies/${StrategyID}/activity/${ActivityID}/task`, {
    MethodID,
    CustomTaskID,
    Name,
    Cost,
    Duration,
  });
}

function saveGlobalTask(StrategyID, ActivityID, { MethodID, Name }) {
  return api.post(`api/strategies/${StrategyID}/activity/${ActivityID}/create-global-task`, {
    MethodID,
    Name,
  });
}

function deleteCustomTask(StrategyID, ActivityID, { MethodID, CustomTaskID }) {
  return api.delete(`api/strategies/${StrategyID}/activity/${ActivityID}/task`, {
    data: {
      MethodID,
      CustomTaskID,
    },
  });
}

function previewFormula(StrategyID, ActivityID, { MethodID, tasks }) {
  return api.post(`api/strategies/${StrategyID}/activity/${ActivityID}/preview`, {
    MethodID,
    tasks,
  });
}

function saveTask(StrategyID, ActivityID, { MethodID, tasks }) {
  return api.post(`api/strategies/${StrategyID}/activity/${ActivityID}/save`, {
    MethodID,
    tasks,
  });
}

export default {
  getStrategies,
  getStrategy,
  addNewStrategy,
  updateStrategySelection,
  updateStrategyName,
  removeStrategy,
  vesselOverride,
  getActivity,
  formulaOverride,
  saveCustomTask,
  saveGlobalTask,
  deleteCustomTask,
  previewFormula,
  saveTask,
};
