import PropTypes from "prop-types";
import { ActivityType } from "types/activity";

export const StrategyType = PropTypes.shape({
  StrategyID: PropTypes.number.isRequired,
  PhaseID: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  FacilityID: PropTypes.number.isRequired,
  Activities: PropTypes.arrayOf(ActivityType).isRequired,
  Complete: PropTypes.bool.isRequired,
});

export const SummaryStrategyType = PropTypes.shape({
  FacilityID: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  PhaseID: PropTypes.number.isRequired,
  StrategyID: PropTypes.number.isRequired,
  Complete: PropTypes.bool.isRequired,
  strategySelections: PropTypes.arrayOf(
    PropTypes.shape({
      ActivityID: PropTypes.number.isRequired,
      MethodID: PropTypes.number,
      StrategyID: PropTypes.number.isRequired,
      StrategySelectionID: PropTypes.number.isRequired,
      vessels: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
});

export const SummaryPhaseType = PropTypes.shape({
  Description: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  PhaseID: PropTypes.number.isRequired,
  PrimaryActivityID: PropTypes.number,
  Sequence: PropTypes.number.isRequired,
  SubArchetypeID: PropTypes.number.isRequired,
  strategies: PropTypes.arrayOf(SummaryStrategyType).isRequired,
});

export default {
  SummaryPhaseType,
  SummaryStrategyType,
  StrategyType,
};
