import React from "react";
import PropTypes from "prop-types";
import { Icon, Tooltip } from "antd";
import { colors } from "utils/shell-colors";
import infoIcon from "assets/information.svg";

import "./style.less";

const TotalTimeCost = ({ items, activeItem }) => {
  const totalCost = items.reduce((total, item) => total + item.Cost, 0);
  const totalTime = items.reduce((total, item) => total + item.Duration, 0);

  return (
    <div className="total-time-cost">
      <div className="total-time">
        <Icon type="clock-circle" />
        {totalTime > 0 ? `Total Time: ${totalTime.toFixed(1)} days` : "Total Time: -"}
        <div className="bar-graph">
          {totalTime > 0 ? (
            items.map(({ Sequence, Duration, Name }) => (
              <Tooltip
                overlayClassName="item-time-tooltip"
                key={`item-time-${Sequence}`}
                placement="top"
                title={`${Name}: ${Duration ? Duration.toFixed(1) : 0} days`}
              >
                <div
                  className="bar"
                  style={{
                    ...colors[(Sequence - 1) % colors.length],
                    width: `${(Duration / totalTime) * 100}%`,
                    opacity: activeItem && Sequence !== activeItem ? "0.2" : "1",
                  }}
                />
              </Tooltip>
            ))
          ) : (
            <div style={{ backgroundColor: "gray", width: "100%" }} />
          )}
        </div>
      </div>
      <div className="total-cost">
        <Icon type="dollar" />
        {totalCost > 0 ? `Total Cost: ${(totalCost / 1000000).toFixed(2)} MM` : "Total Cost: -"}
        <Tooltip
          className="total-cost-tooltip-container__tooltip"
          placement="top"
          title="Total Cost does not include Vessel Cost"
        >
          <img className="tooltip" src={infoIcon} alt="information" />
        </Tooltip>
        <div className="bar-graph">
          {totalCost > 0 ? (
            items.map(({ Sequence, Cost, Name }) => (
              <Tooltip
                overlayClassName="item-cost-tooltip"
                key={`item-cost-${Sequence}`}
                placement="top"
                title={`${Name}: ${Cost > 0 ? (Cost / 1000000).toFixed(2) : 0} MM`}
              >
                <div
                  className="bar"
                  style={{
                    ...colors[(Sequence - 1) % colors.length],
                    width: `${(Cost / totalCost) * 100}%`,
                    opacity: activeItem && Sequence !== activeItem ? "0.2" : "1",
                  }}
                />
              </Tooltip>
            ))
          ) : (
            <div style={{ backgroundColor: "gray", width: "100%" }} />
          )}
        </div>
      </div>
    </div>
  );
};

TotalTimeCost.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      Sequence: PropTypes.number,
      Cost: PropTypes.number,
      Duration: PropTypes.number,
    })
  ).isRequired,
  activeItem: PropTypes.number,
};

TotalTimeCost.defaultProps = {
  activeItem: null,
};

export default TotalTimeCost;
