import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import api from "utils/api";

export const GET_PORTFOLIO = "portfolio/GET_PORTFOLIO";
export const ADD_TO_PORTFOLIO = "portfolio/ADD_TO_PORTFOLIO";
export const REMOVE_FROM_PORTFOLIO = "portfolio/REMOVE_FROM_PORTFOLIO";
export const DOWNLOAD_AUDIT = "portfolio/DOWNLOAD_AUDIT";
export const DOWNLOAD_EXPORT = "portfolio/DOWNLOAD_EXPORT";
export const CLEAR_EXPORT_ERROR = "portfolio/CLEAR_EXPORT_ERROR";

const initialState = {
  isLoading: false,
  exportLoading: false,
  isAddingToPortfolio: false,
  portfolioError: "",
  exportError: "",
  facilities: [],
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    case GET_PORTFOLIO:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          isLoading: true,
          facilities: [],
        }),
        failure: prevState => ({
          ...prevState,
          isLoading: false,
          portfolioError: payload.message || "Could not get portfolio",
        }),
        success: prevState => ({ ...prevState, facilities: payload.data, isLoading: false }),
      });
    case REMOVE_FROM_PORTFOLIO:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
        }),
        failure: prevState => ({
          ...prevState,
          portfolioError: payload.message || "Could not get portfolio",
        }),
        success: prevState => ({ ...prevState, facilities: payload.data }),
      });
    case ADD_TO_PORTFOLIO:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          isAddingToPortfolio: true,
          portfolioError: "",
        }),
        failure: prevState => ({
          ...prevState,
          isAddingToPortfolio: false,
          portfolioError: payload.message || "Could not add to portfolio",
        }),
        success: prevState => ({
          ...prevState,
          isAddingToPortfolio: false,
          portfolioError: "",
        }),
      });
    case DOWNLOAD_AUDIT:
    case DOWNLOAD_EXPORT:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          exportLoading: true,
          exportError: "",
        }),
        failure: prevState => ({
          ...prevState,
          exportLoading: false,
          exportError: payload.message || "Could not download export",
        }),
        success: prevState => ({
          ...prevState,
          exportLoading: false,
          exportError: "",
        }),
      });
    case CLEAR_EXPORT_ERROR:
      return { ...state, exportError: "" };
    default:
      return state;
  }
}

export const addToPortfolio = facilityIDs => ({
  type: ADD_TO_PORTFOLIO,
  promise: api.portfolio.addToPortfolio(facilityIDs),
});

export const removeFromPortfolio = (facilityIDs, shouldDeleteStrategies) => ({
  type: REMOVE_FROM_PORTFOLIO,
  promise: api.portfolio.removeFromPortfolio(facilityIDs, shouldDeleteStrategies),
});

export const getPortfolio = () => ({ type: GET_PORTFOLIO, promise: api.portfolio.getPortfolio() });

export const downloadAudit = facilityIDs => ({ type: DOWNLOAD_AUDIT, promise: api.downloadAudit(facilityIDs) });

export const downloadExport = facilityIDs => ({ type: DOWNLOAD_EXPORT, promise: api.downloadExport(facilityIDs) });

export const clearExportError = () => ({ type: CLEAR_EXPORT_ERROR });

export default reducer;
