import PropTypes from "prop-types";

export default PropTypes.shape({
  FacilityID: PropTypes.number,
  Name: PropTypes.string.isRequired,
  ComplexID: PropTypes.number,
  CoPDate: PropTypes.string,
  CountryID: PropTypes.number,
  FacilityFunctionID: PropTypes.number,
  YearInstalled: PropTypes.string,
  SubArchetypeID: PropTypes.number,
});

export const FacilityFunctionPropType = PropTypes.shape({
  FacilityFunctionID: PropTypes.number,
  Name: PropTypes.string,
});

export const FacilitiesType = PropTypes.arrayOf(
  PropTypes.shape({
    CoPDate: PropTypes.string,
    Name: PropTypes.string.isRequired,
    FacilityID: PropTypes.number.isRequired,
    topsidesTotalWeight: PropTypes.number,
    waterDepth: PropTypes.number,
    substructureTotalJacketWeight: PropTypes.number,
  })
);
