import api from "utils/api";

function getFacilitiesByCountry(countryID) {
  return api.get(`/api/facilities/filterByCountryID?countryid=${+countryID}`);
}

function getFacilityDetails(facilityID) {
  return api.get(`/api/facilities/${facilityID}/details`);
}

function toFacilityDetails(archetype, newFields) {
  // Unflatten unsavedFacilityFields into an data structure
  // that mirrors the full details originally retrieved from the API.
  // The server will use it as a diff to patch its DB records.
  // Fields that the user didn't touch will be undefined,
  // and therefore omitted from the JSON sent to the server.
  // The server will then leave those fields untouched in the DB,
  // avoiding overwriting any simultaneous updates to those
  // fields by other users.

  // basic Facility fields shared by all archetypes
  const result = {
    archetype: newFields.archetype,
    CoPDate: newFields.CoPDate,
    ComplexID: newFields.ComplexID,
    NumBridgeLinks: newFields.NumBridgeLinks,
    YearInstalled: newFields.YearInstalled,
    FacilityFunctionID: newFields.FacilityFunctionID,
    locations: newFields.locations,
  };

  switch (archetype) {
    case "Platform":
      result.platform = {
        WaterDepth: newFields.WaterDepth,
        LegCount: newFields.LegCount,
        RiserCount: newFields.RiserCount,
        ShallowCaissonCount: newFields.ShallowCaissonCount,
        DeepCaissonCount: newFields.DeepCaissonCount,
        JtubeCount: newFields.JtubeCount,
        ConductorCount: newFields.ConductorCount,
        DrillCuttingPile: newFields.DrillCuttingPile,
        NumberOfBeds: newFields.NumberOfBeds,
        substructure: {
          Weight: newFields.SubstructureWeight,
          LegDiameter: newFields.LegDiameter,
          LegWallThickness: newFields.LegWallThickness,
          PileWallThickness: newFields.PileWallThickness,
          SkirtPileCount: newFields.SkirtPileCount,
          SkirtPileDiameter: newFields.SkirtPileDiameter,
          TotalHeight: newFields.SubstructureTotalHeight,
          MarineGrowthID: newFields.MarineGrowthID,
          LegPileCount: newFields.LegPileCount,
          LegPileDiameter: newFields.LegPileDiameter,
        },
        topsides: {
          TopsidesOriginalInstallationMethodID: newFields.TopsidesOriginalInstallationMethodID,
          topsidesModules: newFields.topsidesModules,
          Weight: newFields.TopsidesWeight,
          PieceSmallWeight: newFields.PieceSmallWeight,
        },
      };
      break;

    case "Pipeline":
      result.pipeline = {
        StartFacilityID: newFields.StartFacilityID,
        EndFacilityID: newFields.EndFacilityID,
        InteriorDiameter: newFields.InteriorDiameter,
        Length: newFields.Length,
        MaxWaterDepth: newFields.MaxWaterDepth,
        MinWaterDepth: newFields.MinWaterDepth,
        SignificantDebris: newFields.SignificantDebris,
        EnvironmentalThreat: newFields.EnvironmentalThreat,
        PermanentPiggingFacilities: newFields.PermanentPiggingFacilities,
        NORMSContamination: newFields.NORMSContamination,
        PipelineConfigurationID: newFields.PipelineConfigurationID,
        TieInSpoolCount: newFields.TieInSpoolCount,
        TieInSpoolDiameter: newFields.TieInSpoolDiameter,
        TieInSpoolAverageLength: newFields.TieInSpoolAverageLength,
        TieInSpoolProtectionCoverCount: newFields.TieInSpoolProtectionCoverCount,
        GroutBagCount: newFields.GroutBagCount,
      };
      break;

    case "Subsea":
      result.subsea = {
        Area: newFields.Area,
        WaterDepth: newFields.WaterDepth,
        Weight: newFields.Weight,
      };
      break;

    default:
      throw new Error("unknown facility type");
  }

  return result;
}

function saveFacilityDetails(facilityID, archetype, patchedFacility) {
  return api.patch(`/api/facilities/${facilityID}/details`, toFacilityDetails(archetype, patchedFacility));
}

function createFacility(newFacility) {
  let facilityToCreate;

  switch (newFacility.archetype) {
    case "Platform":
      facilityToCreate = {
        NumBridgeLinks: 0,
        ...newFacility,
        platform: {
          LegCount: 10,
          WaterDepth: 100,
          RiserCount: 10,
          ShallowCaissonCount: 10,
          DeepCaissonCount: 10,
          JtubeCount: 10,
          ConductorCount: 10,
          DrillCuttingPile: 0,
          NumberOfBeds: 0,
          topsides: {
            TopsidesOriginalInstallationMethodID: 1,
            Weight: 100,
            PieceSmallWeight: 100,
          },
          substructure: {
            SkirtPileCount: 10,
            SkirtPileDiameter: 10,
            TotalHeight: 100,
            Weight: 1000,
            MarineGrowthID: 1,
            PileWallThickness: 10,
            LegPileCount: 10,
            LegPileDiameter: 10,
            LegDiameter: 10,
            LegWallThickness: 10,
          },
        },
      };
      break;
    case "Pipeline":
      facilityToCreate = {
        NumBridgeLinks: 0,
        ...newFacility,
        pipeline: {
          InteriorDiameter: 10,
          Length: 100,
          MaxWaterDepth: 100,
          MinWaterDepth: 50,
          SignificantDebris: false,
          EnvironmentalThreat: false,
          PermanentPiggingFacilities: false,
          NORMSContamination: false,
          PipelineConfigurationID: 1,
          TieInSpoolCount: 1,
          TieInSpoolDiameter: 12,
          TieInSpoolAverageLength: 50,
          TieInSpoolProtectionCoverCount: 0,
          GroutBagCount: 0,
        },
      };
      break;
    case "Subsea":
      facilityToCreate = {
        NumBridgeLinks: 0,
        ...newFacility,
        subsea: {
          Area: 100,
          WaterDepth: 100,
          Weight: 100,
        },
      };
      break;
    default:
  }
  return api.post("/api/facilities/add", facilityToCreate);
}

function getFacilityFunctions() {
  return api.get("/api/facilityFunctions");
}

// uploadFacilities posts a Facilities.csv to the server,
// provided as an <input type="file">-style file object.
// It returns a promise.
function uploadFacilities(file) {
  const formData = new FormData();
  formData.append("file", file);
  return api.post("api/facilities/upload", formData);
}

export default {
  getFacilitiesByCountry,
  getFacilityDetails,
  getFacilityFunctions,
  saveFacilityDetails,
  createFacility,
  uploadFacilities,
};
