import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import authReducer from "redux/ducks/auth";
import countriesReducer from "redux/ducks/countries";
import complexesReducer from "redux/ducks/complexes";
import facilitiesReducer from "redux/ducks/facilities";
import portfolioReducer from "redux/ducks/portfolio";
import strategiesReducer from "redux/ducks/strategies";
import locationTypesReducer from "redux/ducks/location-types";
import vesselTypesReducer from "redux/ducks/vessel-types";
import facilityFunctionsReducer from "redux/ducks/facility-functions";
import subArchetypesReducer from "redux/ducks/sub-archetypes";
import topsideInstallationMethodsReducer from "redux/ducks/topside-installation-methods";
import marineGrowthReducer from "redux/ducks/marine-growth";
import settingsReducer from "redux/ducks/settings";
import adminReducer from "redux/ducks/admin";
import pipelineConfigurationsReducer from "redux/ducks/pipeline-configurations";

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    countries: countriesReducer,
    complexes: complexesReducer,
    facilities: facilitiesReducer,
    facilityFunctions: facilityFunctionsReducer,
    portfolio: portfolioReducer,
    strategies: strategiesReducer,
    locationTypes: locationTypesReducer,
    vesselTypes: vesselTypesReducer,
    topsideInstallationMethods: topsideInstallationMethodsReducer,
    marineGrowth: marineGrowthReducer,
    settings: settingsReducer,
    admin: adminReducer,
    subArchetypes: subArchetypesReducer,
    pipelineConfigurations: pipelineConfigurationsReducer,
  });
