import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Input, Alert } from "antd";
import { isEmpty } from "lodash";

import "./style.less";

class GlobalTaskModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    taskError: PropTypes.string,
    draftTask: PropTypes.shape({
      Name: PropTypes.string,
    }).isRequired,
    onUpdateTask: PropTypes.func.isRequired,
  };

  static defaultProps = {
    taskError: "",
  };

  state = {
    errors: {},
  };

  handleChangeName = ({ target: { value } }) => {
    this.props.onUpdateTask({ ...this.props.draftTask, Name: value });
  };

  validate = task => {
    const errors = {};
    const { Name } = task;

    if (!Name) {
      errors.name = true;
    }

    this.setState({ errors });

    return isEmpty(errors);
  };

  handleOnOk = () => {
    const { draftTask } = this.props;
    if (this.validate(draftTask)) {
      this.props.onOk(draftTask);
      this.setState({ errors: {} });
    }
  };

  handleCancel = () => {
    this.setState({ errors: {} });
    this.props.onCancel();
  };

  render() {
    const { showModal, taskError, draftTask } = this.props;
    const { errors } = this.state;
    return (
      <Modal
        visible={showModal}
        title="Global Task"
        maskClosable={false}
        destroyOnClose={true}
        onOk={this.handleOnOk}
        onCancel={this.handleCancel}
        className="global-task-modal"
      >
        {taskError && <Alert message="Error" description={taskError} type="error" showIcon />}
        <div className="labeled-field">
          <div className="label">Task Name</div>
          <Input className="value" autoFocus={true} onChange={this.handleChangeName} value={draftTask.Name} />
          {errors.name && <div className="error">Name cannot be blank</div>}
        </div>
      </Modal>
    );
  }
}

export default GlobalTaskModal;
