import Amplify from "aws-amplify";

export default Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT,
  },
});
