import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { Button, Modal, Table } from "antd";
import FacilitiesFilter from "components/FacilitiesFilter";
import FacilitiesListHeader from "components/FacilitiesListHeader";
import FacilitiesMap from "components/FacilitiesMap";
import { FacilitiesType, FacilityFunctionPropType } from "types/facility";
import CountryType from "types/country";
import { loadFacilitiesForCountry } from "redux/ducks/facilities";
import { loadCountries, loadCountry } from "redux/ducks/countries";
import { getCountries } from "redux/selectors/countries";
import { loadFacilityFunctions } from "redux/ducks/facility-functions";
import { getFacilitiesForCountry, getFacilitiesError, areFacilitiesLoading } from "redux/selectors/facilities";

import "./style.less";

class ChooseFacilityModal extends Component {
  static propTypes = {
    defaultCountryID: PropTypes.number.isRequired,
    defaultFacilityID: PropTypes.number,
    country: CountryType.isRequired,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    loading: PropTypes.bool.isRequired,
    loadCountries: PropTypes.func.isRequired,
    loadFacilities: PropTypes.func.isRequired,
    loadCountry: PropTypes.func.isRequired,
    loadFacilityFunctions: PropTypes.func.isRequired,
    facilityFunctions: PropTypes.arrayOf(FacilityFunctionPropType).isRequired,
    facilities: FacilitiesType.isRequired,
    onClose: PropTypes.func.isRequired, // called after closing animation completes, so parent can destroy component
    onSet: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  };

  static defaultProps = {
    defaultFacilityID: null,
  };

  state = {
    filters: FacilitiesFilter.getEmptyFilters(),
    searchText: "",
    selectedRowKeys: null, // null means the defaultFacilityID (if any) is selected
    selectedRows: [],
    sortedInfo: {},
    viewAsMap: false,
    visible: true, // false while closing animation runs, before parent is notified via props.onClose
  };

  componentDidMount() {
    const countryID = this.props.defaultCountryID;

    if (countryID) {
      this.props.loadCountry(countryID);
      this.props.loadFacilities(countryID);
    }

    this.props.loadFacilityFunctions();
    this.props.loadCountries();
  }

  formatData = filteredFacilities =>
    filteredFacilities.map(facility => {
      const { facilityFunctions } = this.props;
      const { CoPDate, FacilityFunctionID } = facility;
      const facilityFunction = (facilityFunctions || []).find(ff => ff.FacilityFunctionID === FacilityFunctionID);

      return {
        ...facility,
        key: facility.FacilityID,
        CoPDate: moment.utc(CoPDate).format("YYYY-MM-DD"),
        Function: facilityFunction ? facilityFunction.Name : "no function",
      };
    });

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleClearFacility = () => {
    this.props.onSet(null);
    this.setState({ visible: false });
  };

  handleCountrySelect = CountryID => {
    this.props.loadCountry(CountryID);
    this.props.loadFacilities(CountryID);
  };

  handleFilterChange = newFilters => {
    this.setState({ filters: newFilters });
  };

  handleRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  handleRowClick = row => {
    this.setState({ selectedRowKeys: [row.key], selectedRows: [row] });
  };

  handleSearchChange = newSearch => {
    this.setState({ searchText: newSearch });
  };

  handleTableChange = (pagination, filters, sorter) => this.setState({ sortedInfo: sorter });

  handleTableSort = key => (a, b) => (a[key] < b[key] ? -1 : 1);

  handleViewChange = e => {
    this.setState({ viewAsMap: e.target.value === "map" });
  };

  handleSetFacilityFromList = () => {
    // only change the existing value if the user has made an explicit selection
    // (avoiding overwriting the default facility selection)
    if (this.state.selectedRowKeys) {
      this.props.onSet(this.state.selectedRows.length ? this.state.selectedRows[0] : null);
    }
    this.setState({ visible: false });
  };

  handleSetFacilityFromMap = facility => {
    this.props.onSet(facility);
    this.setState({ visible: false });
  };

  renderMapPopupExtraForFacility = facility => (
    <Button
      type="primary"
      onClick={() => {
        this.handleSetFacilityFromMap(facility);
      }}
    >
      Set
    </Button>
  );

  render() {
    const { country, countries, defaultFacilityID, facilities, loading, onClose, title } = this.props;
    const { filters, searchText, selectedRowKeys, sortedInfo, viewAsMap, visible } = this.state;

    const defaultRowKeys = defaultFacilityID ? [defaultFacilityID] : [];
    const selectedRowKeysWithDefault = selectedRowKeys || defaultRowKeys;

    const filteredFacilities = this.formatData(FacilitiesFilter.applyToList(facilities || [], filters, searchText));

    const rowSelection = {
      getCheckboxProps: record => ({ name: record.Name }),
      onChange: this.handleRowChange,
      selectedRowKeys: selectedRowKeysWithDefault,
      type: "radio",
    };

    return (
      <Modal
        visible={visible}
        className="choose-facility-modal"
        wrapClassName="scrollable-modal"
        closable={false}
        width={850}
        footer={
          viewAsMap ? (
            <Button onClick={this.handleCancel}>Done</Button>
          ) : (
            [
              <Button key="cancel" onClick={this.handleCancel}>
                Cancel
              </Button>,
              <Button key="clear" onClick={this.handleClearFacility}>
                Clear
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.handleSetFacilityFromList}
                disabled={!selectedRowKeysWithDefault.length}
              >
                Set
              </Button>,
            ]
          )
        }
        title={
          <FacilitiesListHeader
            actions={null}
            countries={countries}
            country={country}
            facilities={facilities}
            filteredFacilities={filteredFacilities}
            filters={filters}
            onCountrySelect={this.handleCountrySelect}
            onFilterChange={this.handleFilterChange}
            onSearchChange={this.handleSearchChange}
            onViewChange={this.handleViewChange}
            searchText={searchText}
            title={title}
            viewAsMap={viewAsMap}
          />
        }
        maskClosable={false}
        destroyOnClose={true}
        afterClose={onClose}
      >
        {viewAsMap ? (
          <FacilitiesMap
            filteredFacilities={filteredFacilities}
            renderPopupExtraForFacility={this.renderMapPopupExtraForFacility}
          />
        ) : (
          <Table
            className="scrollable-table"
            loading={loading}
            pagination={false}
            rowClassName={this.rowClassName}
            rowSelection={rowSelection}
            dataSource={filteredFacilities}
            onChange={this.handleTableChange}
            useFixedHeader={true}
            onRow={row => {
              // implement single click to select row
              return {
                onClick: () => {
                  this.handleRowClick(row);
                },
              };
            }}
            size="small"
          >
            <Table.Column
              title="Name"
              dataIndex="Name"
              key="Name"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "Name" && sortedInfo.order}
            />
            <Table.Column
              title="COP Date"
              dataIndex="CoPDate"
              key="CoPDate"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "CoPDate" && sortedInfo.order}
              width="10em"
            />
            <Table.Column
              title="Water Depth"
              dataIndex="waterDepth"
              key="waterDepth"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "waterDepth" && sortedInfo.order}
              width="9.5em"
            />
            <Table.Column
              title="Function"
              dataIndex="Function"
              key="Function"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "Function" && sortedInfo.order}
              width="13em"
            />
          </Table>
        )}
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadCountries: () => dispatch(loadCountries()),
    loadFacilities: countryID => dispatch(loadFacilitiesForCountry(countryID)),
    loadCountry: countryID => dispatch(loadCountry(countryID)),
    loadFacilityFunctions: () => dispatch(loadFacilityFunctions()),
  };
}

function mapStateToProps({ facilities, countries, facilityFunctions }) {
  return {
    countries: getCountries(countries),
    error: getFacilitiesError(facilities),
    facilities: getFacilitiesForCountry(facilities),
    loading: areFacilitiesLoading(facilities),
    country: countries.country,
    facilityFunctions: facilityFunctions.list || [],
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseFacilityModal);
