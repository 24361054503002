import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Dropdown, Icon, Menu, Modal, Popover, Spin, Table, Tooltip, Row, Col } from "antd";
import { PortfolioType } from "types/portfolio";
import { Globals } from "types/admin";
import classNames from "classnames";
import PortfolioFilter from "components/PortfolioFilter";
import LabeledNumericInput from "components/LabeledNumericInput";
import VesselOverrides from "containers/VesselOverrides";
import { isEmpty } from "lodash";

import "./style.less";

class PortfolioList extends Component {
  static propTypes = {
    appGlobals: Globals,
    userGlobals: Globals,
    facilities: PortfolioType,
    exportLoading: PropTypes.bool.isRequired,
    portfolioLoading: PropTypes.bool.isRequired,
    exportError: PropTypes.string.isRequired,
    onStrategyNavigate: PropTypes.func.isRequired,
    onFacilityNavigate: PropTypes.func.isRequired,
    onDownloadAudit: PropTypes.func.isRequired,
    onDownloadExport: PropTypes.func.isRequired,
    onAddFacilities: PropTypes.func.isRequired,
    onRemoveFacilities: PropTypes.func.isRequired,
    onClearExportError: PropTypes.func.isRequired,
    onSaveUserGlobals: PropTypes.func.isRequired,
  };

  static defaultProps = {
    facilities: [],
    appGlobals: {},
    userGlobals: {},
  };

  state = {
    selected: [],
    showFilters: false,
    showOverheadCostsModal: false,
    showVesselOverridesModal: false,
    userGlobalEdits: {},
    filters: {
      archetypes: null,
    },
  };

  handleRowChange = (selectedRowKeys, selectedRows) => {
    const selected = selectedRows;
    this.setState({ selected });
  };

  handleCheckboxProps = facility => ({
    Name: facility.Name,
    "data-testid": "portfolio-checkbox",
  });

  shouldAllowExport = selected => selected.every(facility => facility.IncompletePhaseNames.length === 0);

  handleExport = () => {
    const { selected } = this.state;
    if (this.shouldAllowExport(selected)) {
      this.props.onDownloadExport(selected.map(s => s.FacilityID));
    } else {
      Modal.info({
        title: "Unable to export.",
        content: "Some of the selected facilities have incomplete strategies and are ineligible for export.",
      });
    }
  };

  handleExportAudit = () => {
    const { selected } = this.state;
    if (this.shouldAllowExport(selected)) {
      this.props.onDownloadAudit(selected.map(s => s.FacilityID));
    } else {
      Modal.info({
        title: "Unable to generate.",
        content: "Some of the selected facilities have incomplete strategies and cannot produce a formula audit.",
      });
    }
  };

  sortByStatus = (a, b) => !!a.IncompletePhaseNames.length - !!b.IncompletePhaseNames.length;

  handleTableSort = key => (a, b) => (a[key] < b[key] ? -1 : 1);

  columns = () => [
    {
      title: "Name",
      dataIndex: "Name",
      width: "12em",
      sorter: this.handleTableSort("Name"),
    },
    {
      title: "Type",
      dataIndex: "archetype",
      onCell: () => {
        return {
          "data-testid": "Archetype",
        };
      },
      width: "12em",
      sorter: this.handleTableSort("archetype"),
    },
    {
      title: "Export Status",
      dataIndex: "Status",
      sorter: this.sortByStatus,
      render: status =>
        status.incompletePhaseNames.length ? (
          <Tooltip
            overlayClassName="status-tooltip"
            placement="left"
            title={
              <div>
                The following phases have no completed strategies:
                <ul className="list">
                  {status.incompletePhaseNames.map(phaseName => (
                    <li key={phaseName}>{phaseName}</li>
                  ))}
                </ul>
              </div>
            }
          >
            <div className={classNames("status-tooltip-title", { "no-good": !status.valid })}>{status.title}</div>
          </Tooltip>
        ) : (
          <div className="status-tooltip-title">{status.title}</div>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: facility => (
        <>
          <Button className="strategies-button" onClick={this.props.onFacilityNavigate(facility.FacilityID)}>
            Edit Facility Data
          </Button>
          <Button className="strategies-button" onClick={this.props.onStrategyNavigate(facility.FacilityID)}>
            Strategies
          </Button>
        </>
      ),
      width: "20em",
    },
  ];

  rowSelection = () => ({ onChange: this.handleRowChange, getCheckboxProps: this.handleCheckboxProps });

  generateFacilityStatus = facility => {
    if (facility.IncompletePhaseNames.length > 0) {
      return {
        title: "Unable to Export: Not all Phases have a completed Strategy.",
        incompletePhaseNames: facility.IncompletePhaseNames,
      };
    }

    return {
      title: "Able to Export",
      incompletePhaseNames: [],
    };
  };

  handleRemoveFacilities = () => {
    const { selected } = this.state;
    const { onRemoveFacilities } = this.props;

    Modal.confirm({
      title: "Removing Facilities",
      content:
        "This action will remove all selected facilities, would you also like to remove the assocaited strategies?",
      okType: "danger",
      okText: "Remove Strategies",
      cancelText: "Keep Strategies",
      onOk: () => {
        onRemoveFacilities(selected, true);
        this.setState({ selected: [] });
      },
      onCancel: () => {
        onRemoveFacilities(selected, false);
        this.setState({ selected: [] });
      },
    });
  };

  handleShowFiltersChange = () => {
    this.setState(({ showFilters }) => ({
      showFilters: !showFilters,
    }));
  };

  gatherFilterOptions = facilities =>
    Array.from(new Set(facilities.map(facility => facility.archetype)).values()).sort();

  handleFilterChange = newFilters => {
    this.setState({ filters: newFilters, showFilters: false });
  };

  getFilteredFacilities = () => {
    const { filters } = this.state;

    return this.props.facilities.filter(facility => {
      if (filters.archetypes !== null && !filters.archetypes.includes(facility.archetype)) {
        return false;
      }
      return true;
    });
  };

  handleShowVesselOverridesModal = () => {
    this.setState({ showVesselOverridesModal: true });
  };

  handleCloseVesselOverridesModal = () => {
    this.setState({ showVesselOverridesModal: false });
  };

  toggleShowOverheadCostsModal = () => {
    this.setState(({ showOverheadCostsModal }) => ({
      showOverheadCostsModal: !showOverheadCostsModal,
      userGlobalEdits: {},
    }));
  };

  handleChangeField = key => ({ target: { value } }) => {
    this.setState(({ userGlobalEdits }) => ({
      userGlobalEdits: {
        ...userGlobalEdits,
        [key]: value === "" || value === null ? null : (value / 100).toString(),
      },
    }));
  };

  handleSaveUserGlobals = () => {
    this.props.onSaveUserGlobals(this.state.userGlobalEdits);
    this.toggleShowOverheadCostsModal();
  };

  handleResetUserGlobalOverrides = () => {
    this.props.onSaveUserGlobals({
      DAR: null,
      contingency: null,
      contractorEngineering: null,
      projectManagement: null,
    });
    this.setState({ userGlobalEdits: {} });
  };

  render() {
    const { facilities, exportLoading, exportError, portfolioLoading, appGlobals, userGlobals } = this.props;
    const {
      selected,
      filters,
      showFilters,
      showOverheadCostsModal,
      showVesselOverridesModal,
      userGlobalEdits,
    } = this.state;

    const filteredFacilities = this.getFilteredFacilities();

    const data = filteredFacilities.map(facility => ({
      key: facility.FacilityID,
      ...facility,
      Status: this.generateFacilityStatus(facility),
    }));

    const overridesMenu = (
      <Menu>
        <Menu.Item key="1" onClick={this.toggleShowOverheadCostsModal}>
          Overhead Costs
        </Menu.Item>
        <Menu.Item key="2" onClick={this.handleShowVesselOverridesModal}>
          Vessel Rates & Quantities
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="portfolio-list">
        <Modal
          centered
          visible={exportLoading}
          wrapClassName="portfolio-list-modal-loading"
          closable={false}
          footer={null}
        >
          <Spin tip="Exporting Portfolio Spreadsheet..." indicator={<Icon type="loading" />} />
        </Modal>
        <Modal
          centered
          visible={!!exportError.length}
          wrapClassName="portfolio-list-export-error"
          footer={null}
          title="Error"
          onCancel={this.props.onClearExportError}
        >
          <Icon type="close-circle" />
          {exportError}
        </Modal>
        <Modal
          centered
          visible={showOverheadCostsModal}
          wrapClassName="portfolio-list-overhead-costs"
          title="Overhead Costs"
          onOk={this.handleSaveUserGlobals}
          onCancel={this.toggleShowOverheadCostsModal}
          destroyOnClose={true}
          footer={[
            <Button key="reset" type="danger" onClick={this.handleResetUserGlobalOverrides}>
              Restore Default Costs
            </Button>,
            <Button key="back" onClick={this.toggleShowOverheadCostsModal}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={isEmpty(userGlobalEdits)}
              onClick={this.handleSaveUserGlobals}
            >
              Confirm
            </Button>,
          ]}
        >
          <div>Overhead costs are set at a global level but can be overridden here.</div>
          <Row type="flex" className="header-row">
            <Col span={8}>Name</Col>
            <Col span={8}>Default</Col>
            <Col span={8}>Portfolio Override</Col>
          </Row>
          <Row type="flex">
            <Col span={8}>Contractor Engineering:</Col>
            <Col span={8}>{`${+(appGlobals.contractorEngineering * 100).toFixed(1)}%`}</Col>
            <Col span={8}>
              <LabeledNumericInput
                key={userGlobals.contractorEngineering}
                isEditable={true}
                min={0}
                value={+(userGlobals.contractorEngineering * 100).toFixed(1)}
                onChange={this.handleChangeField("contractorEngineering")}
                singularUnit="%"
                pluralUnits="%"
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col span={8}>Project Management:</Col>
            <Col span={8}>{`${+(appGlobals.projectManagement * 100).toFixed(1)}%`}</Col>
            <Col span={8}>
              <LabeledNumericInput
                key={userGlobals.projectManagement}
                isEditable={true}
                min={0}
                value={+(userGlobals.projectManagement * 100).toFixed(1)}
                onChange={this.handleChangeField("projectManagement")}
                singularUnit="%"
                pluralUnits="%"
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col span={8}>DAR:</Col>
            <Col span={8}>{`${+(appGlobals.DAR * 100).toFixed(1)}%`}</Col>
            <Col span={8}>
              <LabeledNumericInput
                key={userGlobals.DAR}
                isEditable={true}
                min={0}
                value={+(userGlobals.DAR * 100).toFixed(1)}
                onChange={this.handleChangeField("DAR")}
                singularUnit="%"
                pluralUnits="%"
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col span={8}>Contingency:</Col>
            <Col span={8}>{`${+(appGlobals.contingency * 100).toFixed(1)}%`}</Col>
            <Col span={8}>
              <LabeledNumericInput
                key={userGlobals.contingency}
                isEditable={true}
                min={0}
                value={+(userGlobals.contingency * 100).toFixed(1)}
                onChange={this.handleChangeField("contingency")}
                singularUnit="%"
                pluralUnits="%"
              />
            </Col>
          </Row>
        </Modal>
        {showVesselOverridesModal && <VesselOverrides onClose={this.handleCloseVesselOverridesModal} />}
        <h1>
          <Icon type="profile" className="icon" />
          Portfolio
        </h1>
        <div className="portfolio-list-controls">
          <div>
            <Button className="portfolio-list-controls__add" type="primary" onClick={this.props.onAddFacilities}>
              Add Facilities
            </Button>
            {selected.length > 0 && (
              <Button className="portfolio-list-controls__remove" type="danger" onClick={this.handleRemoveFacilities}>
                {`Remove ${selected.length > 1 ? "Facilities" : "Facility"}`}
              </Button>
            )}
          </div>
          <div>
            <Dropdown overlay={overridesMenu} className="portfolio-list-controls__overrides">
              <Button>
                Overrides
                <Icon type="down" />
              </Button>
            </Dropdown>
            <Popover
              content={
                <PortfolioFilter
                  overlayClassName="portfolio-filters"
                  filters={filters}
                  onFilterChange={this.handleFilterChange}
                  options={this.gatherFilterOptions(facilities)}
                />
              }
              onVisibleChange={this.handleShowFiltersChange}
              placement="bottomRight"
              trigger="click"
              visible={showFilters}
            >
              <Button className="portfolio-list-controls__filters">Filters</Button>
            </Popover>
            <Button
              className="portfolio-list-controls__export"
              type="primary"
              disabled={!selected.length}
              onClick={this.handleExport}
            >
              Export to Actenum
            </Button>
            <Button
              className="portfolio-list-controls__audit"
              type="primary"
              disabled={!selected.length}
              onClick={this.handleExportAudit}
            >
              Generate Formula Audit
            </Button>
          </div>
        </div>
        {selected.length > 0 && (
          <Alert
            className="portfolio-list__alert"
            message={`you have selected ${selected.length} ${selected.length > 1 ? "facilities" : "facility"}`}
            showIcon={true}
            iconType="info-circle"
            type="info"
          />
        )}
        <Table
          loading={portfolioLoading}
          className="portfolio-list__table scrollable-table"
          pagination={false}
          rowSelection={this.rowSelection()}
          columns={this.columns()}
          dataSource={data}
          useFixedHeader={true}
        />
      </div>
    );
  }
}

export default PortfolioList;
