import React, { Component } from "react";
import { connect } from "react-redux";
import Main from "layout/Main";
import PortfolioList from "components/PortfolioList";
import { PortfolioType } from "types/portfolio";
import { Globals } from "types/admin";
import HistoryType from "types/history";
import PropTypes from "prop-types";
import {
  getPortfolio,
  removeFromPortfolio,
  downloadAudit,
  downloadExport,
  clearExportError,
} from "redux/ducks/portfolio";
import { getGlobals, getUserGlobals, saveUserGlobals } from "redux/ducks/admin";
import { withRouter } from "react-router-dom";
import { withHomeIcon } from "utils/breadcrumbs/home";

import "./style.less";

class Portfolio extends Component {
  static propTypes = {
    facilities: PortfolioType,
    appGlobals: Globals,
    userGlobals: Globals,
    exportLoading: PropTypes.bool.isRequired,
    portfolioLoading: PropTypes.bool.isRequired,
    exportError: PropTypes.string.isRequired,
    history: HistoryType.isRequired,
    getPortfolio: PropTypes.func.isRequired,
    downloadAudit: PropTypes.func.isRequired,
    downloadExport: PropTypes.func.isRequired,
    clearExportError: PropTypes.func.isRequired,
    removeFromPortfolio: PropTypes.func.isRequired,
    getGlobals: PropTypes.func.isRequired,
    getUserGlobals: PropTypes.func.isRequired,
    saveUserGlobals: PropTypes.func.isRequired,
  };

  static defaultProps = {
    facilities: [],
    appGlobals: {},
    userGlobals: {},
  };

  componentDidMount() {
    this.props.getPortfolio();
    this.props.getGlobals();
    this.props.getUserGlobals();
  }

  handleStrategyNavigate = facilityID => () => {
    this.props.history.push(`/facility/${facilityID}/strategies`);
  };

  handleFacilityNavigate = facilityID => () => {
    this.props.history.push(`/facility/${facilityID}/general`);
  };

  handleDownloadAudit = facilityIDs => {
    this.props.downloadAudit(facilityIDs);
  };

  handleDownloadExport = facilityIDs => {
    this.props.downloadExport(facilityIDs);
  };

  handleAddFacilities = () => {
    this.props.history.push("/facilities");
  };

  handleRemoveFacilities = (facilities, shouldRemoveStrategies) => {
    this.props.removeFromPortfolio(facilities.map(f => f.FacilityID), shouldRemoveStrategies);
  };

  handleClearExportError = () => {
    this.props.clearExportError();
  };

  handleSaveUserGlobals = userGlobals => {
    this.props.saveUserGlobals(userGlobals);
  };

  render() {
    const { facilities, exportLoading, exportError, portfolioLoading, appGlobals, userGlobals } = this.props;
    const breadcrumbs = [withHomeIcon()];

    return (
      <Main className="portfolio-page" breadcrumbs={breadcrumbs}>
        <PortfolioList
          appGlobals={appGlobals}
          userGlobals={userGlobals}
          facilities={facilities}
          exportLoading={exportLoading}
          exportError={exportError}
          portfolioLoading={portfolioLoading}
          onStrategyNavigate={this.handleStrategyNavigate}
          onFacilityNavigate={this.handleFacilityNavigate}
          onDownloadAudit={this.handleDownloadAudit}
          onDownloadExport={this.handleDownloadExport}
          onAddFacilities={this.handleAddFacilities}
          onRemoveFacilities={this.handleRemoveFacilities}
          onClearExportError={this.handleClearExportError}
          onSaveUserGlobals={this.handleSaveUserGlobals}
        />
      </Main>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getPortfolio: () => dispatch(getPortfolio()),
    removeFromPortfolio: (facilityIDs, shouldRemoveStrategies) =>
      dispatch(removeFromPortfolio(facilityIDs, shouldRemoveStrategies)),
    downloadAudit: facilityIDs => dispatch(downloadAudit(facilityIDs)),
    downloadExport: facilityIDs => dispatch(downloadExport(facilityIDs)),
    clearExportError: () => dispatch(clearExportError()),
    getGlobals: () => dispatch(getGlobals()),
    getUserGlobals: () => dispatch(getUserGlobals()),
    saveUserGlobals: userGlobals => dispatch(saveUserGlobals(userGlobals)),
  };
}

function mapStateToProps({ portfolio, admin }) {
  return {
    facilities: portfolio.facilities,
    exportLoading: portfolio.exportLoading,
    exportError: portfolio.exportError,
    portfolioLoading: portfolio.isLoading,
    appGlobals: admin.globals,
    userGlobals: admin.userGlobals,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Portfolio)
);
