import PropTypes from "prop-types";

export const PortfolioType = PropTypes.arrayOf(
  PropTypes.shape({
    FacilityID: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    IncompletePhaseNames: PropTypes.arrayOf(PropTypes.string.isRequired),
  })
);

export default {
  PortfolioType,
};
