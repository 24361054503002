import React from "react";
import PropTypes from "prop-types";
import { Alert, Divider } from "antd";
import RequestForgotPassword from "./RequestForgotPassword";
import ConfirmForgotPassword from "./ConfirmForgotPassword";
import "./style.less";

const ForgotPassword = ({ onRequestForgotPassword, onConfirmForgotPassword, error, success }) => (
  <div className="forgot-password">
    {error && <Alert message={error} type="error" showIcon />}
    {success && <Alert message={success} type="success" showIcon />}
    <RequestForgotPassword onSubmit={onRequestForgotPassword} />
    <Divider />
    <ConfirmForgotPassword onSubmit={onConfirmForgotPassword} />
  </div>
);

ForgotPassword.propTypes = {
  onRequestForgotPassword: PropTypes.func.isRequired,
  onConfirmForgotPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.string,
};

ForgotPassword.defaultProps = {
  error: null,
  success: null,
};

export default ForgotPassword;
