export function getFacilitiesForCountry(facilities) {
  return (facilities && facilities.list ? facilities.list.filteredFacilities : null) || [];
}

export function getFacilitiesError(facilities) {
  return (facilities && facilities.error ? facilities.error.message || "Could not load data" : null) || "";
}

export function areFacilitiesLoading(facilities) {
  return !facilities || facilities.isLoading;
}

export function isFacilityLoading(facilities, facility) {
  return !facility || facilities.isLoading;
}

export default {
  getFacilitiesForCountry,
  getFacilitiesError,
  areFacilitiesLoading,
};
