import React from "react";
import PropTypes from "prop-types";
import LabeledNumericInput from "components/LabeledNumericInput";

const LabeledInputWithUnits = ({ label, value, onChange, isEditable, className, min, max }) => {
  const handleChange = u => ({ target }) => {
    onChange(`${target.value === null ? "" : target.value} ${u}`);
  };

  let inputValue = null;
  let inputUnits = null;
  let units = null;

  if (value) {
    [inputValue, ...units] = value.split(" ");
    inputUnits = units.join(" ");
  }

  return (
    <LabeledNumericInput
      label={label}
      value={Number(inputValue)}
      onChange={handleChange(inputUnits)}
      className={className}
      isEditable={isEditable}
      pluralUnits={inputUnits}
      singularUnit={inputUnits}
      min={min}
      max={max}
    />
  );
};

LabeledInputWithUnits.propTypes = {
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

LabeledInputWithUnits.defaultProps = {
  isEditable: true,
  value: null,
  className: "",
  min: undefined,
  max: undefined,
};

export default LabeledInputWithUnits;
