import api from "utils/api";

function getCapabilities() {
  return api.get("/api/vessels/capabilities");
}

function getVesselTypes() {
  return api.get("/api/vesselTypes");
}

function getVesselTypesWithCapabilities(CountryID) {
  return api.get(`/api/vesselTypesWithCapabilities/${CountryID}`);
}

function getVesselTypesAndOverrides(CountryID) {
  return api.get(`/api/vessels/country/${CountryID}`);
}

function updateVesselType(VesselTypeID, CountryID, edits) {
  return api.patch(`/api/vesselType/${VesselTypeID}`, { ...edits, CountryID });
}

function updateVesselTypeOverrides(VesselTypeID, CountryID, edits) {
  return api.post(`/api/vessels/country/${CountryID}`, { ...edits, VesselTypeID });
}

export default {
  getCapabilities,
  getVesselTypes,
  getVesselTypesAndOverrides,
  getVesselTypesWithCapabilities,
  updateVesselType,
  updateVesselTypeOverrides,
};
