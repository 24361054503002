import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, reduce, some } from "lodash";
import { connect } from "react-redux";
import { Alert, Button, InputNumber, Modal, Select, Table } from "antd";
import { loadVesselTypesAndOverrides, updateVesselTypeOverrides } from "redux/ducks/vessel-types";
import { loadCountries, loadCountry } from "redux/ducks/countries";
import { VesselAndCountryOverridesType } from "types/vessel";
import { getCountries } from "redux/selectors/countries";
import CountryType from "types/country";

import "./style.less";

const { Option } = Select;

const rowKey = "VesselTypeID";

class VesselOverrides extends Component {
  static propTypes = {
    error: PropTypes.string,
    vessels: PropTypes.arrayOf(VesselAndCountryOverridesType),
    loading: PropTypes.bool.isRequired,
    country: CountryType.isRequired,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    loadVesselTypesAndOverrides: PropTypes.func.isRequired,
    loadCountries: PropTypes.func.isRequired,
    loadCountry: PropTypes.func.isRequired,
    updateVesselTypeOverrides: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    vessels: [],
  };

  state = {
    edits: {},
    editingKey: null,
    closing: false, // closing is true while "hide" animation is running, after which onClose prop is called to destroy component
  };

  componentDidMount() {
    const countryID = localStorage.getItem("CountryID");
    if (countryID) {
      this.props.loadCountry(countryID);
      this.props.loadVesselTypesAndOverrides(countryID);
    }
    this.props.loadCountries();
  }

  handleClose = () => {
    this.setState({ closing: true });
  };

  handleCountrySelect = value => {
    this.handleCancelEdit();
    this.props.loadCountry(value);
    this.props.loadVesselTypesAndOverrides(value);
  };

  handleCancelEdit = () => {
    this.setState({ editingKey: null, edits: {} });
  };

  handleEdit = key => () => {
    this.setState({ editingKey: key, edits: {} });
  };

  handleSave = () => {
    const validEdits = reduce(
      this.state.edits,
      (result, value, key) => {
        if (value === "" || value === null) {
          result[key] = null;
        } else if (!Number.isNaN(Number(value))) {
          result[key] = Number(value);
        }
        return result;
      },
      {}
    );
    this.props.updateVesselTypeOverrides(this.state.editingKey, this.props.country.CountryID, validEdits).then(() => {
      this.handleCancelEdit();
    });
  };

  handleUpdate = fieldName => value => {
    this.setState(prevState => {
      return { edits: { ...prevState.edits, [fieldName]: value } };
    });
  };

  renderOverrideCell = (fieldName, value, record) => {
    const { editingKey, edits } = this.state;
    const editing = editingKey === record[rowKey];
    return editing ? (
      <InputNumber
        min={0}
        onChange={this.handleUpdate(fieldName)}
        value={editing && fieldName in edits ? edits[fieldName] : value}
      />
    ) : (
      value
    );
  };

  columns = [
    {
      title: "Name",
      dataIndex: "Name",
      width: "16em",
      render: (text, record) => <span>{`(${record.Code}) - ${text}`}</span>,
    },
    {
      align: "center",
      className: "grouped-column",
      title: "Day Rate",
      children: [
        {
          className: "default-field",
          dataIndex: "DayRate",
          title: "Default",
          width: "6em",
          render: DayRate => <span>{DayRate !== null ? DayRate : "-"}</span>,
        },
        {
          className: "override-field",
          dataIndex: "override.DayRate",
          title: "Override",
          width: "6em",
          render: (value, record) => this.renderOverrideCell("DayRate", value, record),
        },
      ],
    },
    {
      align: "center",
      className: "grouped-column",
      title: "Quantity",
      children: [
        {
          className: "default-field",
          dataIndex: "Quantity",
          title: "Default",
          width: "6em",
          render: Quantity => <span>{Quantity !== null ? Quantity : "-"}</span>,
        },
        {
          className: "override-field",
          dataIndex: "override.Quantity",
          title: "Override",
          width: "6em",
          render: (value, record) => this.renderOverrideCell("Quantity", value, record),
        },
      ],
    },
    {
      align: "center",
      render: (text, record) => {
        const { editingKey, edits } = this.state;
        const editable = editingKey === record[rowKey];
        const hasInvalidValues = some(edits, value => value !== null && value !== "" && Number.isNaN(Number(value)));
        return editable ? (
          <span className="override-save-controls">
            <Button
              disabled={isEmpty(edits) || hasInvalidValues}
              onClick={this.handleSave}
              shape="round"
              size="small"
              type="primary"
            >
              Save
            </Button>
            <Button icon="close" onClick={this.handleCancelEdit} shape="round" size="small" />
          </span>
        ) : (
          <Button
            className="override-edit-control"
            disabled={editingKey !== null}
            onClick={this.handleEdit(record[rowKey])}
            size="small"
          >
            Edit
          </Button>
        );
      },
      width: "8em",
    },
  ];

  render() {
    const { vessels, loading, countries, country, onClose, error } = this.props;
    const { closing } = this.state;

    return (
      <Modal
        afterClose={onClose}
        className="portfolio-list-vessel-overrides"
        closable={false}
        destroyOnClose={true}
        footer={
          <Button key="back" type="primary" onClick={this.handleClose}>
            Done
          </Button>
        }
        title={
          <div>
            Vessel Overrides for
            <Select
              dropdownMatchSelectWidth={false}
              className="country-select"
              value={country.CountryID}
              onSelect={this.handleCountrySelect}
            >
              {countries.map(c => (
                <Option key={c.CountryID} value={c.CountryID}>
                  {c.Name}
                </Option>
              ))}
            </Select>
          </div>
        }
        visible={!closing}
        width={900}
        wrapClassName="scrollable-modal"
      >
        <div className="description">
          Per-country vessel rates and quantities are set at a global level but can be overridden here.
        </div>
        {error && <Alert message="Error" description={error} type="error" showIcon />}
        <Table
          className="vessel-overrides__table scrollable-table"
          columns={this.columns}
          dataSource={vessels}
          loading={loading}
          pagination={false}
          rowKey={rowKey}
          useFixedHeader={true}
          size="small"
        />
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadVesselTypesAndOverrides: CountryID => dispatch(loadVesselTypesAndOverrides(CountryID)),
    loadCountries: () => dispatch(loadCountries()),
    loadCountry: countryID => dispatch(loadCountry(countryID)),
    updateVesselTypeOverrides: (VesselTypeID, CountryID, edits) =>
      dispatch(updateVesselTypeOverrides(VesselTypeID, CountryID, edits)),
  };
}

function mapStateToProps({ vesselTypes, countries }) {
  return {
    countries: getCountries(countries),
    country: countries.country,
    vessels: vesselTypes.vesselsAndOverrides || [],
    loading: vesselTypes.loading,
    error: vesselTypes.error,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VesselOverrides);
