import Auth, { completeNewPasswordChallenge } from "utils/auth";
import { handle } from "redux-pack";

export const LOGIN = "auth/LOGIN";
export const VERIFY_USER = "auth/VERIFY_USER";
export const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD";
export const LOGOUT = "auth/LOGOUT";
export const REQUEST_FORGOT_PASSWORD = "auth/REQUEST_FORGOT_PASSWORD";
export const CONFIRM_FORGOT_PASSWORD = "auth/CONFIRM_FORGOT_PASSWORD";

const initialState = {
  loading: false,
  isAuthenticated: false,
  username: "",
  userGroups: [],
  changePassword: false,
  cognitoUser: null,
  error: null,
  success: null,
};

export function login(username, password) {
  return {
    type: LOGIN,
    promise: Auth.signIn(username, password),
    meta: {
      password,
    },
  };
}

export function verifyUser() {
  return {
    type: VERIFY_USER,
    promise: Auth.currentSession(),
  };
}

export function logout() {
  return {
    type: LOGOUT,
    promise: Auth.signOut(),
  };
}

export function requestForgotPassword(username) {
  return {
    type: REQUEST_FORGOT_PASSWORD,
    promise: Auth.forgotPassword(username),
  };
}

export function confirmForgotPassword(username, code, password) {
  return {
    type: CONFIRM_FORGOT_PASSWORD,
    promise: Auth.forgotPasswordSubmit(username, code, password),
  };
}

export function changePassword(cognitoUser, password) {
  return {
    type: CHANGE_PASSWORD,
    promise: completeNewPasswordChallenge(cognitoUser, password),
  };
}

// Cognito will either return an attribute containing the groups or it will
// omit the groups attribute.
const extractUserInfo = ({ accessToken }) => ({
  username: accessToken.payload.username,
  userGroups: accessToken.payload["cognito:groups"] || [],
});

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_USER:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => ({
          ...prevState,
          isAuthenticated: true,
          ...extractUserInfo(payload),
        }),
      });
    case LOGIN:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => {
          const { signInUserSession, challengeName } = payload;

          if (challengeName && challengeName === "NEW_PASSWORD_REQUIRED") {
            return {
              ...prevState,
              changePassword: true,
              cognitoUser: payload,
            };
          }

          return {
            ...prevState,
            isAuthenticated: true,
            ...extractUserInfo(signInUserSession),
          };
        },
      });
    case CHANGE_PASSWORD:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => ({
          ...prevState,
          isAuthenticated: true,
          changePassword: false,
          cognitoUser: null,
          ...extractUserInfo(payload),
        }),
      });
    case REQUEST_FORGOT_PASSWORD:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => ({
          ...prevState,
          success: `Successful password reset request via ${payload.CodeDeliveryDetails.DeliveryMedium} to ${payload.CodeDeliveryDetails.Destination}`,
        }),
      });
    case CONFIRM_FORGOT_PASSWORD:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
          success: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload.message }),
        success: prevState => ({
          ...prevState,
          success: "Successfully reset your password",
        }),
      });
    case LOGOUT:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: () => initialState,
        success: () => initialState,
      });
    default:
      return state;
  }
};

export default reducer;
