import api from "utils/api";

function getCountries() {
  return api.get("/api/countries");
}

function getCountry(countryID) {
  return api.get(`/api/countries/${countryID}`);
}

function getCountryRates(countryID) {
  return api.get(`/api/countries/${countryID}/rates`);
}

function saveCountryRates(countryID, rates) {
  return api.post(`/api/countries/${countryID}/rates`, rates);
}

export default {
  getCountries,
  getCountry,
  getCountryRates,
  saveCountryRates,
};
