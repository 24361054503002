import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import api from "utils/api";
import { get } from "lodash";

export const LOAD_VESSEL_TYPES = "vesselTypes/LOAD_VESSEL_TYPES";
export const LOAD_CAPABILITIES = "vesselTypes/LOAD_CAPABILITIES";
export const UPDATE_VESSEL_TYPE = "vesselType/UPDATE_VESSEL_TYPE";
export const UPDATE_VESSEL_TYPE_OVERRIDES = "vesselType/UPDATE_VESSEL_TYPE_OVERRIDES";
export const LOAD_VESSEL_TYPES_AND_OVERRIDES = "vesselTypes/LOAD_VESSEL_TYPES_AND_OVERRIDES";
export const LOAD_VESSEL_TYPES_WITH_CAPABILITIES = "vesselTypes/LOAD_VESSEL_TYPES_WITH_CAPABILITIES";

export function loadVesselTypes() {
  return {
    type: LOAD_VESSEL_TYPES,
    promise: api.vesselTypes.getVesselTypes(),
  };
}

export function loadVesselTypesAndOverrides(CountryID) {
  return {
    type: LOAD_VESSEL_TYPES_AND_OVERRIDES,
    promise: api.vesselTypes.getVesselTypesAndOverrides(CountryID),
  };
}

export function loadVesselTypesWithCapabilities(CountryID) {
  return {
    type: LOAD_VESSEL_TYPES_WITH_CAPABILITIES,
    promise: api.vesselTypes.getVesselTypesWithCapabilities(CountryID),
  };
}

export function loadCapabilities() {
  return {
    type: LOAD_CAPABILITIES,
    promise: api.vesselTypes.getCapabilities(),
  };
}

export function updateVesselType(VesselTypeID, CountryID, edits) {
  return {
    type: UPDATE_VESSEL_TYPE,
    promise: api.vesselTypes.updateVesselType(VesselTypeID, CountryID, edits),
  };
}

export function updateVesselTypeOverrides(VesselTypeID, CountryID, edits) {
  return {
    type: UPDATE_VESSEL_TYPE_OVERRIDES,
    promise: api.vesselTypes.updateVesselTypeOverrides(VesselTypeID, CountryID, edits),
  };
}

const initialState = {
  loading: false,
  error: null,
  list: null,
  vesselsAndOverrides: [],
  vesselsWithCapabilities: [],
  capabilities: [],
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case LOAD_VESSEL_TYPES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not load vessel types" }),
        success: prevState => ({ ...prevState, list: payload.data }),
      });
    case LOAD_CAPABILITIES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not load capabilities" }),
        success: prevState => ({ ...prevState, capabilities: payload.data }),
      });
    case UPDATE_VESSEL_TYPE:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not update vessel type" }),
        success: prevState => ({ ...prevState, vesselsWithCapabilities: payload.data }),
      });
    case UPDATE_VESSEL_TYPE_OVERRIDES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not update vessel type overrides" }),
        success: prevState => ({ ...prevState, vesselsAndOverrides: payload.data }),
      });
    case LOAD_VESSEL_TYPES_AND_OVERRIDES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not load vessels and overrides" }),
        success: prevState => ({ ...prevState, vesselsAndOverrides: payload.data }),
      });
    case LOAD_VESSEL_TYPES_WITH_CAPABILITIES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not load vessels with capabilities" }),
        success: prevState => ({ ...prevState, vesselsWithCapabilities: payload.data }),
      });
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
