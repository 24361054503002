import { get, flow, isEmpty } from "lodash";
import { LIFECYCLE } from "redux-pack";

const reduxLifecycle = action => get(action, ["meta", "redux-pack/LIFECYCLE"]);

export const isReduxPackAction = flow([reduxLifecycle, a => !isEmpty(a)]);

export const anySuccess = action => reduxLifecycle(action) === LIFECYCLE.SUCCESS;

export const packSuccess = (action, type) => action.type === type && anySuccess(action);

export const anyFailure = action => reduxLifecycle(action) === LIFECYCLE.FAILURE;

export const packFailure = (action, type) => action.type === type && anyFailure(action);
