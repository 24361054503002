import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import LabeledNumericInput from "components/LabeledNumericInput";
import FacilityDetailType from "types/facility";

import "./style.less";

class Subsea extends Component {
  static propTypes = {
    facility: FacilityDetailType.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onChangeField: PropTypes.func.isRequired,
    unsavedFields: PropTypes.object.isRequired,
    facilityTaskVariables: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    facilityTaskVariables: [],
  };

  handleChangeNumericField = key => event => {
    this.props.onChangeField(key, +event.target.value);
  };

  highlightTaskVariableField = key => (this.props.facilityTaskVariables.includes(key) ? "highlight-field" : "");

  render() {
    const { facility, isEditable, unsavedFields } = this.props;

    const { subsea } = facility;

    const fieldValues = {
      Area: subsea.Area,
      WaterDepth: subsea.WaterDepth,
      Weight: subsea.Weight,
      ...unsavedFields,
    };

    return (
      <div className="subsea-layout">
        <h2>Subsea</h2>
        <Row type="flex">
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Area:"
              value={fieldValues.Area}
              min={0}
              onChange={this.handleChangeNumericField("Area")}
              className={this.highlightTaskVariableField("Area")}
              singularUnit="Sq. Meter"
              pluralUnits="Sq. Meters"
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Water Depth:"
              value={fieldValues.WaterDepth}
              min={0}
              onChange={this.handleChangeNumericField("WaterDepth")}
              className={this.highlightTaskVariableField("WaterDepth")}
              singularUnit="Meter"
              pluralUnits="Meters"
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Weight:"
              value={fieldValues.Weight}
              min={0}
              onChange={this.handleChangeNumericField("Weight")}
              className={this.highlightTaskVariableField("Weight")}
              singularUnit="Tonne"
              pluralUnits="Tonnes"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Subsea;
