import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Alert } from "antd";
import MainLayout from "layout/Main";
import { withHomeIcon } from "utils/breadcrumbs/home";

import "./style.less";

class EditablePage extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onCancelEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    saveError: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string,
      })
    ).isRequired,
  };

  render() {
    const { isEditable, isEditing, onEdit, onCancelEdit, onSave, saveError } = this.props;

    const breadcrumbs = [withHomeIcon()].concat(this.props.breadcrumbs);

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="editable-page card-layout">
        <div className="content">
          {saveError ? <Alert message={saveError} type="error" /> : null}
          {(isEditing || isEditable) && (
            <div className="edit-controls">
              {isEditing ? (
                <>
                  <Button type="primary" onClick={onSave}>
                    Save
                  </Button>
                  <Button className="edit-controls__cancel" onClick={onCancelEdit}>
                    Cancel
                  </Button>
                </>
              ) : (
                <Button onClick={onEdit}>Edit</Button>
              )}
            </div>
          )}
          <div className="facility-data">{this.props.children}</div>
        </div>
      </MainLayout>
    );
  }
}

export default EditablePage;
