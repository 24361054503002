import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout } from "antd";

import "./style.less";

const { Content } = Layout;

class PreLogin extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    const { children } = this.props;
    return (
      <div className="App">
        <Layout>
          <Content className="prelogin-layout">{children}</Content>
        </Layout>
      </div>
    );
  }
}

export default PreLogin;
