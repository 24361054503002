import PropTypes from "prop-types";
import PlatformType from "types/platform";

export default PropTypes.shape({
  FacilityID: PropTypes.number,
  Name: PropTypes.string.isRequired,
  ComplexID: PropTypes.number,
  CoPDate: PropTypes.string,
  CountryID: PropTypes.number,
  FacilityFunctionID: PropTypes.number,
  YearInstalled: PropTypes.string,
  LocationID: PropTypes.number,
  archetype: PropTypes.string.isRequired,
  complex: PropTypes.shape({
    ComplexID: PropTypes.number,
    Name: PropTypes.string,
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      LocationID: PropTypes.number,
      LocationTypeID: PropTypes.number,
      Coordinate: PropTypes.shape({
        lng: PropTypes.number,
        lat: PropTypes.number,
      }),
      locationType: PropTypes.shape({
        LocationTypeID: PropTypes.number,
        Name: PropTypes.string,
      }),
    })
  ),
  platform: PlatformType,
  subArchetype: PropTypes.shape({
    SubArchetypeID: PropTypes.number,
    Name: PropTypes.string,
    ArchetypeID: PropTypes.number,
    archetype: PropTypes.shape({
      ArchetypeID: PropTypes.number,
      Name: PropTypes.string,
    }),
  }),
  country: PropTypes.shape({
    CountryID: PropTypes.number,
    Name: PropTypes.string,
  }),
  facilityFunction: PropTypes.shape({
    FacilityFunctionID: PropTypes.number,
    Name: PropTypes.string,
  }),
});
