import PropTypes from "prop-types";

export const activityForTimeframesType = PropTypes.shape({
  earliestFinish: PropTypes.string,
  earliestStart: PropTypes.string,
  latestFinish: PropTypes.string,
  latestStart: PropTypes.string,
  maximumOffset: PropTypes.string,
  minimumOffset: PropTypes.string,
  name: PropTypes.string.isRequired,
});

export const phaseForTimeframesType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  phaseID: PropTypes.number.isRequired,
  activities: PropTypes.arrayOf(activityForTimeframesType),
});

export const subarchetypeForTimeframesType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  phases: PropTypes.arrayOf(phaseForTimeframesType),
});

export const activityTimeframesType = PropTypes.shape({
  subarchetypes: PropTypes.arrayOf(subarchetypeForTimeframesType),
});

export default {
  activityTimeframesType,
};
