import PropTypes from "prop-types";

export const Globals = PropTypes.shape({
  DAR: PropTypes.string,
  contingency: PropTypes.string,
  contractorEngineering: PropTypes.string,
  projectManagement: PropTypes.string,
});

export default {
  Globals,
};
