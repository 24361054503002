import React, { Component } from "react";
import PropTypes from "prop-types";
import { Radio, Row, Col } from "antd";
import LabeledNumericInput from "components/LabeledNumericInput";
import FacilityDetailType from "types/facility";
import Topside from "components/Topside";
import Substructure from "components/Substructure";

import "./style.less";

const RadioGroup = Radio.Group;

class Platform extends Component {
  static propTypes = {
    facility: FacilityDetailType.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onChangeField: PropTypes.func.isRequired,
    unsavedFields: PropTypes.object.isRequired,
    facilityTaskVariables: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    facilityTaskVariables: [],
  };

  handleChangeNumericField = key => event => {
    this.props.onChangeField(key, +event.target.value);
  };

  handleDrillCuttingPile = event => {
    this.props.onChangeField("DrillCuttingPile", event.target.value);
  };

  highlightTaskVariableField = key => (this.props.facilityTaskVariables.includes(key) ? "highlight-field" : "");

  render() {
    const { facility, facilityTaskVariables, isEditable, onChangeField, unsavedFields } = this.props;

    const { platform } = facility;

    const fieldValues = {
      WaterDepth: platform.WaterDepth,
      DrillCuttingPile: platform.DrillCuttingPile,
      NumberOfBeds: platform.NumberOfBeds,
      ...unsavedFields,
    };

    return (
      <>
        <div className="platform-layout">
          <h2>Platform</h2>
          <Row type="flex">
            <Col span={8}>
              <LabeledNumericInput
                isEditable={isEditable}
                label="Water Depth:"
                value={fieldValues.WaterDepth}
                min={0}
                onChange={this.handleChangeNumericField("WaterDepth")}
                className={this.highlightTaskVariableField("WaterDepth")}
                singularUnit="Meter"
                pluralUnits="Meters"
              />
            </Col>
            <Col span={8}>
              <div className={`labeled-field ${this.highlightTaskVariableField("DrillCuttingPile")}`}>
                <div className="label">Drill Cuttings Present:</div>
                <div className="value">
                  {isEditable ? (
                    <RadioGroup value={fieldValues.DrillCuttingPile} onChange={this.handleDrillCuttingPile}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </RadioGroup>
                  ) : (
                    `${fieldValues.DrillCuttingPile ? "Yes" : "No"}`
                  )}
                </div>
              </div>
            </Col>
            <Col span={8}>
              <LabeledNumericInput
                isEditable={isEditable}
                label="Number of Beds:"
                value={fieldValues.NumberOfBeds}
                min={0}
                onChange={this.handleChangeNumericField("NumberOfBeds")}
                className={this.highlightTaskVariableField("NumberOfBeds")}
              />
            </Col>
          </Row>
        </div>
        <Topside
          facility={facility}
          isEditable={isEditable}
          onChangeField={onChangeField}
          unsavedFields={unsavedFields}
          facilityTaskVariables={facilityTaskVariables}
        />
        <Substructure
          facility={facility}
          isEditable={isEditable}
          onChangeField={onChangeField}
          unsavedFields={unsavedFields}
          facilityTaskVariables={facilityTaskVariables}
        />
      </>
    );
  }
}

export default Platform;
