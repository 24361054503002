import PropTypes from "prop-types";

export const BreadcrumbsType = PropTypes.arrayOf(
  PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.function,
    component: PropTypes.element,
  })
);

export default {
  BreadcrumbsType,
};
