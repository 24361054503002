import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import { get } from "lodash";
import api from "utils/api";

export const LOAD_LOCATION_TYPES = "locationTypes/LOAD_LOCATION_TYPES";

export function loadLocationTypes() {
  return {
    type: LOAD_LOCATION_TYPES,
    promise: api.locationTypes.getLocationTypes(),
  };
}

const initialState = { loading: false, error: null, list: null };

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case LOAD_LOCATION_TYPES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "Could not load location types" }),
        success: prevState => ({ ...prevState, list: payload.data }),
      });
    case LOGOUT:
    case RESET_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
