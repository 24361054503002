import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Tooltip, Modal, Select } from "antd";
import ApplicabilityItem from "components/ApplicabilityItem";
import { differenceBy } from "lodash";
import { ActivityType } from "types/activity";
import VesselType from "types/vessel";
import { renderFailedRulesList } from "utils/renderers";

import "./style.less";

const { Option } = Select;

const VesselsSingleRoleDisplay = ({
  ActivityID,
  RoleName,
  Vessels,
  allVesselTypes,
  showVesselDropdown,
  onVesselOverrideInclude,
  onVesselOverrideExclude,
  onVesselOverrideRemove,
  onToggleVesselDropdown,
  onVesselAdd,
}) => {
  const onMoreInfo = vessel => e => {
    e.preventDefault();
    Modal.warning({
      title: `Vessel “${vessel.Name}” Applicability`,
      content: vessel.applicable ? (
        <div>This vessel is applicable according to the available rules.</div>
      ) : (
        <div>
          {`This vessel is inapplicable due to the following rule${vessel.failedRules.length !== 1 ? "s" : ""}:`}
          {renderFailedRulesList(vessel.failedRules, true)}
        </div>
      ),
      width: "620px",
    });
  };

  const newVesselList = differenceBy(allVesselTypes, Vessels, "VesselTypeID");

  return (
    <div className="vessel-display" key={RoleName}>
      <h4>{RoleName ? `Vessels: ${RoleName}` : "Vessels"}</h4>
      <div className="vessel-items">
        {Vessels.length === 0 && <div className="vessel-item no-choices">No Vessels Found</div>}
        {Vessels.map(Vessel => {
          if (Vessel.manuallyExcluded) {
            return (
              <div key={Vessel.Name + ActivityID} className="manual-override">
                <Tooltip
                  placement="right"
                  title={
                    <div>
                      {`Override Reason: ${Vessel.Reason ? Vessel.Reason : "N/A"}`}
                      <div>Click Vessel icon to override</div>
                    </div>
                  }
                >
                  <Icon type="close-square" onClick={onVesselOverrideInclude(Vessel.VesselTypeID, RoleName)} />
                </Tooltip>
                <div className="manual-override-text">{Vessel.Name}</div>
                <Icon type="delete" onClick={onVesselOverrideRemove(Vessel.VesselTypeID, RoleName)} />
              </div>
            );
          }

          if (Vessel.manuallyIncluded) {
            return (
              <div key={Vessel.Name + ActivityID} className="manual-override">
                <Tooltip
                  placement="right"
                  title={
                    <div>
                      {`Override Reason: ${Vessel.Reason ? Vessel.Reason : "N/A"}`}
                      <div>Click Vessel icon to override</div>
                    </div>
                  }
                >
                  <Icon type="check-square" onClick={onVesselOverrideExclude(Vessel.VesselTypeID, RoleName)} />
                </Tooltip>
                <div className="manual-override-text">{Vessel.Name}</div>
                <Icon type="delete" onClick={onVesselOverrideRemove(Vessel.VesselTypeID, RoleName)} />
              </div>
            );
          }

          return (
            <ApplicabilityItem
              key={Vessel.Name + ActivityID}
              text={Vessel.Name}
              tooltipPlacement="left"
              tooltipText={
                Vessel.applicable ? (
                  <div>
                    <div>Applicable based on the facility’s attributes</div>
                    <div>Click Vessel icon to override</div>
                  </div>
                ) : (
                  <div>
                    {`Inapplicable due to the following rule${Vessel.failedRules.length !== 1 ? "s" : ""}.`}
                    {renderFailedRulesList(Vessel.failedRules, false)}
                    <Button onClick={onMoreInfo(Vessel)}>More Info</Button>
                    <div>Click Vessel icon to override</div>
                  </div>
                )
              }
              applicable={Vessel.applicable}
              onApplicableClick={onVesselOverrideExclude(Vessel.VesselTypeID, RoleName)}
              onInapplicableClick={onVesselOverrideInclude(Vessel.VesselTypeID, RoleName)}
            />
          );
        })}
      </div>
      {!showVesselDropdown ? (
        <Button size="small" className="vessel-add" onClick={onToggleVesselDropdown(RoleName)}>
          <Icon type="plus-circle" theme="outlined" />
          Add a Vessel
        </Button>
      ) : (
        <div className="vessel-select">
          <Select
            dropdownClassName="vessel-select__dropdown"
            dropdownMatchSelectWidth={false}
            defaultValue=""
            placeholder="Select a Vessel"
            data-testid="vessel-select"
            onChange={onVesselAdd(RoleName)}
            open={true}
          >
            {newVesselList.map(vessel => (
              <Option key={vessel.VesselTypeID}>{vessel.Name}</Option>
            ))}
          </Select>
          <Icon type="close" onClick={onToggleVesselDropdown(RoleName)} />
        </div>
      )}
    </div>
  );
};

VesselsSingleRoleDisplay.propTypes = {
  onVesselOverrideInclude: PropTypes.func.isRequired,
  onVesselOverrideExclude: PropTypes.func.isRequired,
  onVesselOverrideRemove: PropTypes.func.isRequired,
  ActivityID: PropTypes.number.isRequired,
  RoleName: PropTypes.string.isRequired,
  Vessels: PropTypes.arrayOf(VesselType).isRequired,
  allVesselTypes: PropTypes.arrayOf(VesselType).isRequired,
  showVesselDropdown: PropTypes.bool.isRequired,
  onToggleVesselDropdown: PropTypes.func.isRequired,
  onVesselAdd: PropTypes.func.isRequired,
};

export const VesselsDisplay = ({
  Activity,
  allVesselTypes,
  showVesselDropdownForRole,
  onVesselOverrideInclude,
  onVesselOverrideExclude,
  onVesselOverrideRemove,
  onToggleVesselDropdown,
  onVesselAdd,
}) => {
  const { ActivityID, VesselRoles, Methods, StrategySelection } = Activity;
  const method = Methods.find(m => m.MethodID === StrategySelection.MethodID);

  if (!method) {
    // don't show Vessels area at all if no method is selected
    return null;
  }

  if (!VesselRoles.length) {
    // simple "No Vessels Required" message, without heading, for methods
    // that don't use vessels
    return <div className="vessel-item no-choices">No Vessels Required</div>;
  }

  return VesselRoles.map(({ Name, Vessels }) => (
    <VesselsSingleRoleDisplay
      key={Name}
      RoleName={Name}
      showVesselDropdown={showVesselDropdownForRole === Name}
      {...{
        ActivityID,
        Vessels,
        allVesselTypes,
        onVesselOverrideInclude,
        onVesselOverrideExclude,
        onVesselOverrideRemove,
        onToggleVesselDropdown,
        onVesselAdd,
      }}
    />
  ));
};

VesselsDisplay.propTypes = {
  onVesselOverrideInclude: PropTypes.func.isRequired,
  onVesselOverrideExclude: PropTypes.func.isRequired,
  onVesselOverrideRemove: PropTypes.func.isRequired,
  Activity: ActivityType.isRequired,
  allVesselTypes: PropTypes.arrayOf(VesselType).isRequired,
  showVesselDropdownForRole: PropTypes.string,
  onToggleVesselDropdown: PropTypes.func.isRequired,
  onVesselAdd: PropTypes.func.isRequired,
};

export default VesselsDisplay;
