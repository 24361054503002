import React from "react";
import { InputNumber } from "antd";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import classNames from "classnames";

import "./style.less";

const LabeledNumericInput = ({
  label,
  min,
  max,
  onChange,
  value,
  singularUnit,
  pluralUnits,
  isEditable,
  className,
}) => {
  // The on onChange callback for the InputNumber component passes the event target value directly as an argument.
  // onBlur passes the entire event. Since the rest of the handlers that use this component expect the entire event object,
  // I made this mimic the event object so it didn't cascade all the way and require a significant change.
  const handleChange = v => {
    onChange({ target: { value: v } });
  };

  const hasNumericValue = +value === value; // flag null, undefined, NaN, "", other non-numeric
  // Note that the onChange handler is called onBlur instead of onChange,
  // to work around a problem with the insertion point position being lost
  // after each keypress due to React replacing the entire page DOM on each
  // state change. The fix is beyond the control of this component.
  // TODO: When the above issue is fixed, the onChange handler can be restored.
  return (
    <div className={classNames("labeled-field", className)}>
      {label && <span className="label">{label}</span>}
      {!isEditable && !hasNumericValue ? (
        <span className="value">—</span>
      ) : (
        <span className="value">
          {isEditable ? (
            <InputNumber
              defaultValue={hasNumericValue ? value : ""}
              min={min}
              max={max}
              onChange={debounce(handleChange, 250)}
            />
          ) : (
            value
          )}
          {value === 1 ? singularUnit && ` ${singularUnit}` : pluralUnits && ` ${pluralUnits}`}
        </span>
      )}
    </div>
  );
};

LabeledNumericInput.propTypes = {
  isEditable: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  singularUnit: PropTypes.string,
  pluralUnits: PropTypes.string,
};

LabeledNumericInput.defaultProps = {
  isEditable: true,
  min: undefined,
  max: undefined,
  singularUnit: "",
  pluralUnits: "",
  value: null,
  className: "",
  label: null,
};

export default LabeledNumericInput;
