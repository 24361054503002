import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Alert, Spin } from "antd";
import { connect } from "react-redux";
import EditablePage from "components/EditablePage";
import FacilityDetail from "components/FacilityDetail";
import { withRouter } from "react-router-dom";
import Pipeline from "components/Pipeline";
import Platform from "components/Platform";
import Subsea from "components/Subsea";
import { addComplex, loadComplexes, processAddComplexResponse } from "redux/ducks/complexes";
import { loadFacilityFunctions } from "redux/ducks/facility-functions";
import { loadLocationTypes } from "redux/ducks/location-types";
import {
  loadFacilityDetails,
  saveFacilityDetails,
  updateFacilityDetails,
  setEditFacility,
} from "redux/ducks/facilities";
import ComplexType from "types/complex";
import FacilityDetailsType from "types/facilityDetails";
import LocationTypePropTypes from "types/location-type";
import { FacilityFunctionPropType } from "types/facility";

class Facility extends Component {
  static propTypes = {
    complexes: PropTypes.arrayOf(ComplexType),
    complexesAreLoading: PropTypes.bool,
    complexesError: PropTypes.string,
    facilityID: PropTypes.string.isRequired,
    facility: FacilityDetailsType,
    facilityError: PropTypes.string,
    locationTypes: PropTypes.arrayOf(LocationTypePropTypes),
    facilityFunctions: PropTypes.arrayOf(FacilityFunctionPropType),
    facilityFunctionsError: PropTypes.string,
    saveError: PropTypes.string,
    loadFacilityDetails: PropTypes.func.isRequired,
    saveFacilityDetails: PropTypes.func.isRequired,
    updateFacilityDetails: PropTypes.func.isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    isEditing: PropTypes.bool.isRequired,
    setEditFacility: PropTypes.func.isRequired,
    unsavedFacilityFields: PropTypes.object.isRequired,
    addComplex: PropTypes.func.isRequired,
    loadComplexes: PropTypes.func.isRequired,
    loadLocationTypes: PropTypes.func.isRequired,
    loadFacilityFunctions: PropTypes.func.isRequired,
  };

  static defaultProps = {
    complexes: null,
    complexesAreLoading: false,
    complexesError: "",
    facility: null,
    facilityError: "",
    locationTypes: [],
    facilityFunctions: [],
    facilityFunctionsError: "",
    saveError: "",
  };

  componentDidMount() {
    if (!this.props.complexes && !this.props.complexesAreLoading && !this.props.complexesError) {
      this.props.loadComplexes();
    }
    if (!this.props.facility) {
      this.props.loadFacilityDetails(this.props.facilityID);
    }
    if (!this.props.locationTypes) {
      this.props.loadLocationTypes();
    }
    if (!this.props.facilityFunctions) {
      this.props.loadFacilityFunctions();
    }

    const facilityTaskVariables = get(this.props.location, "state.facilityVariables", []);

    if (facilityTaskVariables.length > 0) {
      this.props.setEditFacility(true);
    }
  }

  handleFacilityEdit = () => {
    this.props.setEditFacility(true);
  };

  handleCancelFacilityEdit = () => {
    this.props.setEditFacility(false);
  };

  handleFacilitySave = () => {
    const newFields = this.props.unsavedFacilityFields;
    if (Object.keys(newFields).length) {
      this.props.saveFacilityDetails(this.props.facilityID, this.props.facility.archetype, newFields);
    } else {
      this.props.setEditFacility(false);
    }
  };

  render() {
    const {
      complexes,
      complexesError,
      facility,
      facilityError,
      saveError,
      isEditing,
      unsavedFacilityFields,
      location,
      facilityFunctions,
      facilityFunctionsError,
      locationTypes,
    } = this.props;

    if (facilityError) {
      return <Alert message="Error" description={facilityError} type="error" showIcon />;
    }
    if (!facility) {
      return <Spin spinning={true} />;
    }

    // when navigating from the task page, we will provide a list of attributes to highlight.
    const facilityTaskVariables = get(location, "state.facilityVariables");

    let ArchetypeComponent;
    switch (facility.archetype) {
      case "Platform":
        ArchetypeComponent = Platform;
        break;
      case "Pipeline":
        ArchetypeComponent = Pipeline;
        break;
      case "Subsea":
        ArchetypeComponent = Subsea;
        break;
      default:
        ArchetypeComponent = null;
    }

    return (
      <EditablePage
        breadcrumbs={[{ title: `Details for Facility ${facility.Name}` }]}
        isEditable={true}
        isEditing={isEditing}
        saveError={saveError}
        onCancelEdit={this.handleCancelFacilityEdit}
        onEdit={this.handleFacilityEdit}
        onSave={this.handleFacilitySave}
      >
        <FacilityDetail
          facility={facility}
          isEditable={isEditing}
          onAddComplex={this.props.addComplex}
          onChangeField={this.props.updateFacilityDetails}
          unsavedFields={unsavedFacilityFields}
          complexes={complexes}
          complexesError={complexesError}
          facilityTaskVariables={facilityTaskVariables}
          facilityFunctions={facilityFunctions}
          facilityFunctionsError={facilityFunctionsError}
          locationTypes={locationTypes}
        />
        {ArchetypeComponent && (
          <ArchetypeComponent
            facility={facility}
            isEditable={isEditing}
            onChangeField={this.props.updateFacilityDetails}
            unsavedFields={unsavedFacilityFields}
            facilityTaskVariables={facilityTaskVariables}
          />
        )}
      </EditablePage>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addComplex: (countryID, complexName) => processAddComplexResponse(dispatch(addComplex(countryID, complexName))),
    loadComplexes: facilityID => dispatch(loadComplexes(facilityID)),
    loadFacilityDetails: facilityID => dispatch(loadFacilityDetails(facilityID)),
    saveFacilityDetails: (id, archetype, facilityData) => dispatch(saveFacilityDetails(id, archetype, facilityData)),
    updateFacilityDetails: (key, value) => dispatch(updateFacilityDetails(key, value)),
    setEditFacility: isEditing => dispatch(setEditFacility(isEditing)),
    loadFacilityFunctions: () => dispatch(loadFacilityFunctions()),
    loadLocationTypes: () => dispatch(loadLocationTypes()),
  };
}

function mapStateToProps({ complexes, facilities, facilityFunctions, locationTypes }) {
  return {
    complexes: complexes.list,
    complexesAreLoading: complexes.loading,
    complexesError: complexes.error,
    facility: facilities.facility,
    facilityError: facilities.facilityError,
    saveError: facilities.saveError,
    isEditing: facilities.isEditing,
    unsavedFacilityFields: facilities.unsavedFacilityFields,
    facilityFunctions: facilityFunctions.list,
    facilityFunctionsError: facilityFunctions.error,
    locationTypes: locationTypes.list,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Facility)
);
