import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, notification, Select } from "antd";
import { getCountries } from "redux/selectors/countries";
import { loadCountries } from "redux/ducks/countries";
import CountryType from "types/country";
import api from "utils/api";
import MainLayout from "layout/Main";
import { withHomeIcon } from "utils/breadcrumbs/home";

import "./style.less";

const { Option } = Select;

class FacilitySettings extends Component {
  static propTypes = {
    countries: PropTypes.arrayOf(CountryType).isRequired,
    loadCountries: PropTypes.func.isRequired,
  };

  state = {
    archetype: "Platform",
    countryID: "",
  };

  componentDidMount() {
    this.props.loadCountries();
  }

  handleArchetypeSelect = value => {
    this.setState({ archetype: value });
  };

  handleCountrySelect = value => {
    this.setState({ countryID: value });
  };

  handleDownload = () => {
    api
      .downloadFacilities(this.state.archetype, this.state.countryID)
      .then(filename => {
        notification.success({
          message: "Download complete",
          description: `The file “${filename}” has been downloaded.`,
        });
      })
      .catch(err => {
        Modal.error({
          title: "Download failed",
          content: String(err.message),
        });
      });
  };

  render() {
    const { countries } = this.props;
    const { countryID, archetype } = this.state;
    const breadcrumbs = [withHomeIcon(), { title: "Settings" }, { title: "Facility Settings" }];
    const archetypeChoices = ["Platform", "Pipeline", "Subsea"];

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="card-layout">
        <div className="content facility-settings-content">
          <h1>Download Facilities</h1>
          <div className="note">Facilities of the selected type will be exported as a CSV file.</div>
          <div>
            <div className="field">
              <span>Facility type:</span>
              <Select
                className="archetype-select"
                dropdownMatchSelectWidth={false}
                value={archetype}
                onSelect={this.handleArchetypeSelect}
              >
                {archetypeChoices.map(a => (
                  <Option key={a} value={a}>
                    {a}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="field">
              <span>In:</span>
              <Select
                className="country-select"
                dropdownMatchSelectWidth={false}
                value={countryID || ""}
                onSelect={this.handleCountrySelect}
              >
                <Option key="" value="">
                  All Countries
                </Option>
                {countries.map(c => (
                  <Option key={c.CountryID} value={c.CountryID}>
                    {c.Name}
                  </Option>
                ))}
              </Select>
            </div>
            <Button icon="download" onClick={this.handleDownload} type="primary">
              Download
            </Button>
          </div>
        </div>
      </MainLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadCountries: () => dispatch(loadCountries()),
  };
}

function mapStateToProps({ countries }) {
  return {
    countries: getCountries(countries),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacilitySettings);
