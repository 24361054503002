import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import { Alert, Spin, Button } from "antd";
import MainLayout from "layout/Main";
import StrategyOptions from "components/StrategyOptions";
import FacilityType from "types/facility";
import HistoryType from "types/history";
import { SummaryPhaseType } from "types/strategies";
import { loadFacilityDetails } from "redux/ducks/facilities";
import { removeStrategy, addNewStrategy, getStrategies, accordionUpdateKeys } from "redux/ducks/strategies";
import { withHomeIcon } from "utils/breadcrumbs/home";

import "./style.less";

class Strategies extends Component {
  static propTypes = {
    facilityID: PropTypes.string.isRequired,
    facility: FacilityType,
    addNewStrategy: PropTypes.func.isRequired,
    strategiesSummary: PropTypes.arrayOf(SummaryPhaseType).isRequired,
    removeStrategy: PropTypes.func.isRequired,
    loadFacilityDetails: PropTypes.func.isRequired,
    getStrategies: PropTypes.func.isRequired,
    accordionUpdateKeys: PropTypes.func.isRequired,
    accordionOpenKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
    facilityError: PropTypes.string,
    strategyError: PropTypes.string,
    history: HistoryType.isRequired,
  };

  static defaultProps = {
    facility: null,
    strategyError: null,
    facilityError: null,
  };

  componentDidMount() {
    if (!this.props.facility) {
      this.props.loadFacilityDetails(this.props.facilityID);
    }
    if (isEmpty(this.props.strategiesSummary)) {
      this.props.getStrategies(this.props.facilityID);
    }
  }

  handleRemoveStrategy = strategyID => () => this.props.removeStrategy(strategyID);

  handleStrategyEdit = (strategyID, phaseID) => () => {
    // Called when the user clicks Edit next to a strategy.
    // This is implemented as a function, not just a Link element,
    // because the design calls for the flexibility to open new windows or modals
    // in the future.
    this.props.history.push(`/facility/${this.props.facilityID}/strategies/${strategyID}/phase/${phaseID}/edit`);
  };

  handleStrategyAdd = (phase, facilityID) => () => {
    // Called when the user clicks Add next to a strategy.
    // This is implemented as a function, not just a Link element,
    // because the design calls for the flexibility to open new windows or modals
    // in the future.
    this.props.addNewStrategy(phase.PhaseID, facilityID);
  };

  handleAccordionOpen = keys => this.props.accordionUpdateKeys(keys);

  handleOpenAllPhases = () => {
    const { strategiesSummary } = this.props;
    const keys = strategiesSummary.map(strategy => strategy.PhaseID.toString());
    this.handleAccordionOpen(keys);
  };

  handleCloseAllPhases = () => this.handleAccordionOpen([]);

  render() {
    const { facility, facilityError, strategyError, strategiesSummary, accordionOpenKeys } = this.props;

    if (!facility) {
      return <Spin spinning={true} />;
    }

    const breadcrumbs = [withHomeIcon(), { title: `Strategies for Facility “${facility.Name}”` }];

    if (facilityError) {
      return <Alert message="Error" description={facilityError} type="error" showIcon />;
    }

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="strategies-page card-layout">
        {strategyError && <Alert type="error" message={strategyError} banner closable />}
        <div className="strategies">
          <div className="strategies-content-header">
            <div className="strategies-content-header__title">{`Strategies for Facility “${facility.Name}”`}</div>
            <div className="strategies-content-header__controls">
              {strategiesSummary.length !== accordionOpenKeys.length ? (
                <Button onClick={this.handleOpenAllPhases}>Open All Phases</Button>
              ) : (
                <Button onClick={this.handleCloseAllPhases}>Close All Phases</Button>
              )}
            </div>
          </div>
          <StrategyOptions
            strategiesSummary={strategiesSummary}
            facility={facility}
            onStrategyRemove={this.handleRemoveStrategy}
            onStrategyEdit={this.handleStrategyEdit}
            onStrategyAdd={this.handleStrategyAdd}
            openAccordionKeys={accordionOpenKeys}
            onAccordionOpen={this.handleAccordionOpen}
          />
        </div>
      </MainLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadFacilityDetails: facilityID => dispatch(loadFacilityDetails(facilityID)),
    removeStrategy: strategyID => dispatch(removeStrategy(strategyID)),
    addNewStrategy: (facilityID, phaseID) => dispatch(addNewStrategy(facilityID, phaseID)),
    getStrategies: facilityID => dispatch(getStrategies(facilityID)),
    accordionUpdateKeys: keys => dispatch(accordionUpdateKeys(keys)),
  };
}

function mapStateToProps({ facilities, strategies }) {
  return {
    facility: facilities.facility,
    facilityError: facilities.facilityError,
    strategyError: strategies.strategyError,
    strategiesSummary: strategies.summary,
    accordionOpenKeys: strategies.accordionOpenKeys,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Strategies)
);
