export const colors = [
  {
    backgroundColor: "#66c2d6",
  },
  {
    backgroundColor: "#00824a",
  },
  {
    backgroundColor: "#711471",
  },
  {
    backgroundColor: "#de8703",
  },
  {
    backgroundColor: "#003882",
  },
  {
    backgroundColor: "#bad405",
  },
  {
    backgroundColor: "#ba91ba",
  },
  {
    backgroundColor: "#70331f",
  },
];

export default {
  colors,
};
