export function getComplexes(complexes) {
  return (complexes && complexes.list ? complexes.list : null) || [];
}

export function getComplexesError(complexes) {
  return (complexes && complexes.error ? complexes.error.message || "Could not load data" : null) || "";
}

export function areComplexesLoading(complexes) {
  return !complexes || complexes.loading;
}

export default {
  getComplexes,
  getComplexesError,
  areComplexesLoading,
};
