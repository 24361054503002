import React, { Component } from "react";
import { SummaryPhaseType, SummaryStrategyType } from "types/strategies";
import FacilityType from "types/facility";
import PropTypes from "prop-types";
import { Button, Collapse, Table, Tooltip } from "antd";
import RowActions from "components/RowActions";
import infoIcon from "assets/information.svg";
import classNames from "classnames";

import "./style.less";

const { Panel } = Collapse;

const STRATEGY_COMPLETE_MINIMUM = 1;

const columns = [
  {
    title: "Activity",
    dataIndex: "activity",
    key: "activity",
  },
  {
    title: "Method",
    dataIndex: "method",
    key: "method",
  },
  {
    title: "Vessels",
    dataIndex: "vessels",
    key: "vessels",
    render: (_, { Vessels: { count, title, vessels } }) => (
      <div className="vessel-tooltip-container">
        <span>{count}</span>
        <Tooltip
          className="vessel-tooltip-container__tooltip"
          placement="right"
          overlayClassName="vessel-tooltip"
          title={
            <div>
              {title}
              <ul className="list">
                {vessels.map(v => (
                  <li key={v}>{v}</li>
                ))}
              </ul>
            </div>
          }
        >
          <img className="tooltip" src={infoIcon} alt="information" />
        </Tooltip>
      </div>
    ),
  },
];

function renderStrategyStatus(strategy) {
  const emptyStrategySelections = strategy.strategySelections.filter(ss => !ss.MethodID);

  if (emptyStrategySelections.length === 0) {
    return <div className="complete">Complete</div>;
  }

  return (
    <Tooltip
      placement="left"
      overlayClassName="approve-status-tooltip"
      data-testid="approve-status-tooltip"
      title={
        <div>
          The following activities have no method selection:
          <ul className="list">
            {emptyStrategySelections.map(ss => (
              <li key={ss.activity.Name}>{ss.activity.Name}</li>
            ))}
          </ul>
        </div>
      }
    >
      <div className="incomplete">Incomplete</div>
    </Tooltip>
  );
}

const StrategyHeader = ({ strategy, onStrategyEdit, onStrategyRemove }) => (
  <div className="strategy-options__panel-header" title={strategy.Name}>
    {strategy.Name}
    <div className="strategy-options__strategy-actions">
      <div className="approve-status">{renderStrategyStatus(strategy)}</div>
      <RowActions onDelete={onStrategyRemove} onEdit={onStrategyEdit} />
    </div>
  </div>
);

StrategyHeader.propTypes = {
  strategy: SummaryStrategyType.isRequired,
  onStrategyEdit: PropTypes.func.isRequired,
  onStrategyRemove: PropTypes.func.isRequired,
};

const PhaseHeader = ({ phase }) => {
  const numComplete = phase.strategies.filter(strategy => strategy.Complete).length;
  const numIncomplete = phase.strategies.filter(strategy => !strategy.Complete).length;
  const needMoreComplete = numComplete < STRATEGY_COMPLETE_MINIMUM;
  const numCompleteWord = numComplete === 0 ? "No" : `${numComplete}`;

  return (
    <div className="strategy-options__panel-header" title={phase.Name}>
      {phase.Name}
      <div className="strategy-options__status">
        <div
          className={classNames("complete-status", {
            "no-good": needMoreComplete,
          })}
        >
          {`${numCompleteWord} complete ${numComplete === 1 ? "strategy" : "strategies"}${
            needMoreComplete ? ` (at least ${STRATEGY_COMPLETE_MINIMUM} required)` : ""
          }${numComplete && numIncomplete ? ", " : ""}`}
        </div>
        {numIncomplete ? (
          <div className="incomplete-status no-good">
            {`${numIncomplete} incomplete ${numIncomplete === 1 ? "strategy" : "strategies"}`}
          </div>
        ) : null}
      </div>
    </div>
  );
};

PhaseHeader.propTypes = {
  phase: SummaryPhaseType.isRequired,
};

class StrategyOptions extends Component {
  static propTypes = {
    strategiesSummary: PropTypes.arrayOf(SummaryPhaseType).isRequired,
    facility: FacilityType.isRequired,
    onStrategyRemove: PropTypes.func.isRequired,
    onStrategyEdit: PropTypes.func.isRequired,
    onStrategyAdd: PropTypes.func.isRequired,
    onAccordionOpen: PropTypes.func.isRequired,
    openAccordionKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  getVessels = ({ method, vessels }) => {
    if (!method) {
      return {
        count: "-",
        title: "No method has been selected. Please select a method to see potential vessel choices.",
        vessels,
      };
    }
    if (!method.VesselRequired) {
      return {
        count: 0,
        title: "No vessels are required to complete this activity with the selected method.",
        vessels,
      };
    }
    return {
      count: vessels.length,
      title: "Applicable vessels:",
      vessels,
    };
  };

  render() {
    const { strategiesSummary, facility, openAccordionKeys } = this.props;

    return (
      <div className="strategy-options">
        <Collapse
          className="strategy-options-phase"
          onChange={this.props.onAccordionOpen}
          activeKey={openAccordionKeys}
          defaultActiveKey={openAccordionKeys}
        >
          {strategiesSummary.map(strategyPhase => (
            <Panel header={<PhaseHeader phase={strategyPhase} />} key={strategyPhase.PhaseID}>
              <header>
                <Button
                  onClick={this.props.onStrategyAdd(
                    {
                      PhaseID: strategyPhase.PhaseID,
                      Name: strategyPhase.Name,
                    },
                    facility.FacilityID
                  )}
                  className="strategy-options__add"
                  type="primary"
                >
                  Add Strategy
                </Button>
              </header>
              {strategyPhase.strategies.length > 0 ? (
                <Collapse className="selections">
                  {strategyPhase.strategies.map(strategy => (
                    <Panel
                      header={
                        <StrategyHeader
                          strategy={strategy}
                          onStrategyEdit={this.props.onStrategyEdit(strategy.StrategyID, strategyPhase.PhaseID)}
                          onStrategyRemove={this.props.onStrategyRemove(strategy.StrategyID)}
                        />
                      }
                      key={strategy.StrategyID}
                    >
                      <Table
                        columns={columns}
                        dataSource={strategy.strategySelections.map((selection, i) => ({
                          activity: selection.activity.Name,
                          method: selection.method ? selection.method.Name : "Not Selected",
                          Vessels: this.getVessels(selection),
                          key: i,
                        }))}
                        pagination={false}
                      />
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div> No strategies exist. Please create one. </div>
              )}
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export default StrategyOptions;
