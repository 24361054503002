import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, Icon, Button, Popconfirm, Input, Alert } from "antd";
import LabeledInputWithUnits from "components/LabeledInputWithUnits";
import { formatCostDisplay } from "utils/format";
import { map, isEmpty, last, upperFirst } from "lodash";

import "./style.less";

const { TextArea } = Input;

class FieldDetailModal extends Component {
  static propTypes = {
    field: PropTypes.shape({
      error: PropTypes.string,
      formula: PropTypes.string,
      value: PropTypes.string,
      variables: PropTypes.object,
    }),
    fieldTitle: PropTypes.string,
    taskName: PropTypes.string,
    fieldKey: PropTypes.string,
    showModal: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAfterCloseModal: PropTypes.func.isRequired,
    FacilityID: PropTypes.number.isRequired,
    onPreviewFormula: PropTypes.func.isRequired,
    onSaveFormula: PropTypes.func.isRequired,
    onGetActivity: PropTypes.func.isRequired,
    taskFormulaError: PropTypes.string,
  };

  static defaultProps = {
    field: {},
    taskFormulaError: "",
    fieldTitle: "",
    taskName: "",
    fieldKey: "",
  };

  state = {
    editResult: false,
    editFormula: false,
    rateVariables: [],
    facilityVariables: [],
    editedFormula: "",
    value: "",
    visiblePopConfirm: false,
  };

  static getDerivedStateFromProps = (props, state) => {
    const { variables } = props.field;

    let derivedState = {};

    if (variables) {
      const facilityVariables = [];
      const rateVariables = [];
      Object.keys(variables).forEach(k => {
        const keyPieces = k.split(".");
        const variable = last(keyPieces);

        if (k.startsWith("facility.country.")) {
          rateVariables.push(variable);
        } else if (k.startsWith("facility")) {
          facilityVariables.push(upperFirst(variable));
        }
      });

      derivedState = {
        ...state,
        rateVariables,
        facilityVariables,
      };
    }

    return derivedState;
  };

  toggleEditResult = () => {
    const { editResult, originalValue } = this.state;
    this.setState({ editResult: !editResult, value: originalValue });
  };

  toggleEditFormula = () => {
    const { editFormula } = this.state;
    if (editFormula) {
      this.props.onGetActivity();
      this.setState({ editedFormula: "" });
    }
    this.setState({ editFormula: !editFormula });
  };

  handleUpdateResult = value => {
    this.setState({ value });
  };

  handleOnOk = () => {
    const { taskName, fieldKey } = this.props;
    const { value, editedFormula } = this.state;

    if (value) {
      this.props.onConfirm(taskName, fieldKey, value);
    }
    if (editedFormula) {
      this.props.onSaveFormula({ Name: taskName, [fieldKey]: editedFormula });
    }
  };

  handleCancel = () => {
    this.props.onCancel();
    this.props.onGetActivity();
  };

  handleFormulaChange = ({ target: { value } }) => {
    this.setState({ editedFormula: value });
  };

  handlePreviewFormula = () => {
    const { fieldKey, taskName } = this.props;
    const { editedFormula } = this.state;

    this.props.onPreviewFormula({ Name: taskName, [fieldKey]: editedFormula });
  };

  handleVisiblePopConfirmChange = visible => {
    const { taskFormulaError } = this.props;
    if (taskFormulaError) {
      this.setState({ visiblePopConfirm: false });
    } else {
      this.setState({ visiblePopConfirm: visible });
    }
  };

  renderFooter = name => {
    /* eslint-disable react/jsx-indent */
    const { taskFormulaError } = this.props;
    const { visiblePopConfirm, value, editedFormula } = this.state;
    let title = "Are you sure you want to override";

    if (value) {
      title += ` the ${name} Result`;
    }

    if (editedFormula) {
      title += value ? `  and ${name} Formula` : ` the ${name} Formula`;
    }

    return [
      <Button key="back" onClick={this.handleCancel}>
        Cancel
      </Button>,
      <Popconfirm
        key="submit"
        placement="top"
        visible={visiblePopConfirm}
        onVisibleChange={this.handleVisiblePopConfirmChange}
        title={`${title}?`}
        onConfirm={this.handleOnOk}
        okText="Yes"
        cancelText="No"
      >
        <Button type="primary" disabled={!!taskFormulaError}>
          Confirm
        </Button>
      </Popconfirm>,
    ];
    /* eslint-enable react/jsx-indent */
  };

  formatValue = (name, value) => {
    switch (name) {
      case "Cost":
        return formatCostDisplay(value);
      default:
        return value;
    }
  };

  render() {
    const {
      onCancel,
      showModal,
      onAfterCloseModal,
      field: { value, error, formula, variables },
      taskName,
      fieldTitle,
      FacilityID,
      taskFormulaError,
    } = this.props;

    const { editResult, facilityVariables, rateVariables, editFormula, editedFormula } = this.state;

    const variablesPresent = !isEmpty(variables);

    return (
      <Modal
        visible={showModal}
        className="field-detail-modal"
        width={650}
        onOk={this.handleOnOk}
        onCancel={onCancel}
        title={`${taskName}: ${fieldTitle}`}
        maskClosable={false}
        destroyOnClose={true}
        afterClose={onAfterCloseModal}
        footer={this.renderFooter(fieldTitle)}
      >
        <div data-testid="field-detail-modal" className="field-detail-modal-content">
          <div className="result">
            <LabeledInputWithUnits
              isEditable={editResult}
              label={fieldTitle || ""}
              value={value}
              min={0}
              onChange={this.handleUpdateResult}
            />
            <div className="result-edit" role="button" tabIndex="0" onClick={this.toggleEditResult}>
              <Icon type="edit" theme="filled" />
              {editResult ? "Revert to Formula" : `Override ${fieldTitle}`}
            </div>
          </div>
          {error && <Alert message={<div className="preformatted-message">{error}</div>} type="error" showIcon />}
          <div className="item">
            <div className="title">Formula</div>
            {editFormula ? (
              <>
                <TextArea defaultValue={formula} onChange={this.handleFormulaChange} />
                <div className="formula-edit-controls">
                  <div className="cancel" role="button" tabIndex="0" onClick={this.toggleEditFormula}>
                    Cancel
                  </div>
                  {editedFormula && (
                    <div className="preview" role="button" tabIndex="0" onClick={this.handlePreviewFormula}>
                      Preview
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="value">{formula}</div>
                <div className="formula-edit" role="button" tabIndex="0" onClick={this.toggleEditFormula}>
                  Edit Formula
                </div>
              </>
            )}
          </div>
          {taskFormulaError && (
            <Alert type="error" message={<div className="preformatted-message">{taskFormulaError}</div>} />
          )}
          <div className="item">
            <div className="title">Variables</div>
            {variablesPresent ? map(variables, (v, k) => <div key={k}>{`${k}: ${v}`}</div>) : "-"}
            <div className="variable-edit-links">
              {facilityVariables.length > 0 && (
                <Link
                  to={{
                    pathname: `/facility/${FacilityID}/general`,
                    state: { facilityVariables },
                  }}
                >
                  Edit Facility Variables
                </Link>
              )}
              {rateVariables.length > 0 && (
                <Link
                  to={{
                    pathname: "/countries/rates",
                    state: { rateVariables },
                  }}
                >
                  Edit Country Rates
                </Link>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default FieldDetailModal;
