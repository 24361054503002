import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ActivityMethodDetails from "components/ActivityMethodDetails";
import {
  getActivity,
  updateActivityFormula,
  saveCustomTask,
  saveGlobalTask,
  deleteCustomTask,
  clearTaskErrors,
  previewTaskFormula,
  saveTask,
} from "redux/ducks/strategies";

const mapDispatchToProps = dispatch => {
  return {
    getActivity: (strategyID, activityID) => dispatch(getActivity(strategyID, activityID)),
    updateActivityFormula: (strategyID, activityID, payload) =>
      dispatch(updateActivityFormula(strategyID, activityID, payload)),
    saveCustomTask: (strategyID, activityID, payload) => dispatch(saveCustomTask(strategyID, activityID, payload)),
    saveGlobalTask: (strategyID, activityID, payload) => dispatch(saveGlobalTask(strategyID, activityID, payload)),
    deleteCustomTask: (strategyID, activityID, payload) => dispatch(deleteCustomTask(strategyID, activityID, payload)),
    previewTaskFormula: (strategyID, activityID, payload) =>
      dispatch(previewTaskFormula(strategyID, activityID, payload)),
    saveTask: (strategyID, activityID, payload) => dispatch(saveTask(strategyID, activityID, payload)),
    clearTaskErrors: () => dispatch(clearTaskErrors()),
  };
};

const mapStateToProps = ({ strategies: { activity, taskError, taskFormulaError } }) => {
  return { activity, taskError, taskFormulaError };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ActivityMethodDetails)
);
