import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Icon, Input, Button, Alert } from "antd";
import { isEmpty } from "lodash";
import AntFormType from "types/ant-form";
import PropTypes from "prop-types";
import "./style.less";

const FormItem = Form.Item;

class LoginForm extends Component {
  static propTypes = {
    form: AntFormType.isRequired,
    onLogin: PropTypes.func.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: "",
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onLogin(values.userName, values.password);
      }
    });
  };

  componentDidMount() {
    // Autofocus the username field when the form appears.
    // This is done imperatively because Ant Design no longer supports
    // the autoFocus prop on Inputs.
    // Maintaining a ref to the userName field won't work here because
    // rc-form is currently incompatible with React 16.3's new ref style.
    // So use rc-form's getFieldInstance method instead.
    this.props.form.getFieldInstance("userName").focus();
  }

  render() {
    const {
      form: { getFieldDecorator },
      error,
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        {!isEmpty(error) && <Alert message={error} type="error" showIcon />}
        <FormItem>
          {getFieldDecorator("userName", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Username" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please input your Password!" }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </FormItem>
        <FormItem className="login-form__forgot-password-wrapper">
          <Link to="/forgot-password" className="login-form__forgot-password-button">
            Forgot Password
          </Link>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(LoginForm);
