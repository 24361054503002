import { STRATEGY_ADD } from "redux/ducks/strategies";
import { push } from "connected-react-router";
import { packSuccess } from "utils/redux-pack";

const strategyAddMiddleware = ({ dispatch }) => next => action => {
  if (packSuccess(action, STRATEGY_ADD)) {
    const { FacilityID, StrategyID, PhaseID } = action.payload.data;
    dispatch(push(`/facility/${FacilityID}/strategies/${StrategyID}/phase/${PhaseID}/edit`));
  }

  next(action);
};

export default strategyAddMiddleware;
