import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import { get } from "lodash";
import api from "utils/api";

export const LOAD_COUNTRIES = "countries/LOAD_COUNTRIES";
export const LOAD_COUNTRY = "countries/LOAD_COUNTRY";
export const LOAD_COUNTRY_RATES = "countries/LOAD_COUNTRY_RATES";
export const EDIT_RATES = "countries/EDIT_RATES";
export const UPDATE_RATE = "countries/UPDATE_RATE";
export const SAVE_RATES = "countries/SAVE_RATES";

export function loadCountries() {
  return {
    type: LOAD_COUNTRIES,
    promise: api.countries.getCountries(),
  };
}

export function loadCountry(countryID) {
  return {
    type: LOAD_COUNTRY,
    promise: api.countries.getCountry(countryID),
  };
}

export function loadCountryRates(countryID) {
  return {
    type: LOAD_COUNTRY_RATES,
    promise: api.countries.getCountryRates(countryID),
  };
}

export function updateCountryRate(key, value) {
  return {
    type: UPDATE_RATE,
    payload: {
      [key]: value,
    },
  };
}

export function setEditRates(isEditing) {
  return {
    type: EDIT_RATES,
    payload: {
      isEditing,
    },
  };
}

export function saveCountryRates(id, rates) {
  return {
    type: SAVE_RATES,
    promise: api.countries.saveCountryRates(id, rates),
  };
}

const initialState = {
  isEditing: false,
  error: null,
  list: null,
  saveError: "",
  country: {},
  rates: {},
  unsavedRateFields: {},
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case LOAD_COUNTRIES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          error: null,
        }),
        failure: prevState => ({ ...prevState, error: message || "Could not load countries" }),
        success: prevState => ({ ...prevState, list: payload.data }),
      });
    case LOAD_COUNTRY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          error: null,
        }),
        failure: prevState => ({ ...prevState, error: message || "Could not load country" }),
        success: prevState => ({ ...prevState, country: payload.data }),
      });
    case LOAD_COUNTRY_RATES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          error: null,
        }),
        failure: prevState => ({ ...prevState, error: message || "Could not load country rates" }),
        success: prevState => ({ ...prevState, rates: payload.data }),
      });
    case EDIT_RATES:
      return {
        ...state,
        unsavedRateFields: initialState.unsavedRateFields,
        isEditing: payload.isEditing,
        error: !payload.isEditing ? initialState.error : state.error,
      };
    case UPDATE_RATE:
      return {
        ...state,
        unsavedRateFields: {
          ...state.unsavedRateFields,
          ...payload,
        },
      };
    case SAVE_RATES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          saveError: "",
        }),
        failure: prevState => ({
          ...prevState,
          saveError: message || "Could not save rate data",
        }),
        success: prevState => ({
          ...prevState,
          rates: payload.data,
          isEditing: false,
          unsavedRateFields: initialState.unsavedRateFields,
        }),
      });
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
