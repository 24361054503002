import React from "react";
import { Icon, Tooltip } from "antd";
import PropTypes from "prop-types";

import "./style.less";

const ApplicabilityItem = ({
  applicable,
  text,
  tooltipText,
  tooltipPlacement,
  onApplicableClick,
  onInapplicableClick,
}) => (
  <div className="applicability">
    <Tooltip overlayClassName="applicability-tooltip" placement={tooltipPlacement} title={tooltipText}>
      {applicable ? (
        <Icon type="check-circle" onClick={onApplicableClick} />
      ) : (
        <Icon type="close-circle" onClick={onInapplicableClick} />
      )}
    </Tooltip>
    <div className="applicability-text">{text}</div>
  </div>
);

ApplicabilityItem.propTypes = {
  applicable: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.node,
  tooltipPlacement: PropTypes.string,
  onApplicableClick: PropTypes.func,
  onInapplicableClick: PropTypes.func,
};

ApplicabilityItem.defaultProps = {
  tooltipText: null,
  tooltipPlacement: undefined,
  onApplicableClick: () => {},
  onInapplicableClick: () => {},
};

export default ApplicabilityItem;
