import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { get } from "lodash";
import api from "utils/api";

export const GET_GLOBALS = "admin/GET_GLOBALS";
export const GET_USER_GLOBALS = "admin/GET_USER_GLOBALS";
export const EDIT_GLOBALS = "admin/EDIT_GLOBALS";
export const UPDATE_GLOBAL = "admin/UPDATE_GLOBAL";
export const SAVE_GLOBALS = "admin/SAVE_GLOBALS";
export const SAVE_USER_GLOBALS = "admin/SAVE_USER_GLOBALS";
export const RESET_REDUX_STATE = "admin/RESET_REDUX_STATE";
export const RESET_ENV_DATA = "admin/RESET_ENV_DATA";

export function getGlobals() {
  return {
    type: GET_GLOBALS,
    promise: api.admin.getGlobals(),
  };
}

export function getUserGlobals() {
  return {
    type: GET_USER_GLOBALS,
    promise: api.admin.getUserGlobals(),
  };
}

export function setEditGlobals(isEditing) {
  return {
    type: EDIT_GLOBALS,
    payload: {
      isEditing,
    },
  };
}

export function updateGlobal(key, value) {
  return {
    type: UPDATE_GLOBAL,
    payload: {
      [key]: value,
    },
  };
}

export function saveGlobals(globals) {
  return {
    type: SAVE_GLOBALS,
    promise: api.admin.saveGlobals(globals),
  };
}

export function saveUserGlobals(userGlobals) {
  return {
    type: SAVE_USER_GLOBALS,
    promise: api.admin.saveUserGlobals(userGlobals),
  };
}

export function resetEnvData() {
  return {
    type: RESET_ENV_DATA,
    promise: api.admin.resetEnvData(),
  };
}

export function resetReduxState() {
  return {
    type: RESET_REDUX_STATE,
    payload: {},
  };
}

const initialState = {
  loading: false,
  error: null,
  saveError: "",
  globals: {},
  userGlobals: {},
  unsavedGlobalsFields: {},
  isEditing: false,
  resettingData: false,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case GET_GLOBALS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not get admin globals" }),
        success: prevState => ({ ...prevState, globals: payload.data }),
      });
    case GET_USER_GLOBALS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not get user globals" }),
        success: prevState => ({ ...prevState, userGlobals: payload.data }),
      });
    case EDIT_GLOBALS:
      return {
        ...state,
        unsavedGlobalsFields: initialState.unsavedGlobalsFields,
        isEditing: payload.isEditing,
        error: !payload.isEditing ? initialState.error : state.error,
      };
    case UPDATE_GLOBAL:
      return {
        ...state,
        unsavedGlobalsFields: {
          ...state.unsavedGlobalsFields,
          ...payload,
        },
      };
    case SAVE_GLOBALS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          saveError: "",
        }),
        failure: prevState => ({
          ...prevState,
          saveError: message || "Could not save Global data",
        }),
        success: prevState => ({
          ...prevState,
          globals: payload.data,
          isEditing: false,
          unsavedGlobalsFields: initialState.unsavedGlobalsFields,
        }),
      });
    case SAVE_USER_GLOBALS:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          saveError: "",
        }),
        failure: prevState => ({
          ...prevState,
          saveError: message || "Could not save User Global override data",
        }),
        success: prevState => ({
          ...prevState,
          userGlobals: payload.data,
        }),
      });
    case RESET_ENV_DATA:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          error: "",
          resettingData: true,
        }),
        failure: prevState => ({
          ...prevState,
          resettingData: false,
          error: message || "Could not reset environment data",
        }),
        success: () => initialState,
      });
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
