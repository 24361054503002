import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { Alert, Button, Select, Tooltip, Input, InputNumber, Row, Col, Icon, Spin } from "antd";
import info from "assets/information.svg";
import LabeledNumericInput from "components/LabeledNumericInput";
import FacilityDetailType from "types/facility";
import { TopsideInstallationMethodPropType } from "types/topsides";
import { loadTopsideInstallationMethods } from "redux/ducks/topside-installation-methods";

import "./style.less";

const { Option } = Select;

class Topside extends Component {
  static propTypes = {
    installMethods: PropTypes.arrayOf(TopsideInstallationMethodPropType),
    installMethodsAreLoading: PropTypes.bool,
    installMethodsError: PropTypes.string,
    facility: FacilityDetailType.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onChangeField: PropTypes.func.isRequired,
    unsavedFields: PropTypes.object.isRequired,
    facilityTaskVariables: PropTypes.arrayOf(PropTypes.string),
    loadTopsideInstallationMethods: PropTypes.func.isRequired,
  };

  static defaultProps = {
    facilityTaskVariables: [],
    installMethods: null,
    installMethodsAreLoading: false,
    installMethodsError: "",
  };

  handleChangeNumericField = key => event => {
    this.props.onChangeField(key, +event.target.value);
  };

  handleChangeInstallationMethod = value => {
    this.props.onChangeField("TopsidesOriginalInstallationMethodID", value);
  };

  handleAddTopsidesModule = topsidesModules => () => {
    this.props.onChangeField("topsidesModules", [...topsidesModules, ...[{ Name: "", Weight: 0, ExtraModule: false }]]);
  };

  handleDeleteTopsidesModule = (topsidesModules, index) => () => {
    const newModules = topsidesModules.filter((tm, i) => i !== index);
    this.props.onChangeField("topsidesModules", newModules);
  };

  handleExtraModuleChange = (topsidesModules, index) => value => {
    const newModules = [...topsidesModules];
    newModules[index] = {
      ...topsidesModules[index],
      ExtraModule: value === "true",
    };
    this.props.onChangeField("topsidesModules", newModules);
  };

  handleNameChange = (topsidesModules, index) => ({ target }) => {
    const newModules = [...topsidesModules];
    newModules[index] = {
      ...topsidesModules[index],
      Name: target.value,
    };
    this.props.onChangeField("topsidesModules", newModules);
  };

  handleWeightChange = (topsidesModules, index) => value => {
    const newModules = [...topsidesModules];
    newModules[index] = {
      ...topsidesModules[index],
      Weight: value,
    };
    this.props.onChangeField("topsidesModules", newModules);
  };

  getOriginalInstallationWeight = topsides => topsides.topsidesModules.reduce((acc, n) => acc + n.Weight, 0);

  highlightTaskVariableField = key => (this.props.facilityTaskVariables.includes(key) ? "highlight-field" : "");

  componentDidMount() {
    if (!this.props.installMethods && !this.props.installMethodsAreLoading) {
      this.props.loadTopsideInstallationMethods();
    }
  }

  render() {
    const { facility, isEditable, unsavedFields, installMethods, installMethodsError } = this.props;
    const { topsides } = facility.platform;
    const fieldValues = {
      TopsidesOriginalInstallationMethodID: topsides.TopsidesOriginalInstallationMethodID,
      TopsidesWeight: topsides.Weight,
      topsidesModules: topsides.topsidesModules,
      PieceSmallWeight: topsides.PieceSmallWeight,
      ...unsavedFields,
    };

    if (installMethodsError) {
      return <Alert message="Error" description={installMethodsError} type="error" showIcon />;
    }

    if (!installMethods) {
      return <Spin spinning={true} />;
    }

    return (
      <div className="topside-layout">
        <h2>Topside</h2>
        <Row type="flex">
          <Col span={8}>
            <LabeledNumericInput
              isEditable={false /* this value is calculated, therefore non-editable */}
              label="Total Weight:"
              onChange={() => {}}
              value={topsides.totalWeight}
              singularUnit="Tonne"
              pluralUnits="Tonnes"
              min={0}
              className={classNames(this.highlightTaskVariableField("TotalWeight"), { dimmer: isEditable })}
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Topsides Weight:"
              onChange={this.handleChangeNumericField("TopsidesWeight")}
              className={this.highlightTaskVariableField("TopsidesWeight")}
              value={fieldValues.TopsidesWeight}
              singularUnit="Tonne"
              pluralUnits="Tonnes"
              min={0}
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Piece Small Items Weight:"
              onChange={this.handleChangeNumericField("PieceSmallWeight")}
              className={this.highlightTaskVariableField("PieceSmallWeight")}
              value={fieldValues.PieceSmallWeight}
              singularUnit="Tonne"
              pluralUnits="Tonnes"
              min={0}
            />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={12}>
            <div className={`labeled-field ${this.highlightTaskVariableField("OriginalInstallationMethod")}`}>
              <div className="label">
                Original Installation Method:
                <Tooltip placement="right" title="Original install method would affect applicability">
                  <img className="tooltip" src={info} alt="information" />
                </Tooltip>
              </div>
              <div className="value">
                {isEditable ? (
                  <Select
                    value={fieldValues.TopsidesOriginalInstallationMethodID}
                    onChange={this.handleChangeInstallationMethod}
                  >
                    {installMethods.map(method => (
                      <Option
                        key={method.TopsidesOriginalInstallationMethodID}
                        value={method.TopsidesOriginalInstallationMethodID}
                      >
                        {method.Name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  (
                    installMethods.find(
                      i => i.TopsidesOriginalInstallationMethodID === fieldValues.TopsidesOriginalInstallationMethodID
                    ) || { Name: "—" }
                  ).Name
                )}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="topside-layout-modules">
              <div className="topside-layout-modules__label">Modules:</div>
              {fieldValues.topsidesModules.map((module, i) => (
                // using the key for an index here is fine considering we will not
                // be reordering this array at all which is where the eslint
                // defensiveness derives from.
                // eslint-disable-next-line
                <div key={i} className="topside-module">
                  {isEditable ? (
                    <>
                      <Input value={module.Name} onChange={this.handleNameChange(fieldValues.topsidesModules, i)} />
                      {" : "}
                      <InputNumber
                        value={module.Weight}
                        min={0}
                        onChange={this.handleWeightChange(fieldValues.topsidesModules, i)}
                      />
                      Tonnes
                      <Select
                        className="topside-module__select"
                        defaultValue={`${module.ExtraModule || false}`}
                        onChange={this.handleExtraModuleChange(fieldValues.topsidesModules, i)}
                      >
                        <Option value="true">Extra Module</Option>
                        <Option value="false">Primary Module</Option>
                      </Select>
                      <Button
                        className="topside-module__delete"
                        onClick={this.handleDeleteTopsidesModule(fieldValues.topsidesModules, i)}
                      >
                        <Icon type="minus-circle" theme="outlined" />
                      </Button>
                    </>
                  ) : (
                    <div
                      className={this.highlightTaskVariableField(
                        module.ExtraModule ? "NumberOfExtraModules" : "NumberOfPrimaryModules"
                      )}
                    >
                      {`Name: ${module.Name}, Weight: ${module.Weight} Tonnes, ${
                        module.ExtraModule ? "Extra Module" : "Primary Module"
                      }`}
                    </div>
                  )}
                </div>
              ))}
              {!fieldValues.topsidesModules.length && <div>No modules</div>}
              {isEditable && (
                <Button
                  className="topside-module__add"
                  onClick={this.handleAddTopsidesModule(fieldValues.topsidesModules)}
                >
                  <Icon type="plus-circle" theme="outlined" />
                  Add module
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadTopsideInstallationMethods: () => dispatch(loadTopsideInstallationMethods()),
  };
}

function mapStateToProps({ topsideInstallationMethods: installMethods }) {
  return {
    installMethods: installMethods.list,
    installMethodsAreLoading: installMethods.isLoading,
    installMethodsError: installMethods.error,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topside);
