import { handle } from "redux-pack";
import { get } from "lodash";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import api from "utils/api";

export const ADD_COMPLEX = "countries/ADD_COMPLEX";
export const LOAD_COMPLEXES = "countries/LOAD_COMPLEXES";

export function addComplex(CountryID, Name) {
  return {
    type: ADD_COMPLEX,
    promise: api.complexes.addComplex(CountryID, Name),
  };
}

export function loadComplexes() {
  return {
    type: LOAD_COMPLEXES,
    promise: api.complexes.getComplexes(),
  };
}

// processAddComplexResponse translates the result of calling
// dispatch(addComplex(...)) into either a promise rejection or a promise
// result consisting of the new ComplexID. (The promise returned from dispatch
// always resolves successfully, even if the result indicates failure, and
// returns an object in which the useful result is deeply nested.)
export const processAddComplexResponse = promise =>
  promise.then(reply => {
    if (reply.error) {
      throw new Error(get(reply, "payload.response.data.message") || "Error saving complex");
    }
    // return the ComplexID from the response payload
    return Number(reply.payload.data.ComplexID);
  });

const initialState = { loading: false, error: null, list: null };

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_COMPLEX:
      // note that middleware/addComplex.js will trigger LOAD_COMPLEXES
      // on successful completion of adding a complex. Nothing needs to be
      // done here. The addComplex caller will deal with the ComplexID
      // resulting from the promise returned by dispatch(addComplex()).
      return state;
    case LOAD_COMPLEXES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: payload }),
        success: prevState => ({ ...prevState, list: payload.data }),
      });
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
