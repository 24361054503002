import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox } from "antd";
import { map } from "lodash";

import "./style.less";

const CheckboxGroup = Checkbox.Group;

class PortfolioFilter extends Component {
  static propTypes = {
    filters: PropTypes.shape({
      archetypes: PropTypes.arrayOf(PropTypes.string.isRequired),
    }).isRequired,
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    onFilterChange: PropTypes.func.isRequired,
  };

  state = {
    filters: null,
  };

  handleChangeTypes = values => {
    this.setState(({ filters }) => ({
      filters: {
        ...filters,
        archetypes: values,
      },
    }));
  };

  handleClear = () => {
    this.setState({ filters: null });

    this.props.onFilterChange({
      archetypes: null,
    });
  };

  handleApply = () => {
    if (this.state.filters) {
      this.props.onFilterChange(this.state.filters);
      this.setState({ filters: null });
    }
  };

  render() {
    // state.filters overrides original props.filters if non-null
    // (meaning that there are unapplied changes)
    const filters = this.state.filters || this.props.filters;

    const hasAppliedFilters = !!Object.values(this.props.filters).find(item => item !== null);

    const options = map(this.props.options, type => ({
      label: type,
      value: type,
    }));

    return (
      <>
        <div className="filter-controls">
          <Button className="clear-button" disabled={!hasAppliedFilters} onClick={this.handleClear} type="danger">
            Clear Filters
          </Button>
          <Button
            className="apply-button"
            disabled={this.state.filters === null}
            onClick={this.handleApply}
            type="primary"
          >
            Apply
          </Button>
        </div>
        <CheckboxGroup options={options} value={filters.archetypes} onChange={this.handleChangeTypes} />
      </>
    );
  }
}

export default PortfolioFilter;
