import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import LoginForm from "components/LoginForm";
import ChangePassword from "components/ChangePassword";
import ForgotPassword from "components/ForgotPassword";
import logo from "assets/dingo-logo.svg";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PreLogin from "layout/PreLogin";
import { login, changePassword, requestForgotPassword, confirmForgotPassword } from "redux/ducks/auth";
import "./style.less";

class Login extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    error: PropTypes.string,
    success: PropTypes.string,
    changePassword: PropTypes.bool.isRequired,
    cognitoUserChangePassword: PropTypes.func.isRequired,
    cognitoUser: PropTypes.object,
    requestForgotPassword: PropTypes.func.isRequired,
    confirmForgotPassword: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: "",
    success: null,
    cognitoUser: null,
  };

  handleChangePassword = password => {
    this.props.cognitoUserChangePassword(this.props.cognitoUser, password);
  };

  handleRequestForgotPassword = username => {
    this.props.requestForgotPassword(username);
  };

  handleConfirmForgotPassword = (username, code, password) => {
    this.props.confirmForgotPassword(username, code, password);
  };

  render() {
    return (
      <PreLogin>
        <Switch>
          {/* eslint-disable no-confusing-arrow */}
          <Route
            exact
            path="/"
            render={() =>
              this.props.changePassword ? (
                <ChangePassword onChangePassword={this.handleChangePassword} error={this.props.error} />
              ) : (
                <div className="login-container">
                  <div className="logo-container">
                    <img className="logo" alt="Shell Logo" src={logo} />
                  </div>
                  <LoginForm onLogin={this.props.login} error={this.props.error} />
                </div>
              )
            }
          />
          <Route
            exact
            path="/forgot-password"
            render={() => (
              <ForgotPassword
                onRequestForgotPassword={this.handleRequestForgotPassword}
                onConfirmForgotPassword={this.handleConfirmForgotPassword}
                error={this.props.error}
                success={this.props.success}
              />
            )}
          />
        </Switch>
      </PreLogin>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    login: (username, password) => dispatch(login(username, password)),
    cognitoUserChangePassword: (cognitoUser, password) => dispatch(changePassword(cognitoUser, password)),
    requestForgotPassword: username => dispatch(requestForgotPassword(username)),
    confirmForgotPassword: (username, code, password) => dispatch(confirmForgotPassword(username, code, password)),
  };
}

function mapStateToProps({ auth }) {
  return {
    error: auth.error,
    success: auth.success,
    changePassword: auth.changePassword,
    cognitoUser: auth.cognitoUser,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
