import { CREATE_FACILITY } from "redux/ducks/facilities";
import { push } from "connected-react-router";
import { packSuccess } from "utils/redux-pack";

const createFacilityMiddleware = ({ dispatch }) => next => action => {
  if (packSuccess(action, CREATE_FACILITY)) {
    const { FacilityID } = action.payload.data;
    dispatch(push(`/facility/${FacilityID}/general`));
  }

  next(action);
};

export default createFacilityMiddleware;
