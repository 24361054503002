import { ADD_COMPLEX, loadComplexes } from "redux/ducks/complexes";
import { packSuccess } from "utils/redux-pack";

// asynchronously reload all complexes after a complex has been successfully added
const addComplexMiddleware = ({ dispatch }) => next => action => {
  if (packSuccess(action, ADD_COMPLEX)) {
    dispatch(loadComplexes());
  }
  return next(action);
};

export default addComplexMiddleware;
