import React, { Component } from "react";
import { Alert, Button, Icon, Tooltip, Modal, Input, Divider } from "antd";
import VesselsDisplay from "components/VesselsDisplay";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ActivityType } from "types/activity";
import VesselType from "types/vessel";
import { colors } from "utils/shell-colors";
import { renderFailedRulesList } from "utils/renderers";

import "./style.less";

class ActivitySelector extends Component {
  static propTypes = {
    Activity: ActivityType.isRequired,
    StrategyID: PropTypes.number.isRequired,
    onMethodSelect: PropTypes.func.isRequired,
    onVesselOverride: PropTypes.func.isRequired,
    onActiveActivity: PropTypes.func.isRequired,
    onInactiveActivity: PropTypes.func.isRequired,
    vesselTypes: PropTypes.arrayOf(VesselType).isRequired,
    activeItem: PropTypes.number,
  };

  static defaultProps = {
    activeItem: null,
  };

  state = {
    showVesselDropdownForRole: null,
  };

  handleMethodSelect = (method, strategySelectionID) => e => {
    if (!method.applicable) {
      e.preventDefault();
      Modal.confirm({
        title: "Warning",
        content: (
          <div>
            {`This method is inapplicable due to the following rule${method.failedRules.length !== 1 ? "s" : ""}:`}
            {renderFailedRulesList(method.failedRules, true)}
          </div>
        ),
        okText: "Override",
        okType: "danger",
        cancelText: "Cancel",
        width: "620px",
        onOk: () => {
          this.props.onMethodSelect(strategySelectionID)(method.MethodID);
        },
      });
    } else {
      this.props.onMethodSelect(strategySelectionID)(method.MethodID);
    }
  };

  handleVesselOverrideReason = (VesselTypeID, RoleName, Included) => {
    let vesselOverrideReason;

    const handleChangeReason = ({ target }) => {
      vesselOverrideReason = target.value;
    };

    Modal.confirm({
      title: "Please Provide an override Reason",
      content: <Input defaultValue={vesselOverrideReason} onChange={handleChangeReason} />,
      okText: "Override",
      okType: "primary",
      cancelText: "Omit Reason",
      onOk: () => {
        this.props.onVesselOverride(VesselTypeID, RoleName, Included, vesselOverrideReason);
      },
      onCancel: () => {
        this.props.onVesselOverride(VesselTypeID, RoleName, Included);
      },
    });
  };

  handleVesselOverrideInclude = (VesselTypeID, RoleName) => e => {
    e.preventDefault();
    this.handleVesselOverrideReason(VesselTypeID, RoleName, true);
  };

  handleVesselOverrideExclude = (VesselTypeID, RoleName) => e => {
    e.preventDefault();
    this.handleVesselOverrideReason(VesselTypeID, RoleName, false);
  };

  handleVesselOverrideRemove = (VesselTypeID, RoleName) => () => {
    this.props.onVesselOverride(VesselTypeID, RoleName);
  };

  onMoreInfo = method => e => {
    e.preventDefault();
    Modal.warning({
      title: `Method “${method.Name}” Applicability`,
      content: method.applicable ? (
        <div>This method is applicable according to the available rules.</div>
      ) : (
        <div>
          {`This method is inapplicable due to the following rule${method.failedRules.length !== 1 ? "s" : ""}:`}
          {renderFailedRulesList(method.failedRules, true)}
        </div>
      ),
      width: "620px",
    });
  };

  handleToggleVesselDropdown = RoleName => () => {
    this.setState(prevState => {
      return { showVesselDropdownForRole: RoleName === prevState.showVesselDropdownForRole ? null : RoleName };
    });
  };

  handleVesselAdd = RoleName => VesselTypeID => {
    this.handleVesselOverrideReason(VesselTypeID, RoleName, true);
    this.handleToggleVesselDropdown(RoleName)();
  };

  // we display cost in millions.
  formatCost = cost => (cost / 1000000).toFixed(2);

  render() {
    const { Activity, vesselTypes, onActiveActivity, onInactiveActivity, activeItem, StrategyID } = this.props;
    const { showVesselDropdownForRole } = this.state;

    return (
      <div className="activity-selector" onMouseEnter={onActiveActivity} onMouseLeave={onInactiveActivity}>
        <div className="activity-selector-sequence">{`Activity #${Activity.Sequence}`}</div>
        <div className="activity-selector-metrics">
          <div
            style={{
              ...colors[(Activity.Sequence - 1) % colors.length],
              width: "5px",
              opacity: activeItem && Activity.Sequence !== activeItem ? "0.2" : "1",
            }}
            className={`activity-sequence-marker activity-sequence-marker-${Activity.Sequence}`}
          />
          <div className="time">
            <Icon type="clock-circle" />
            {Activity.Duration > 0 ? `${Activity.Duration.toFixed(2)} days` : "-"}
          </div>
          <div className="cost">
            <Icon type="dollar" />
            {Activity.Cost > 0 ? `${this.formatCost(Activity.Cost)} MM` : "-"}
          </div>
        </div>
        <h3 className="activity-selector-title">{Activity.Name}</h3>
        {Activity.Error && <Alert message={Activity.Error} type="error" showIcon />}
        <div className="activity-selector-interface">
          <div className="activity-selector-interface__methods">
            {Activity.Methods.length === 0 && <div className="method-item no-choices">none</div>}
            {Activity.Methods.map(Method => (
              <div
                key={Method.Name}
                className={classNames("method-item", { disabled: Method.disabled, inapplicable: !Method.applicable })}
              >
                <div className="method-item__checkbox">
                  {/* eslint-disable-next-line */}
                  <label>
                    <input
                      disabled={Boolean(Method.disabled)}
                      name={Activity.Name}
                      type="radio"
                      value={Method.MethodID}
                      checked={Method.MethodID === Activity.StrategySelection.MethodID}
                      onChange={this.handleMethodSelect(Method, Activity.StrategySelection.StrategySelectionID)}
                    />
                    {!Method.applicable && (
                      <Tooltip
                        overlayClassName="applicability-tooltip"
                        title={
                          <div>
                            {`Warning: this method is inapplicable due to the following rule${
                              Method.failedRules.length !== 1 ? "s" : ""
                            }.`}
                            {renderFailedRulesList(Method.failedRules, false)}
                            <Button onClick={this.onMoreInfo(Method)}>More Info</Button>
                          </div>
                        }
                      >
                        <Icon type="warning" />
                      </Tooltip>
                    )}
                    <span className="text">{Method.Name}</span>
                  </label>
                </div>
              </div>
            ))}
            <Link
              className={Activity.Cost !== null && Activity.Duration !== null ? "details" : "hidden"}
              to={`/strategies/${StrategyID}/activity/${Activity.ActivityID}`}
            >
              details
            </Link>
          </div>
          <Divider />
          <VesselsDisplay
            onVesselOverrideInclude={this.handleVesselOverrideInclude}
            onVesselOverrideExclude={this.handleVesselOverrideExclude}
            onVesselOverrideRemove={this.handleVesselOverrideRemove}
            onVesselAdd={this.handleVesselAdd}
            Activity={Activity}
            allVesselTypes={vesselTypes}
            showVesselDropdownForRole={showVesselDropdownForRole}
            onToggleVesselDropdown={this.handleToggleVesselDropdown}
          />
        </div>
      </div>
    );
  }
}

export default ActivitySelector;
