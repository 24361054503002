import api from "utils/api";

function getPortfolio() {
  return api.get("/api/portfolio");
}

function addToPortfolio(facilityIDs) {
  return api.post("/api/portfolio/add", { facilities: facilityIDs });
}

function removeFromPortfolio(facilities, shouldRemoveStrategies) {
  return api.post("/api/portfolio/remove", { facilities, delete: shouldRemoveStrategies });
}

export default {
  addToPortfolio,
  removeFromPortfolio,
  getPortfolio,
};
