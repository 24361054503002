import React from "react";

export const renderFailedRulesList = (failedRules, expanded) =>
  failedRules && failedRules.length ? (
    <ul className="failedRules">
      {failedRules.map(item => {
        const variables = item.Variables ? Object.entries(item.Variables) : [];
        return (
          <li key={item.Name}>
            <span className="ruleName">{item.Name}</span>
            {item.Description && <div className="description">{item.Description}</div>}
            {expanded && (
              <div>
                <div className="caption">The condition</div>
                {item.Formula && <div className="formula">{item.Formula}</div>}
                {variables.length ? (
                  <div className="variables">
                    <div className="caption">{`was not met, based on the value${variables.length > 1 ? "s" : ""}`}</div>
                    <table className="variables">
                      <thead>
                        <th>Variable</th>
                        <th>Value</th>
                      </thead>
                      <tbody>
                        {variables.map(row => (
                          <tr key={row[0]}>
                            <td className="name">{row[0]}</td>
                            <td className="equals">=</td>
                            <td className="value">{row[1]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="caption">was not met.</div>
                )}
                {item.Log && item.Log.length ? (
                  <ol className="log">
                    {item.Log.map((value, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`${index}`}>{value}</li>
                    ))}
                  </ol>
                ) : null}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  ) : null;

export default {
  renderFailedRulesList,
};
