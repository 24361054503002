import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Select, Input, Alert } from "antd";
import LabeledNumericInput from "components/LabeledNumericInput";
import LabeledInputWithUnits from "components/LabeledInputWithUnits";
import { VesselWithCapabilitiesType, CapabilityType } from "types/vessel";
import CountryType from "types/country";
import { isEmpty } from "lodash";
import classNames from "classnames";

import "./style.less";

const { Option } = Select;

class VesselEditModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAfterCloseModal: PropTypes.func.isRequired,
    vesselType: VesselWithCapabilitiesType,
    capabilities: PropTypes.arrayOf(CapabilityType),
    country: CountryType.isRequired,
  };

  static defaultProps = {
    vesselType: {},
    capabilities: [],
  };

  state = {
    edits: {},
    errors: {},
  };

  handleUpdateCapabilities = selection => {
    this.setState(({ edits }) => ({
      edits: {
        ...edits,
        vesselCapabilities: selection,
      },
    }));
  };

  handleUpdateFromEvent = key => ({ target: { value } }) => {
    this.setState(({ edits }) => ({ edits: { ...edits, [key]: value } }));
  };

  handleUpdate = key => value => {
    this.setState(({ edits }) => ({ edits: { ...edits, [key]: value } }));
  };

  validate = () => {
    const {
      vesselCapabilities,
      DayRate,
      Description,
      positioningDuration,
      Quantity,
      liftCapacity,
      sailingSpeed,
      activitySpeed,
      mobilizationDuration,
      demobilizationDuration,
    } = this.state.edits;

    const errors = {};

    if (Description !== undefined && Description.length === 0) {
      errors.Description = true;
    }

    if (liftCapacity !== undefined && liftCapacity.startsWith(" ")) {
      errors.liftCapacity = true;
    }

    if (DayRate !== undefined && DayRate === null) {
      errors.DayRate = true;
    }

    if (positioningDuration !== undefined && positioningDuration.startsWith(" ")) {
      errors.positioningDuration = true;
    }

    if (sailingSpeed !== undefined && sailingSpeed.startsWith(" ")) {
      errors.sailingSpeed = true;
    }

    if (activitySpeed !== undefined && activitySpeed.startsWith(" ")) {
      errors.activitySpeed = true;
    }

    if (mobilizationDuration !== undefined && mobilizationDuration.startsWith(" ")) {
      errors.mobilizationDuration = true;
    }

    if (demobilizationDuration !== undefined && demobilizationDuration.startsWith(" ")) {
      errors.demobilizationDuration = true;
    }

    if (Quantity !== undefined && Quantity === null) {
      errors.Quantity = true;
    }

    if (vesselCapabilities !== undefined && vesselCapabilities.length === 0) {
      errors.vesselCapabilities = true;
    }

    this.setState({ errors });
    return isEmpty(errors);
  };

  handleSaveCapabilities = () => {
    const {
      vesselType: { VesselTypeID },
      country: { CountryID },
    } = this.props;

    if (this.validate()) {
      this.props.onConfirm(VesselTypeID, CountryID, this.state.edits);
    }
  };

  render() {
    const { showModal, onCancel, onAfterCloseModal, vesselType, capabilities } = this.props;
    const { errors } = this.state;

    return (
      <Modal
        title="Edit Vessel"
        className="vessel-edit-modal"
        visible={showModal}
        width={700}
        maskClosable={false}
        onOk={this.handleSaveCapabilities}
        onCancel={onCancel}
        afterClose={onAfterCloseModal}
      >
        {!isEmpty(errors) && (
          <Alert
            message="Validation has failed"
            description={
              <ul>
                {errors.Description && <li>Description cannot be blank</li>}
                {errors.liftCapacity && <li>Lift Capacity cannot be blank</li>}
                {errors.DayRate && <li>Day Rate cannot be blank</li>}
                {errors.positioningDuration && <li>Positioning Duration cannot be blank</li>}
                {errors.Quantity && <li>Quantity cannot be blank</li>}
                {errors.vesselCapabilities && <li>Capabilities cannot be blank</li>}
                {errors.sailingSpeed && <li>Sailing Speed cannot be blank</li>}
                {errors.activitySpeed && <li>Activity Speed cannot be blank</li>}
                {errors.mobilizationDuration && <li>Mobilization Duration cannot be blank</li>}
                {errors.demobilizationDuration && <li>Demobilization Duration cannot be blank</li>}
              </ul>
            }
            type="error"
            showIcon
          />
        )}
        <div className="labeled-field">
          <div className="label">Name</div>
          <div className="value">{vesselType.Name}</div>
        </div>
        <div className="labeled-field">
          <div className="label">Description</div>
          <Input
            className={classNames("value", { error: errors.Description })}
            defaultValue={vesselType.Description}
            onChange={this.handleUpdateFromEvent("Description")}
          />
        </div>
        <div className="row">
          <LabeledInputWithUnits
            label="Lift Capacity"
            className={classNames({ error: errors.liftCapacity })}
            value={vesselType.liftCapacity}
            min={0}
            onChange={this.handleUpdate("liftCapacity")}
          />
          <LabeledNumericInput
            label="Day Rate"
            className={classNames({ error: errors.DayRate })}
            min={0}
            value={vesselType.DayRate}
            onChange={this.handleUpdateFromEvent("DayRate")}
          />
          <LabeledInputWithUnits
            label="Positioning Duration"
            className={classNames({ error: errors.positioningDuration })}
            value={vesselType.positioningDuration}
            min={0}
            onChange={this.handleUpdate("positioningDuration")}
          />
        </div>
        <div className="row">
          <LabeledNumericInput
            label="Quantity"
            className={classNames({ error: errors.Quantity })}
            min={0}
            value={vesselType.Quantity}
            onChange={this.handleUpdateFromEvent("Quantity")}
          />
          <LabeledInputWithUnits
            label="Sailing Speed"
            className={classNames({ error: errors.sailingSpeed })}
            value={vesselType.sailingSpeed}
            min={0}
            onChange={this.handleUpdate("sailingSpeed")}
          />
          <LabeledInputWithUnits
            label="Activity Speed"
            className={classNames({ error: errors.activitySpeed })}
            value={vesselType.activitySpeed}
            min={0}
            onChange={this.handleUpdate("activitySpeed")}
          />
        </div>
        <div className="row">
          <LabeledInputWithUnits
            label="Mobilization Duration"
            className={classNames({ error: errors.mobilizationDuration })}
            value={vesselType.mobilizationDuration}
            min={0}
            onChange={this.handleUpdate("mobilizationDuration")}
          />
          <LabeledInputWithUnits
            label="Demobilization Duration"
            className={classNames({ error: errors.demobilizationDuration })}
            value={vesselType.demobilizationDuration}
            min={0}
            onChange={this.handleUpdate("demobilizationDuration")}
          />
        </div>
        <div className="labeled-field">
          <div className="label">Capabilities</div>
          <Select
            mode="multiple"
            className={classNames("capabilities-select", { error: errors.vesselCapabilities })}
            style={{ width: "100%" }}
            placeholder="Please select a capability"
            onChange={this.handleUpdateCapabilities}
            defaultValue={vesselType.capabilities}
          >
            {capabilities.map(c => (
              <Option key={c.Name}>{c.Name}</Option>
            ))}
          </Select>
        </div>
      </Modal>
    );
  }
}

export default VesselEditModal;
