import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { omit, get } from "lodash";
import { Button, Divider, DatePicker, Row, Col, Select, Alert, Spin } from "antd";
import ComplexType from "types/complex";
import FacilityDetailType from "types/facilityDetails";
import LocationTypePropTypes from "types/location-type";
import { FacilityFunctionPropType } from "types/facility";
import LabeledNumericInput from "components/LabeledNumericInput";
import openAddComplex from "components/AddComplexModal";

import "./style.less";

const { Option } = Select;

class FacilityDetail extends Component {
  static propTypes = {
    complexes: PropTypes.arrayOf(ComplexType).isRequired,
    complexesError: PropTypes.string,
    locationTypes: PropTypes.arrayOf(LocationTypePropTypes),
    facilityFunctions: PropTypes.arrayOf(FacilityFunctionPropType),
    facilityFunctionsError: PropTypes.string,
    facility: FacilityDetailType.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onAddComplex: PropTypes.func.isRequired,
    unsavedFields: PropTypes.object.isRequired,
    facilityTaskVariables: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    complexesError: "",
    locationTypes: [],
    facilityFunctions: [],
    facilityFunctionsError: "",
    facilityTaskVariables: [],
  };

  handleClickAddComplex = () => {
    openAddComplex(this.props.facility.CountryID, this.props.onAddComplex, this.handleChangeComplex);
  };

  handleChangeComplex = value => {
    this.props.onChangeField("ComplexID", value || null);
  };

  handleChangeNumericField = key => event => {
    this.props.onChangeField(key, +event.target.value);
  };

  handleChangeYearInstalled = event => {
    const { value } = event.target;
    this.props.onChangeField("YearInstalled", value ? `${value}-01-01` : null);
  };

  handleChangeCoPDate = event => {
    this.props.onChangeField("CoPDate", event);
  };

  getLocationByLocationType = LocationTypeID => {
    const unsavedLocation = this.props.unsavedFields.locations.find(l => l.LocationTypeID === LocationTypeID);
    const savedLocation = this.props.facility.locations.find(l => l.LocationTypeID === LocationTypeID);
    return unsavedLocation || savedLocation;
  };

  handleChangeFacilityFunction = value => {
    this.props.onChangeField("FacilityFunctionID", value);
  };

  handleCoordinateSignChange = (direction, LocationTypeID) => () => {
    const currentCoord = this.getLocationByLocationType(LocationTypeID).Coordinate[direction];
    this.handleCoordinateChange(direction, LocationTypeID)(-currentCoord);
  };

  handleCoordinateChange = (direction, LocationTypeID) => ({ target: { value } }) => {
    const location = this.getLocationByLocationType(LocationTypeID);
    const updatedLocation = {
      ...location,
      FacilityID: this.props.facility.FacilityID,
      LocationTypeID,
      Coordinate: { ...(location || {}).Coordinate },
    };

    updatedLocation.Coordinate[direction] = +value;

    this.props.onChangeField("locations", updatedLocation);
  };

  unionedLocations = () => {
    const unsavedIds = this.props.unsavedFields.locations.map(l => l.LocationTypeID);
    return [
      ...this.props.facility.locations.filter(l => !unsavedIds.includes(l.LocationTypeID)),
      ...this.props.unsavedFields.locations,
    ];
  };

  highlightTaskVariableField = key => (this.props.facilityTaskVariables.includes(key) ? "highlight-field" : "");

  render() {
    const {
      complexes,
      complexesError,
      locationTypes,
      facility,
      isEditable,
      unsavedFields,
      facilityFunctions,
      facilityFunctionsError,
    } = this.props;

    const fieldValues = {
      NumBridgeLinks: facility.NumBridgeLinks,
      ComplexID: facility.ComplexID,
      CoPDate: facility.CoPDate,
      YearInstalled: facility.YearInstalled,
      locations: this.unionedLocations(),
      FacilityFunctionID: facility.FacilityFunctionID,
      ...omit(unsavedFields, ["locations"]),
    };

    if (isEditable && complexesError) {
      return <Alert message="Error" description={complexesError} type="error" showIcon />;
    }

    if (facilityFunctionsError) {
      return <Alert message="Error" description={facilityFunctionsError} type="error" showIcon />;
    }

    if (!facilityFunctions || !locationTypes || !complexes) {
      return <Spin spinning={true} />;
    }

    // show all known location types if editable, or only the populated location types if viewing
    const locationTypeIDsToShow = (isEditable ? locationTypes : fieldValues.locations || []).map(
      location => location.LocationTypeID
    );

    const filteredComplexes = complexes.filter(c => c.CountryID === facility.CountryID);

    return (
      <div className="facility-detail">
        <h2>General</h2>
        <Row type="flex">
          <Col span={8}>
            <div className="labeled-field">
              <div className="label">Name:</div>
              <div className="value">{facility.Name}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="labeled-field">
              <div className="label">Complex:</div>
              <div className="value">
                {isEditable ? (
                  <Select
                    value={fieldValues.ComplexID || ""}
                    onChange={this.handleChangeComplex}
                    dropdownMatchSelectWidth={false}
                  >
                    <Option key="" value="">
                      none
                    </Option>
                    {filteredComplexes.map(item => (
                      <Option key={item.ComplexID} value={item.ComplexID}>
                        {item.Name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  get(complexes.find(c => c.ComplexID === fieldValues.ComplexID), "Name") || "-"
                )}
              </div>
              {isEditable && (
                <div className="action">
                  <Button onClick={this.handleClickAddComplex} icon="plus" shape="circle" size="small" />
                </div>
              )}
            </div>
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Bridge Links:"
              min={0}
              onChange={this.handleChangeNumericField("NumBridgeLinks")}
              className={this.highlightTaskVariableField("NumBridgeLinks")}
              value={fieldValues.NumBridgeLinks}
            />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("Function")}`}>
              <div className="label">Type:</div>
              <div className="value">
                {isEditable ? (
                  <Select value={fieldValues.FacilityFunctionID} onChange={this.handleChangeFacilityFunction}>
                    {facilityFunctions.map(f => (
                      <Option key={f.FacilityFunctionID} value={f.FacilityFunctionID}>
                        {f.Name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  get(facilityFunctions.find(f => f.FacilityFunctionID === fieldValues.FacilityFunctionID), "Name") ||
                  "unknown"
                )}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("CoPDate")}`}>
              <div className="label">COP Date:</div>
              <div className="value">
                {isEditable ? (
                  <DatePicker
                    value={!fieldValues.CoPDate ? "" : moment.utc(fieldValues.CoPDate)}
                    isEditable={isEditable}
                    onChange={this.handleChangeCoPDate}
                  />
                ) : (
                  moment.utc(facility.CoPDate).format("YYYY-MM-DD")
                )}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Year Installed:"
              min={1900}
              max={+moment().format("YYYY")}
              onChange={this.handleChangeYearInstalled}
              className={this.highlightTaskVariableField("YearInstalled")}
              value={+moment.utc(fieldValues.YearInstalled).format("YYYY")}
            />
          </Col>
        </Row>
        <Divider />
        <Row type="flex">
          {!locationTypeIDsToShow.length && "No locations"}
          {locationTypeIDsToShow.map(locationTypeID => {
            const location = (fieldValues.locations || []).find(item => item.LocationTypeID === locationTypeID);
            const type = locationTypes.find(item => item.LocationTypeID === locationTypeID);
            const lat = location && location.Coordinate ? location.Coordinate.lat : null;
            const lng = location && location.Coordinate ? location.Coordinate.lng : null;
            // detect missing/invalid lat & lng: null, undefined, "", NaN
            const hasLat = +lat === lat;
            const hasLng = +lng === lng;
            return (
              <Col key={locationTypeID} span={8}>
                <div className="labeled-location-field">
                  <div className="label">{`${type.Name}:`}</div>
                  <div className="value">
                    {isEditable ? (
                      <>
                        <LabeledNumericInput
                          className="lat"
                          value={hasLat ? Math.abs(lat) : null}
                          precision={4}
                          onChange={this.handleCoordinateChange("lat", locationTypeID)}
                        />
                        <Select
                          value={lat < 0 ? -1 : 1}
                          onChange={this.handleCoordinateSignChange("lat", locationTypeID)}
                        >
                          <Option value={1}>°N</Option>
                          <Option value={-1}>°S</Option>
                        </Select>
                        <LabeledNumericInput
                          className="lng"
                          precision={4}
                          value={hasLng ? Math.abs(lng) : null}
                          onChange={this.handleCoordinateChange("lng", locationTypeID)}
                        />
                        <Select
                          value={lng < 0 ? -1 : 1}
                          onChange={this.handleCoordinateSignChange("lng", locationTypeID)}
                        >
                          <Option value={1}>°E</Option>
                          <Option value={-1}>°W</Option>
                        </Select>
                      </>
                    ) : (
                      `${!hasLat ? "—" : `${Math.round(Math.abs(lat) * 10000) / 10000}° ${lat < 0 ? "S" : "N"}`}, ${
                        !hasLng ? "—" : `${Math.round(Math.abs(lng) * 10000) / 10000}° ${lng < 0 ? "W" : "E"}`
                      }`
                    )}
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <Divider />
      </div>
    );
  }
}

export default FacilityDetail;
