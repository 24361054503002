import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Alert, Button, Modal, DatePicker, Row, Col, Select, Input } from "antd";
import { FacilityFunctionPropType } from "types/facility";
import LabeledNumericInput from "components/LabeledNumericInput";
import openAddComplex from "components/AddComplexModal";
import ComplexType from "types/complex";
import CountryType from "types/country";
import SubArchetypeType from "types/subArchetype";
import classNames from "classnames";

import "./style.less";

const { Option } = Select;

const initialFacility = {
  Name: null,
  YearInstalled: null,
  ComplexID: null,
  CoPDate: moment.utc(new Date()),
  FacilityFunctionID: null,
  SubArchetypeID: null,
};

class CreateFacility extends Component {
  static propTypes = {
    country: CountryType.isRequired,
    complexes: PropTypes.arrayOf(ComplexType).isRequired,
    facilityFunctions: PropTypes.arrayOf(FacilityFunctionPropType).isRequired,
    showCreateModal: PropTypes.bool.isRequired,
    subArchetypes: PropTypes.arrayOf(SubArchetypeType).isRequired,
    onAddComplex: PropTypes.func.isRequired,
    onCreateConfirm: PropTypes.func.isRequired,
    onCloseCreateModal: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      facility: {
        ...initialFacility,
        CountryID: props.country.CountryID,
      },
      errors: [],
    };
  }

  handleClickAddComplex = () => {
    openAddComplex(this.state.facility.CountryID, this.props.onAddComplex, this.handleUpdateValue("ComplexID"));
  };

  handleUpdateValueFromEvent = key => ({ target }) => {
    const { facility } = this.state;
    this.setState({
      facility: {
        ...facility,
        [key]: target.value,
      },
    });
  };

  handleUpdateValue = key => value => {
    const { facility } = this.state;
    const { subArchetypes } = this.props;

    if (key === "SubArchetypeID") {
      const SubArchetype = subArchetypes.find(s => s.SubArchetypeID === value);

      this.setState({
        facility: {
          ...facility,
          [key]: value,
          archetype: SubArchetype.Archetype,
        },
      });
    } else {
      this.setState({
        facility: {
          ...facility,
          [key]: value,
        },
      });
    }
  };

  handleUpdateYearInstalled = ({ target }) => {
    const { facility } = this.state;
    const { value } = target;
    this.setState({
      facility: {
        ...facility,
        YearInstalled: value ? `${value}-01-01` : null,
      },
    });
  };

  validate() {
    const {
      facility: { Name, YearInstalled, CoPDate, FacilityFunctionID, SubArchetypeID },
    } = this.state;

    const errors = [];

    if (!Name) {
      errors.push("Name");
    }

    if (!SubArchetypeID) {
      errors.push("Type");
    }

    if (!YearInstalled) {
      errors.push("Year Installed");
    }

    if (!CoPDate) {
      errors.push("COP Date");
    }

    if (!FacilityFunctionID) {
      errors.push("Facility Function");
    }

    this.setState({ errors });

    return errors.length === 0;
  }

  handleCreateConfirm = () => {
    const { facility } = this.state;

    if (this.validate()) {
      this.props.onCreateConfirm(facility);
    }
  };

  handleCloseCreateModal = () => {
    this.props.onCloseCreateModal();
  };

  render() {
    const { complexes, facilityFunctions, showCreateModal, subArchetypes } = this.props;
    const {
      errors,
      facility: { Name, YearInstalled, ComplexID, CoPDate, FacilityFunctionID, SubArchetypeID, CountryID },
    } = this.state;

    const filteredComplexes = complexes.filter(c => c.CountryID === CountryID);

    return (
      <Modal
        centered
        visible={showCreateModal}
        wrapClassName="create-facility-modal"
        closable={false}
        maskClosable={false}
        okText="Confirm"
        onCancel={this.handleCloseCreateModal}
        onOk={this.handleCreateConfirm}
        title="Create a new Facility"
      >
        {errors.length !== 0 && <Alert message="Please correct the highlighted fields." type="error" />}
        <Row type="flex">
          <Col span={8}>
            <div className={classNames("labeled-field", { error: errors.includes("Name") })}>
              <div className="label">Name</div>
              <Input value={Name} onChange={this.handleUpdateValueFromEvent("Name")} />
            </div>
          </Col>
          <Col span={8}>
            <div className={classNames("labeled-field", { error: errors.includes("Type") })}>
              <div className="label">Type</div>
              <Select
                dropdownMatchSelectWidth={false}
                value={SubArchetypeID}
                onChange={this.handleUpdateValue("SubArchetypeID")}
              >
                {subArchetypes.map(s => (
                  <Option key={s.SubArchetypeID} value={s.SubArchetypeID}>
                    {s.Name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
          <Col span={8}>
            <div className="labeled-field">
              <div className="label">Complex</div>
              <div className="value">
                <Select value={ComplexID} onChange={this.handleUpdateValue("ComplexID")}>
                  {filteredComplexes.map(c => (
                    <Option key={c.ComplexID} value={c.ComplexID}>
                      {c.Name}
                    </Option>
                  ))}
                </Select>
                <Button onClick={this.handleClickAddComplex} icon="plus" shape="circle" size="small" />
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={6}>
            <LabeledNumericInput
              label="Year Installed"
              className={classNames({ error: errors.includes("Year Installed") })}
              min={1900}
              max={+moment().format("YYYY")}
              onChange={this.handleUpdateYearInstalled}
              value={+moment.utc(YearInstalled).format("YYYY")}
            />
          </Col>
          <Col span={6}>
            <div className={classNames("labeled-field", { error: errors.includes("COP Date") })}>
              <div className="label">COP Date</div>
              <div className="value">
                <DatePicker value={!CoPDate ? "" : moment.utc(CoPDate)} onChange={this.handleUpdateValue("CoPDate")} />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className={classNames("labeled-field", { error: errors.includes("Facility Function") })}>
              <div className="label">Function</div>
              <div className="value">
                <Select value={FacilityFunctionID} onChange={this.handleUpdateValue("FacilityFunctionID")}>
                  {facilityFunctions.map(f => (
                    <Option key={f.FacilityFunctionID} value={f.FacilityFunctionID}>
                      {f.Name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default CreateFacility;
