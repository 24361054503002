import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MainLayout from "layout/Main";
import { Spin } from "antd";
import { withHomeIcon } from "utils/breadcrumbs/home";
import { getTimeframes } from "redux/ducks/settings";
import { activityTimeframesType } from "types/settings";
import ActivityTimeframesSubarchetype from "components/ActivityTimeframesSubarchetype";

import "./style.less";

class ActivityTimeframes extends Component {
  static propTypes = {
    timeframes: activityTimeframesType,
    loading: PropTypes.bool.isRequired,
    getTimeframes: PropTypes.func.isRequired,
  };

  static defaultProps = {
    timeframes: {},
  };

  componentDidMount() {
    this.props.getTimeframes();
  }

  render() {
    const {
      loading,
      timeframes: { subarchetypes },
    } = this.props;

    const breadcrumbs = [withHomeIcon(), { title: "Settings" }, { title: "Activity Timeframes" }];

    if (loading) {
      return <Spin spinning={true} />;
    }

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="activity-timeframes-page">
        <div className="activity-timeframes-content">
          <div className="activity-timeframes-content-title">
            <h1>Activity Timeframes</h1>
            {subarchetypes &&
              subarchetypes.map(
                (subarchetype, i) =>
                  subarchetype.phases.length > 0 && (
                    <ActivityTimeframesSubarchetype key={`${subarchetype.name + i}`} subarchetype={subarchetype} />
                  )
              )}
          </div>
        </div>
      </MainLayout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getTimeframes: () => dispatch(getTimeframes()),
  };
}

function mapStateToProps({ settings }) {
  return {
    timeframes: settings.timeframes || {},
    loading: settings.loading,
    error: settings.error,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityTimeframes);
