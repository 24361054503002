import PropTypes from "prop-types";

export const MarineGrowthPropType = PropTypes.shape({
  MarineGrowthID: PropTypes.number,
  Name: PropTypes.string,
  Value: PropTypes.number,
});

export default PropTypes.shape({
  LegDiameter: PropTypes.number,
  LegWallThickness: PropTypes.number,
  SkirtPileCount: PropTypes.number,
  SkirtPileDiameter: PropTypes.number.isRequired,
  SubstructureID: PropTypes.number,
  TotalHeight: PropTypes.number.isRequired,
  Weight: PropTypes.number.isRequired,
  MarineGrowthID: PropTypes.number,
  PileWallThickness: PropTypes.number,
  LegPileCount: PropTypes.number.isRequired,
  LegPileDiameter: PropTypes.number.isRequired,
  totalJacketWeight: PropTypes.number.isRequired,
});
