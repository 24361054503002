import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Row, Col, Radio, Select, Alert, Spin } from "antd";
import ChooseFacilityModal from "components/ChooseFacilityModal";
import LabeledNumericInput from "components/LabeledNumericInput";
import FacilityDetailType from "types/facility";
import PipelineConfigurationsType from "types/pipelineConfiguration";
import { loadPipelineConfigurations } from "redux/ducks/pipeline-configurations";
import { get } from "lodash";

import "./style.less";

const RadioGroup = Radio.Group;

const { Option } = Select;

export class Pipeline extends Component {
  static propTypes = {
    facility: FacilityDetailType.isRequired,
    isEditable: PropTypes.bool.isRequired,
    onChangeField: PropTypes.func.isRequired,
    unsavedFields: PropTypes.object.isRequired,
    facilityTaskVariables: PropTypes.arrayOf(PropTypes.string),
    pipelineConfigurations: PropTypes.arrayOf(PipelineConfigurationsType),
    loadPipelineConfigurations: PropTypes.func.isRequired,
    loadingPipelineConfigurations: PropTypes.bool,
    pipelineConfigurationsError: PropTypes.string,
  };

  static defaultProps = {
    facilityTaskVariables: [],
    pipelineConfigurations: [],
    loadingPipelineConfigurations: false,
    pipelineConfigurationsError: "",
  };

  state = { startFacilityIsOpen: false, endFacilityIsOpen: false };

  // facilityNameCache allows lookup of facility name in render function,
  // while preserving Start/EndFacilityID as the sole sources of truth
  // (avoiding separate Name state fields that could get out of sync with the
  // ID state fields)
  facilityNameCache = new Map();

  componentDidMount() {
    if (
      !this.props.pipelineConfigurations &&
      !this.props.loadingPipelineConfigurations &&
      !this.props.pipelineConfigurationsError
    ) {
      this.props.loadPipelineConfigurations();
    }
  }

  handleChangeNumericField = key => event => {
    this.props.onChangeField(key, +event.target.value);
  };

  handleEventUpdate = key => event => {
    this.props.onChangeField(key, event.target.value);
  };

  handleChangePipelineConfiguration = value => {
    this.props.onChangeField("PipelineConfigurationID", value);
  };

  handleOpenStartFacility = () => {
    this.setState({ startFacilityIsOpen: true });
  };

  handleOpenEndFacility = () => {
    this.setState({ endFacilityIsOpen: true });
  };

  handleCloseStartFacility = () => {
    this.setState({ startFacilityIsOpen: false });
  };

  handleCloseEndFacility = () => {
    this.setState({ endFacilityIsOpen: false });
  };

  handleSetFacility = fieldName => newFacility => {
    if (newFacility) {
      const { FacilityID, Name } = newFacility;
      this.facilityNameCache.set(FacilityID, Name);
      this.props.onChangeField(fieldName, FacilityID);
    } else {
      this.props.onChangeField(fieldName, null);
    }
  };

  highlightTaskVariableField = key => (this.props.facilityTaskVariables.includes(key) ? "highlight-field" : "");

  render() {
    const { facility, isEditable, unsavedFields, pipelineConfigurations, pipelineConfigurationsError } = this.props;
    const { startFacilityIsOpen, endFacilityIsOpen } = this.state;

    const { pipeline } = facility;

    const fieldValues = {
      StartFacilityID: pipeline.StartFacilityID,
      EndFacilityID: pipeline.EndFacilityID,
      StartFacilityName: pipeline.StartFacilityName,
      EndFacilityName: pipeline.EndFacilityName,
      Length: pipeline.Length,
      InteriorDiameter: pipeline.InteriorDiameter,
      MinWaterDepth: pipeline.MinWaterDepth,
      MaxWaterDepth: pipeline.MaxWaterDepth,
      SignificantDebris: pipeline.SignificantDebris,
      EnvironmentalThreat: pipeline.EnvironmentalThreat,
      PermanentPiggingFacilities: pipeline.PermanentPiggingFacilities,
      NORMSContamination: pipeline.NORMSContamination,
      PipelineConfigurationID: pipeline.PipelineConfigurationID,
      TieInSpoolCount: pipeline.TieInSpoolCount,
      TieInSpoolDiameter: pipeline.TieInSpoolDiameter,
      TieInSpoolAverageLength: pipeline.TieInSpoolAverageLength,
      TieInSpoolProtectionCoverCount: pipeline.TieInSpoolProtectionCoverCount,
      GroutBagCount: pipeline.GroutBagCount,
      ...unsavedFields,
    };

    if (pipeline.StartFacilityID) {
      this.facilityNameCache.set(pipeline.StartFacilityID, pipeline.StartFacilityName);
    }
    if (pipeline.EndFacilityID) {
      this.facilityNameCache.set(pipeline.EndFacilityID, pipeline.EndFacilityName);
    }

    if (pipelineConfigurationsError) {
      return <Alert message="Error" description={pipelineConfigurationsError} type="error" showIcon />;
    }

    if (!pipelineConfigurations) {
      return <Spin spinning={true} />;
    }

    return (
      <div className="pipeline-layout">
        <h2>Pipeline</h2>
        <Row type="flex">
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("StartFacility")}`}>
              <div className="label">Start Facility:</div>
              <div className="value">
                {fieldValues.StartFacilityID
                  ? this.facilityNameCache.get(fieldValues.StartFacilityID)
                  : "(none / shore)"}
              </div>
              {isEditable && (
                <div className="action">
                  <Button onClick={this.handleOpenStartFacility} size="small">
                    Change
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("EndFacility")}`}>
              <div className="label">End Facility:</div>
              <div className="value">
                {fieldValues.EndFacilityID ? this.facilityNameCache.get(fieldValues.EndFacilityID) : "(none / shore)"}
              </div>
              {isEditable && (
                <div className="action">
                  <Button onClick={this.handleOpenEndFacility} size="small">
                    Change
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("PipelineConfiguration")}`}>
              <div className="label">Pipeline Configuration:</div>
              <div className="value">
                {isEditable ? (
                  <Select
                    value={fieldValues.PipelineConfigurationID || "unknown"}
                    dropdownMatchSelectWidth={false}
                    onChange={this.handleChangePipelineConfiguration}
                  >
                    {pipelineConfigurations.map(pc => (
                      <Option key={pc.PipelineConfigurationID} value={pc.PipelineConfigurationID}>
                        {pc.Name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  get(
                    pipelineConfigurations.find(
                      pc => pc.PipelineConfigurationID === fieldValues.PipelineConfigurationID
                    ),
                    "Name"
                  ) || "unknown"
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Length:"
              value={fieldValues.Length}
              min={0}
              onChange={this.handleChangeNumericField("Length")}
              className={this.highlightTaskVariableField("Length")}
              singularUnit="Kilometer"
              pluralUnits="Kilometers"
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Interior Diameter:"
              value={fieldValues.InteriorDiameter}
              min={0}
              onChange={this.handleChangeNumericField("InteriorDiameter")}
              className={this.highlightTaskVariableField("InteriorDiameter")}
              singularUnit="Inch"
              pluralUnits="Inches"
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Min. Water Depth:"
              value={fieldValues.MinWaterDepth}
              min={0}
              onChange={this.handleChangeNumericField("MinWaterDepth")}
              className={this.highlightTaskVariableField("MinWaterDepth")}
              singularUnit="Meter"
              pluralUnits="Meters"
            />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Max. Water Depth:"
              value={fieldValues.MaxWaterDepth}
              min={0}
              onChange={this.handleChangeNumericField("MaxWaterDepth")}
              className={this.highlightTaskVariableField("MaxWaterDepth")}
              singularUnit="Meter"
              pluralUnits="Meters"
            />
          </Col>
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("SignificantDebris")}`}>
              <div className="label">Significant Debris:</div>
              <div className="value">
                {isEditable ? (
                  <RadioGroup
                    value={fieldValues.SignificantDebris}
                    onChange={this.handleEventUpdate("SignificantDebris")}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </RadioGroup>
                ) : (
                  `${fieldValues.SignificantDebris ? "Yes" : "No"}`
                )}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("EnvironmentalThreat")}`}>
              <div className="label">Environmental Threat:</div>
              <div className="value">
                {isEditable ? (
                  <RadioGroup
                    value={fieldValues.EnvironmentalThreat}
                    onChange={this.handleEventUpdate("EnvironmentalThreat")}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </RadioGroup>
                ) : (
                  `${fieldValues.EnvironmentalThreat ? "Yes" : "No"}`
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("PermanentPiggingFacilities")}`}>
              <div className="label">Permanent Pigging Facilities:</div>
              <div className="value">
                {isEditable ? (
                  <RadioGroup
                    value={fieldValues.PermanentPiggingFacilities}
                    onChange={this.handleEventUpdate("PermanentPiggingFacilities")}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </RadioGroup>
                ) : (
                  `${fieldValues.PermanentPiggingFacilities ? "Yes" : "No"}`
                )}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className={`labeled-field ${this.highlightTaskVariableField("NORMSContamination")}`}>
              <div className="label">NORMS Contamination:</div>
              <div className="value">
                {isEditable ? (
                  <RadioGroup
                    value={fieldValues.NORMSContamination}
                    onChange={this.handleEventUpdate("NORMSContamination")}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </RadioGroup>
                ) : (
                  `${fieldValues.NORMSContamination ? "Yes" : "No"}`
                )}
              </div>
            </div>
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Tie In Spool Count:"
              value={fieldValues.TieInSpoolCount}
              min={0}
              onChange={this.handleChangeNumericField("TieInSpoolCount")}
              className={this.highlightTaskVariableField("TieInSpoolCount")}
            />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Tie In Spool Diameter:"
              value={fieldValues.TieInSpoolDiameter}
              min={0}
              onChange={this.handleChangeNumericField("TieInSpoolDiameter")}
              className={this.highlightTaskVariableField("TieInSpoolDiameter")}
              singularUnit="Inch"
              pluralUnits="Inches"
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Tie In Spool Average Length:"
              value={fieldValues.TieInSpoolAverageLength}
              min={0}
              onChange={this.handleChangeNumericField("TieInSpoolAverageLength")}
              className={this.highlightTaskVariableField("TieInSpoolAverageLength")}
              singularUnit="Meter"
              pluralUnits="Meters"
            />
          </Col>
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Tie In Spool Protection Cover Count:"
              value={fieldValues.TieInSpoolProtectionCoverCount}
              min={0}
              onChange={this.handleChangeNumericField("TieInSpoolProtectionCoverCount")}
              className={this.highlightTaskVariableField("TieInSpoolProtectionCoverCount")}
            />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={8}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Grout Bag Count:"
              value={fieldValues.GroutBagCount}
              min={0}
              onChange={this.handleChangeNumericField("GroutBagCount")}
              className={this.highlightTaskVariableField("GroutBagCount")}
            />
          </Col>
        </Row>
        {startFacilityIsOpen && (
          <ChooseFacilityModal
            defaultCountryID={facility.CountryID}
            defaultFacilityID={fieldValues.StartFacilityID}
            onSet={this.handleSetFacility("StartFacilityID")}
            onClose={this.handleCloseStartFacility}
            title="Choose Pipeline Start Facility"
          />
        )}
        {endFacilityIsOpen && (
          <ChooseFacilityModal
            defaultCountryID={facility.CountryID}
            defaultFacilityID={fieldValues.EndFacilityID}
            onSet={this.handleSetFacility("EndFacilityID")}
            onClose={this.handleCloseEndFacility}
            title="Choose Pipeline End Facility"
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadPipelineConfigurations: () => dispatch(loadPipelineConfigurations()),
  };
}

function mapStateToProps({ pipelineConfigurations }) {
  return {
    pipelineConfigurations: pipelineConfigurations.list,
    pipelineConfigurationsError: pipelineConfigurations.error,
    loadingPipelineConfigurations: pipelineConfigurations.isLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pipeline);
