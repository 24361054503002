import React, { Component } from "react";
import { Form, Icon, Input, Button } from "antd";
import AntFormType from "types/ant-form";
import PasswordHelp from "components/PasswordHelp";
import PropTypes from "prop-types";
import "./style.less";

const FormItem = Form.Item;

class ConfirmForgotPassword extends Component {
  static propTypes = {
    form: AntFormType.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    confirmDirty: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values.confirmUsername, values.resetCode, values.password);
      }
    });
  };

  handleBlur = e => {
    const { value } = e.target;
    if (value && !this.state.confirmDirty) {
      this.setState({ confirmDirty: true });
    }
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Your passwords must match!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="forgot-password-form">
        <FormItem>
          {getFieldDecorator("confirmUsername", {
            rules: [{ required: true, message: "Please input your username!" }],
          })(<Input prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Username" />)}
        </FormItem>
        <FormItem>
          {getFieldDecorator("resetCode", {
            rules: [{ required: true, message: "Please input your reset code!" }],
          })(<Input prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Reset Code" />)}
        </FormItem>
        <PasswordHelp />
        <FormItem>
          {getFieldDecorator("password", {
            rules: [
              { required: true, message: "Please input your Password!" },
              {
                validator: this.validatetoNextPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator("confirm", {
            rules: [
              {
                required: true,
                message: "Please confirm your Password!",
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Confirm Password"
              onBlur={this.handleBlur}
            />
          )}
        </FormItem>
        <FormItem>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Reset Password
          </Button>
        </FormItem>
      </Form>
    );
  }
}

export default Form.create()(ConfirmForgotPassword);
