import React, { Component } from "react";
import { get } from "lodash";
import MainLayout from "layout/Main";
import PropTypes from "prop-types";
import FacilityType from "types/facility";
import VesselType from "types/vessel";
import { StrategyType } from "types/strategies";
import HistoryType from "types/history";
import { Button, Icon, Input, Modal, Divider } from "antd";
import ActivitySelector from "components/ActivitySelector";
import TotalTimeCost from "components/TotalTimeCost";
import { withHomeIcon } from "utils/breadcrumbs/home";
import classNames from "classnames";

import "./style.less";
import StrategyPlatformInfo from "components/StrategyPlatformInfo";

const { confirm } = Modal;

class StrategyEditor extends Component {
  static propTypes = {
    strategy: StrategyType,
    facility: FacilityType.isRequired,
    history: HistoryType.isRequired,
    showStrategyInfo: PropTypes.bool.isRequired,
    onToggleStrategyInfo: PropTypes.func.isRequired,
    onUpdateStrategySelection: PropTypes.func.isRequired,
    onUpdateStrategyName: PropTypes.func.isRequired,
    onStrategyClose: PropTypes.func.isRequired,
    onRemoveStrategy: PropTypes.func.isRequired,
    onVesselOverride: PropTypes.func.isRequired,
    vesselTypes: PropTypes.arrayOf(VesselType).isRequired,
  };

  static defaultProps = {
    strategy: null,
  };

  constructor(props) {
    super(props);
    const { strategy } = this.props;
    this.state = {
      editStrategyName: false,
      userStrategyName: strategy ? strategy.Name : "",
      activeItem: null,
    };
  }

  handleActiveActivity = activeItem => () => this.setState({ activeItem });

  handleInactiveActivity = () => this.setState({ activeItem: null });

  handleToggleEditStrategyName = () => {
    const { editStrategyName } = this.state;
    this.setState({ editStrategyName: !editStrategyName });
  };

  handleEditStrategyName = ({ target }) => {
    this.setState({ userStrategyName: target.value });
  };

  handleSaveStrategyName = () => {
    const { userStrategyName } = this.state;
    if (userStrategyName) {
      this.props.onUpdateStrategyName(userStrategyName);
      this.handleToggleEditStrategyName();
    }
  };

  handleCloseStrategyName = () => {
    const { strategy } = this.props;
    this.setState({
      editStrategyName: false,
      userStrategyName: strategy.Name,
    });
  };

  handleDeleteConfirm = ({ strategyID, title, content, path }) => e => {
    e.preventDefault();
    const { onRemoveStrategy, history } = this.props;
    confirm({
      title,
      content,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        await onRemoveStrategy(strategyID)();
        history.push(path);
      },
    });
  };

  handleClose = () => {
    const { history, facility } = this.props;
    this.props.onStrategyClose(facility.FacilityID);
    history.push(`/facility/${facility.FacilityID}/strategies`);
  };

  renderStrategyFacilityInfo = () => {
    const { facility, showStrategyInfo } = this.props;
    switch (facility.archetype) {
      case "Platform":
        return (
          <StrategyPlatformInfo
            showStrategyInfo={showStrategyInfo}
            onToggleStrategyInfo={this.props.onToggleStrategyInfo}
            facility={facility}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { strategy, facility, onUpdateStrategySelection, vesselTypes } = this.props;
    const { editStrategyName, userStrategyName, activeItem } = this.state;

    const breadcrumbs = [
      withHomeIcon({
        path: "/",
      }),
      {
        title: `Strategies for Facility “${facility.Name}”`,
        path: `/facility/${facility.FacilityID}/strategies`,
      },
      {
        title: `Strategy “${strategy.Name}” for Phase “${get(strategy, ["phase", "Name"]) || "—"}”`,
      },
    ];

    return (
      <MainLayout breadcrumbs={breadcrumbs} className="strategy-editor-page">
        <div className="strategy-editor">
          <div className="strategy-editor-header">
            <div className="strategy-editor-header__title">
              <div className="title-display">
                <div className="title-label">Strategy Name:</div>
                {editStrategyName ? (
                  <div className="title-input">
                    <Input type="text" value={userStrategyName} onChange={this.handleEditStrategyName} />
                    <Icon
                      type="check"
                      className={classNames({
                        "disabled-save-name": !userStrategyName,
                      })}
                      onClick={this.handleSaveStrategyName}
                    />
                    <Icon type="close" onClick={this.handleCloseStrategyName} />
                  </div>
                ) : (
                  <div className="title-value">
                    {strategy.Name}
                    <Icon type="edit" onClick={this.handleToggleEditStrategyName} />
                  </div>
                )}
              </div>
            </div>
            <div className="strategy-editor-header__actions">
              <Button
                onClick={this.handleDeleteConfirm({
                  strategyID: strategy.StrategyID,
                  title: `Are you sure you want to delete "${strategy.Name}"?`,
                  content: "This action is permanent.",
                  path: `/facility/${facility.FacilityID}/strategies`,
                })}
                type="danger"
              >
                &nbsp;Delete&nbsp;
              </Button>
              <Button onClick={this.handleClose} type="primary">
                &nbsp;Done&nbsp;
              </Button>
            </div>
          </div>
          {this.renderStrategyFacilityInfo()}
          <div className="strategy-editor__phase-title">
            <div className="phase-title-content">
              {/* eslint-disable-next-line */}
              <div className="name">{`Activities for Phase “${get(strategy, ["phase", "Name"])}”`}</div>
              <TotalTimeCost items={strategy.Activities} activeItem={activeItem} />
            </div>
            <Divider />
          </div>
          <div className="strategy-editor-content">
            <div className="strategy-editor__activities">
              <div className="strategy-editor__activities-inner">
                {strategy.Activities.map(Activity => (
                  <ActivitySelector
                    key={Activity.ActivityID}
                    onMethodSelect={onUpdateStrategySelection}
                    StrategyID={strategy.StrategyID}
                    Activity={Activity}
                    vesselTypes={vesselTypes}
                    onVesselOverride={this.props.onVesselOverride(Activity.ActivityID)}
                    onActiveActivity={this.handleActiveActivity(Activity.Sequence)}
                    onInactiveActivity={this.handleInactiveActivity}
                    activeItem={activeItem}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

export default StrategyEditor;
