import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Button, Spin } from "antd";
import StrategyEditor from "components/StrategyEditor";
import HistoryType from "types/history";
import FacilityDetailsType from "types/facilityDetails";
import VesselType from "types/vessel";
import { StrategyType } from "types/strategies";
import { loadFacilityDetails } from "redux/ducks/facilities";
import { loadVesselTypes } from "redux/ducks/vessel-types";
import {
  removeStrategy,
  updateStrategySelection,
  updateStrategyName,
  getStrategy,
  getStrategies,
  vesselOverride,
} from "redux/ducks/strategies";

class Strategy extends Component {
  static propTypes = {
    history: HistoryType.isRequired,
    facilityID: PropTypes.string.isRequired,
    facility: FacilityDetailsType,
    facilityError: PropTypes.string,
    strategyID: PropTypes.string.isRequired,
    strategy: StrategyType,
    strategyError: PropTypes.string,
    closeStrategy: PropTypes.func.isRequired,
    getStrategy: PropTypes.func.isRequired,
    updateStrategySelection: PropTypes.func.isRequired,
    updateStrategyName: PropTypes.func.isRequired,
    removeStrategy: PropTypes.func.isRequired,
    loadFacilityDetails: PropTypes.func.isRequired,
    loadVesselTypes: PropTypes.func.isRequired,
    vesselOverride: PropTypes.func.isRequired,
    vesselTypes: PropTypes.arrayOf(VesselType),
  };

  static defaultProps = {
    facility: null,
    facilityError: "",
    strategy: null,
    strategyError: "",
    vesselTypes: [],
  };

  state = {
    showStrategyInfo: false,
  };

  componentDidMount() {
    const { facility, facilityID, strategyID } = this.props;

    if (!facility) {
      this.props.loadFacilityDetails(facilityID);
    }

    this.props.getStrategy(strategyID);
    this.props.loadVesselTypes();
  }

  handleGetStrategy = strategyID => this.props.getStrategy(strategyID);

  handleUpdateStrategySelection = strategySelectionID => methodID => {
    this.props.updateStrategySelection(strategySelectionID, methodID);
  };

  handleUpdateStrategyName = userStrategyName => {
    const { StrategyID } = this.props.strategy;
    this.props.updateStrategyName(StrategyID, userStrategyName);
  };

  handleToggleViewStrategyInfo = () => {
    const { showStrategyInfo } = this.state;
    this.setState({ showStrategyInfo: !showStrategyInfo });
  };

  handleRemoveStrategy = strategyID => () => this.props.removeStrategy(strategyID);

  handleVesselOverride = StrategyID => ActivityID => (VesselTypeID, RoleName, Included, Reason) => {
    this.props.vesselOverride(StrategyID, ActivityID, VesselTypeID, RoleName, Included, Reason);
  };

  handleToggleErrorDetails = () => {
    this.setState(({ showErrorDetails }) => {
      return { showErrorDetails: !showErrorDetails };
    });
  };

  render() {
    const { history, facility, facilityError, strategy, strategyError, vesselTypes } = this.props;

    if (facilityError) {
      return <Alert message="Error" description={facilityError} type="error" showIcon />;
    }

    if (strategyError) {
      // extended multi-line error details may be in the error
      // message, starting with two linefeeds in a row.
      // Allow the user to toggle the display of the extended
      // details, defaulting to hiding them.
      const detailsStartOffset = strategyError.indexOf("\n\n");
      const hasDetails = detailsStartOffset >= 0;
      return (
        <Alert
          message="Error"
          description={
            <div>
              <div className="preformatted-message">
                {this.state.showErrorDetails || !hasDetails
                  ? strategyError
                  : strategyError.substr(0, detailsStartOffset)}
              </div>
              {hasDetails && (
                <div className="toggle-details">
                  <Button onClick={this.handleToggleErrorDetails} size="small" icon="search">
                    {this.state.showErrorDetails ? "Hide Details" : "Show Details"}
                  </Button>
                </div>
              )}
            </div>
          }
          type="error"
          showIcon
        />
      );
    }

    if (!facility || !strategy) {
      return <Spin spinning={true} />;
    }

    return (
      <StrategyEditor
        facility={facility}
        history={history}
        strategy={strategy}
        vesselTypes={vesselTypes}
        onGetStrategy={this.handleGetStrategy}
        onUpdateStrategySelection={this.handleUpdateStrategySelection}
        onUpdateStrategyName={this.handleUpdateStrategyName}
        onStrategyClose={this.props.closeStrategy}
        onRemoveStrategy={this.handleRemoveStrategy}
        showStrategyInfo={this.state.showStrategyInfo}
        onToggleStrategyInfo={this.handleToggleViewStrategyInfo}
        onVesselOverride={this.handleVesselOverride(strategy.StrategyID)}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadFacilityDetails: facilityID => dispatch(loadFacilityDetails(facilityID)),
    removeStrategy: strategyID => dispatch(removeStrategy(strategyID)),
    closeStrategy: facilityID => dispatch(getStrategies(facilityID)),
    updateStrategyName: (strategyID, name) => dispatch(updateStrategyName(strategyID, name)),
    updateStrategySelection: (strategySelectionID, methodID) =>
      dispatch(updateStrategySelection(strategySelectionID, methodID)),
    vesselOverride: (StrategyID, ActivityID, VesselTypeID, RoleName, Included, Reason) =>
      dispatch(vesselOverride(StrategyID, ActivityID, VesselTypeID, RoleName, Included, Reason)),
    getStrategy: strategyID => dispatch(getStrategy(strategyID)),
    loadVesselTypes: () => dispatch(loadVesselTypes()),
  };
}

function mapStateToProps({ vesselTypes, facilities, strategies }) {
  return {
    facility: facilities.facility,
    facilityError: facilities.facilityError,
    strategy: strategies.strategy,
    strategyError: strategies.strategyError,
    vesselTypes: vesselTypes.list,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Strategy)
);
