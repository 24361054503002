import PropTypes from "prop-types";
import VesselType from "types/vessel";

export const ActivityType = PropTypes.shape({
  ActivityID: PropTypes.number.isRequired,
  Description: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  Cost: PropTypes.number,
  Duration: PropTypes.number,
  Error: PropTypes.string,
  ErrorDetails: PropTypes.string,
  Sequence: PropTypes.number.isRequired,
  StrategySelection: PropTypes.shape({
    MethodID: PropTypes.number,
    StrategyID: PropTypes.number.isRequired,
    StrategySelectionID: PropTypes.number.isRequired,
  }).isRequired,
  Methods: PropTypes.arrayOf(
    PropTypes.shape({
      MethodID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      Description: PropTypes.string.isRequired,
    })
  ).isRequired,
  VesselRoles: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Vessels: PropTypes.arrayOf(VesselType).isRequired,
    })
  ).isRequired,
});

const FieldType = PropTypes.shape({
  error: PropTypes.string,
  formula: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  variables: PropTypes.object.isRequired,
});

export const ActivityWithTasksType = PropTypes.shape({
  Name: PropTypes.string.isRequired,
  ActivityID: PropTypes.number.isRequired,
  PhaseID: PropTypes.number.isRequired,
  FacilityID: PropTypes.number.isRequired,
  StrategyID: PropTypes.number.isRequired,
  Error: PropTypes.string,
  ErrorDetails: PropTypes.string,
  Tasks: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Cost: PropTypes.number,
      Duration: PropTypes.number,
      Sequence: PropTypes.number.isRequired,
      fields: PropTypes.shape({
        Duration: FieldType.isRequired,
        Cost: FieldType.isRequired,
        RequiredPoB: FieldType,
        LevelOfEffort: FieldType,
        DayRate: FieldType,
      }).isRequired,
    })
  ),
});

export default {
  ActivityType,
  ActivityWithTasksType,
};
