import PropTypes from "prop-types";

export const VesselWithCapabilitiesType = PropTypes.shape({
  VesselTypeID: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  Code: PropTypes.string.isRequired,
  liftCapacity: PropTypes.string,
  sailingSpeed: PropTypes.string,
  activitySpeed: PropTypes.string,
  mobilizationDuration: PropTypes.string,
  demobilizationDuration: PropTypes.string,
  PositioningDuration: PropTypes.string,
  Quantity: PropTypes.number,
  capabilities: PropTypes.arrayOf(PropTypes.string),
  DayRate: PropTypes.number,
});

export const VesselType = PropTypes.shape({
  VesselTypeID: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
  Code: PropTypes.string.isRequired,
  Reason: PropTypes.string,
  applicable: PropTypes.bool,
  manuallyExcluded: PropTypes.bool,
  manuallyIncluded: PropTypes.bool,
});

export const VesselAndCountryOverridesType = PropTypes.shape({
  VesselTypeID: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  Code: PropTypes.string.isRequired,
  DayRate: PropTypes.number,
  Quantity: PropTypes.number,
  overrides: PropTypes.shape({
    DayRate: PropTypes.number,
    Quantity: PropTypes.number,
  }),
});

export const CapabilityType = PropTypes.shape({
  CapabilityID: PropTypes.number.isRequired,
  Name: PropTypes.string.isRequired,
  Description: PropTypes.string.isRequired,
});

export default VesselType;
