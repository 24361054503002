import React, { Component } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { connect } from "react-redux";
import { Divider, Select, Row, Col, Alert, Spin } from "antd";
import LabeledNumericInput from "components/LabeledNumericInput";
import FacilityDetailType from "types/facility";
import { MarineGrowthPropType } from "types/substructure";
import { loadMarineGrowth } from "redux/ducks/marine-growth";

import "./style.less";

const { Option } = Select;

class Substructure extends Component {
  static propTypes = {
    facility: FacilityDetailType.isRequired,
    facilityTaskVariables: PropTypes.arrayOf(PropTypes.string),
    isEditable: PropTypes.bool.isRequired,
    loadMarineGrowth: PropTypes.func.isRequired,
    marineGrowth: PropTypes.arrayOf(MarineGrowthPropType),
    marineGrowthError: PropTypes.string,
    marineGrowthIsLoading: PropTypes.bool,
    onChangeField: PropTypes.func.isRequired,
    unsavedFields: PropTypes.object.isRequired,
  };

  static defaultProps = {
    facilityTaskVariables: [],
    marineGrowth: null,
    marineGrowthError: "",
    marineGrowthIsLoading: true,
  };

  handleChangeNumericField = key => event => {
    this.props.onChangeField(key, +event.target.value);
  };

  handleChangeMarineGrowth = value => {
    this.props.onChangeField("MarineGrowthID", value);
  };

  highlightTaskVariableField = key => (this.props.facilityTaskVariables.includes(key) ? "highlight-field" : "");

  componentDidMount() {
    if (!this.props.marineGrowth && !this.props.marineGrowthIsLoading) {
      this.props.loadMarineGrowth();
    }
  }

  render() {
    const { facility, isEditable, unsavedFields, marineGrowth, marineGrowthError } = this.props;
    const { substructure } = facility.platform;
    const fieldValues = {
      SubstructureWeight: substructure.Weight,
      LegDiameter: substructure.LegDiameter,
      LegWallThickness: substructure.LegWallThickness,
      PileWallThickness: substructure.PileWallThickness,
      SkirtPileCount: substructure.SkirtPileCount,
      SkirtPileDiameter: substructure.SkirtPileDiameter,
      SubstructureTotalHeight: substructure.TotalHeight,
      MarineGrowthID: substructure.MarineGrowthID,
      LegPileCount: substructure.LegPileCount,
      LegPileDiameter: substructure.LegPileDiameter,
      LegCount: facility.platform.LegCount,
      RiserCount: facility.platform.RiserCount,
      ShallowCaissonCount: facility.platform.ShallowCaissonCount,
      DeepCaissonCount: facility.platform.DeepCaissonCount,
      JtubeCount: facility.platform.JtubeCount,
      ConductorCount: facility.platform.ConductorCount,
      ...unsavedFields,
    };

    if (marineGrowthError) {
      return <Alert message="Error" description={marineGrowthError} type="error" showIcon />;
    }

    if (!marineGrowth) {
      return <Spin spinning={true} />;
    }

    return (
      <div className="substructure-layout">
        <h2>Substructure</h2>
        <Row type="flex">
          <Col span={12}>
            <LabeledNumericInput
              isEditable={false}
              label="Total Weight:"
              className={this.highlightTaskVariableField("TotalJacketWeight")}
              value={substructure.totalJacketWeight}
              onChange={() => {}}
              singularUnit="Tonne"
              pluralUnits="Tonnes"
              min={0}
            />
          </Col>
          <Col span={12}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Total Height:"
              className={this.highlightTaskVariableField("SubstructureTotalHeight")}
              onChange={this.handleChangeNumericField("SubstructureTotalHeight")}
              value={fieldValues.SubstructureTotalHeight}
              singularUnit="Meter"
              pluralUnits="Meters"
              min={0}
            />
          </Col>
        </Row>
        <Row type="flex">
          <Col span={12}>
            <LabeledNumericInput
              isEditable={isEditable}
              label="Original Jacket Installed Weight:"
              onChange={this.handleChangeNumericField("SubstructureWeight")}
              className={this.highlightTaskVariableField("SubstructureWeight")}
              value={fieldValues.SubstructureWeight}
              singularUnit="Tonne"
              pluralUnits="Tonnes"
              min={0}
            />
          </Col>
          <Col span={12}>
            <div className={`labeled-field ${this.highlightTaskVariableField("MarineGrowth")}`}>
              <div className="label">Marine Growth:</div>
              <div className="value">
                {isEditable ? (
                  <Select value={fieldValues.MarineGrowthID} onChange={this.handleChangeMarineGrowth}>
                    {marineGrowth.map(m => (
                      <Option key={m.MarineGrowthID} value={m.MarineGrowthID}>
                        {m.Name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  get(marineGrowth.find(m => m.MarineGrowthID === fieldValues.MarineGrowthID), "Name") || "unknown"
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Divider />
        <div className="leg-info-table">
          <Row className="header-row" type="flex">
            <Col span={2} />
            <Col span={6}>Count</Col>
            <Col span={6}>Diameter</Col>
            <Col span={6}>Wall Thickness</Col>
          </Row>
          <Row type="flex">
            <Col className="row-label" span={2}>
              Legs
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("LegCount")}
                className={this.highlightTaskVariableField("LegCount")}
                value={fieldValues.LegCount}
                min={0}
              />
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("LegDiameter")}
                className={this.highlightTaskVariableField("LegDiameter")}
                value={fieldValues.LegDiameter}
                singularUnit="Inch"
                pluralUnits="Inches"
                min={0}
              />
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("LegWallThickness")}
                className={this.highlightTaskVariableField("LegWallThickness")}
                value={fieldValues.LegWallThickness}
                singularUnit="Millimeter"
                pluralUnits="Millimeters"
                min={0}
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col className="row-label" span={2}>
              Leg Piles
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("LegPileCount")}
                className={this.highlightTaskVariableField("LegPileCount")}
                value={fieldValues.LegPileCount}
                min={0}
              />
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("LegPileDiameter")}
                className={this.highlightTaskVariableField("LegPileDiameter")}
                value={fieldValues.LegPileDiameter}
                singularUnit="Inch"
                pluralUnits="Inches"
                min={0}
              />
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("PileWallThickness")}
                className={this.highlightTaskVariableField("PileWallThickness")}
                value={fieldValues.PileWallThickness}
                singularUnit="Millimeter"
                pluralUnits="Millimeters"
                min={0}
              />
            </Col>
          </Row>
          <Row type="flex">
            <Col className="row-label" span={2}>
              Skirt Piles
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("SkirtPileCount")}
                className={this.highlightTaskVariableField("SkirtPileCount")}
                value={fieldValues.SkirtPileCount}
                min={0}
              />
            </Col>
            <Col span={6}>
              <LabeledNumericInput
                isEditable={isEditable}
                onChange={this.handleChangeNumericField("SkirtPileDiameter")}
                className={this.highlightTaskVariableField("SkirtPileDiameter")}
                value={fieldValues.SkirtPileDiameter}
                singularUnit="Inch"
                pluralUnits="Inches"
                min={0}
              />
            </Col>
            <Col span={6} />
          </Row>
        </div>
        <Divider />
        <div className="field-counts-table">
          <Row className="header-row" type="flex" justify="space-around">
            <Col span={4}>Conductors</Col>
            <Col span={4}>Risers</Col>
            <Col span={4}>J-tubes</Col>
            <Col span={4}>Shallow Caissons</Col>
            <Col span={4}>Deep Caissons</Col>
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={4}>
              <LabeledNumericInput
                isEditable={isEditable}
                min={0}
                onChange={this.handleChangeNumericField("ConductorCount")}
                className={this.highlightTaskVariableField("ConductorCount")}
                value={fieldValues.ConductorCount}
              />
            </Col>
            <Col span={4}>
              <LabeledNumericInput
                isEditable={isEditable}
                min={0}
                onChange={this.handleChangeNumericField("RiserCount")}
                className={this.highlightTaskVariableField("RiserCount")}
                value={fieldValues.RiserCount}
              />
            </Col>
            <Col span={4}>
              <LabeledNumericInput
                isEditable={isEditable}
                min={0}
                onChange={this.handleChangeNumericField("JtubeCount")}
                className={this.highlightTaskVariableField("JtubeCount")}
                value={fieldValues.JtubeCount}
              />
            </Col>
            <Col span={4}>
              <LabeledNumericInput
                isEditable={isEditable}
                min={0}
                onChange={this.handleChangeNumericField("ShallowCaissonCount")}
                className={this.highlightTaskVariableField("ShallowCaissonCount")}
                value={fieldValues.ShallowCaissonCount}
              />
            </Col>
            <Col span={4}>
              <LabeledNumericInput
                isEditable={isEditable}
                min={0}
                onChange={this.handleChangeNumericField("DeepCaissonCount")}
                className={this.highlightTaskVariableField("DeepCaissonCount")}
                value={fieldValues.DeepCaissonCount}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadMarineGrowth: () => dispatch(loadMarineGrowth()),
  };
}

function mapStateToProps({ marineGrowth }) {
  return {
    marineGrowth: marineGrowth.list,
    marineGrowthError: marineGrowth.error,
    marineGrowthIsLoading: marineGrowth.isLoading,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Substructure);
