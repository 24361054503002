import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import api from "utils/api";

export const LOAD_SUB_ARCHETYPES = "subArchetypes/LOAD_SUB_ARCHETYPES";

export function loadSubArchetypes() {
  return {
    type: LOAD_SUB_ARCHETYPES,
    promise: api.subArchetypes.getSubArchetypes(),
  };
}

const initialState = {
  isLoading: false,
  list: null,
  error: null,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_SUB_ARCHETYPES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          isLoading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({ ...prevState, error: payload.message || "Error fetching sub archetypes" }),
        success: prevState => ({ ...prevState, list: payload.data }),
      });
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
