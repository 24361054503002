import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./style.less";

const RowActions = ({ onDelete, onEdit, className }) => (
  <div className={classNames("row-actions", className)}>
    <span
      className="row-action"
      role="button"
      tabIndex={0}
      onClick={e => {
        e.stopPropagation();
        onDelete();
      }}
    >
      Delete
    </span>
    <span className="row-action row-action--separator">|</span>
    <span
      className="row-action"
      role="button"
      tabIndex={-1}
      onClick={e => {
        e.stopPropagation();
        onEdit();
      }}
    >
      Edit
    </span>
  </div>
);

RowActions.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

RowActions.defaultProps = {
  className: "",
};

export default RowActions;
