import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "App";
import { unregister } from "registerServiceWorker";
import AWSConfig from "config/aws-config";
import configureStore, { history } from "redux/store";

import "index.less";

const store = configureStore();

Amplify.configure(AWSConfig);

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );
};

render();
// we are not using the service worker. this line will help us unregister
// any already running service workers to avoid any over caching issues.
unregister();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept("./App", () => {
    render();
  });
}
