import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Divider, Spin } from "antd";
import { getGlobals, setEditGlobals, updateGlobal, saveGlobals } from "redux/ducks/admin";
import { isEmpty, get } from "lodash";
import EditablePage from "components/EditablePage";
import LabeledNumericInput from "components/LabeledNumericInput";

import "./style.less";

class OverheadCosts extends Component {
  static propTypes = {
    isEditing: PropTypes.bool.isRequired,
    unsavedGlobalsFields: PropTypes.object.isRequired,
    getGlobals: PropTypes.func.isRequired,
    setEditGlobals: PropTypes.func.isRequired,
    updateGlobal: PropTypes.func.isRequired,
    saveGlobals: PropTypes.func.isRequired,
    saveError: PropTypes.string.isRequired,
    globals: PropTypes.shape({
      contractorEngineering: PropTypes.string,
      projectManagement: PropTypes.string,
      DAR: PropTypes.string,
      contingency: PropTypes.string,
    }),
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
  };

  static defaultProps = {
    globals: {},
  };

  componentDidMount = () => {
    this.props.getGlobals();
  };

  handleGlobalsEdit = () => {
    this.props.setEditGlobals(true);
  };

  handleCancelGlobalsEdit = () => {
    this.props.setEditGlobals(false);
  };

  handleChangeField = key => ({ target: { value } }) => {
    this.props.updateGlobal(key, (value / 100).toString());
  };

  handleGlobalsSave = () => {
    const { unsavedGlobalsFields } = this.props;
    if (!isEmpty(unsavedGlobalsFields)) {
      this.props.saveGlobals(unsavedGlobalsFields);
    } else {
      this.props.setEditGlobals(false);
    }
  };

  highlightTaskVariableField = key => {
    // when navigating from the task page, we will provide a list of attributes to highlight.
    const overrideCostsVariables = get(this.props.location, "state.overrideCostsVariables", []);

    return overrideCostsVariables.includes(key) ? "highlight-field" : "";
  };

  render() {
    const { globals, isEditing, saveError } = this.props;
    const breadcrumbs = [{ title: "Settings" }, { title: "Overhead Costs" }];

    if (isEmpty(globals)) {
      return <Spin spinning={true} />;
    }

    return (
      <EditablePage
        breadcrumbs={breadcrumbs}
        isEditing={isEditing}
        isEditable={true}
        saveError={saveError}
        onCancelEdit={this.handleCancelGlobalsEdit}
        onEdit={this.handleGlobalsEdit}
        onSave={this.handleGlobalsSave}
      >
        <div className="overhead-costs-content">
          <div className="overhead-costs-content-title">
            <h1>Overhead Costs</h1>
          </div>
          <Divider />
          <LabeledNumericInput
            isEditable={isEditing}
            label="Contractor Engineering:"
            min={0}
            onChange={this.handleChangeField("contractorEngineering")}
            className={this.highlightTaskVariableField("contractorEngineering")}
            value={+(globals.contractorEngineering * 100).toFixed(1)}
            singularUnit="%"
            pluralUnits="%"
          />
          <LabeledNumericInput
            isEditable={isEditing}
            label="Project Management:"
            min={0}
            onChange={this.handleChangeField("projectManagement")}
            className={this.highlightTaskVariableField("projectManagement")}
            value={+(globals.projectManagement * 100).toFixed(1)}
            singularUnit="%"
            pluralUnits="%"
          />
          <LabeledNumericInput
            isEditable={isEditing}
            label="DAR:"
            min={0}
            onChange={this.handleChangeField("DAR")}
            className={this.highlightTaskVariableField("DAR")}
            value={+(globals.DAR * 100).toFixed(1)}
            singularUnit="%"
            pluralUnits="%"
          />
          <LabeledNumericInput
            isEditable={isEditing}
            label="Contingency:"
            min={0}
            onChange={this.handleChangeField("contingency")}
            className={this.highlightTaskVariableField("contingency")}
            value={+(globals.contingency * 100).toFixed(1)}
            singularUnit="%"
            pluralUnits="%"
          />
        </div>
      </EditablePage>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getGlobals: () => dispatch(getGlobals()),
    setEditGlobals: isEditing => dispatch(setEditGlobals(isEditing)),
    updateGlobal: (key, value) => dispatch(updateGlobal(key, value)),
    saveGlobals: globals => dispatch(saveGlobals(globals)),
  };
}

function mapStateToProps({ admin }) {
  return {
    globals: admin.globals,
    unsavedGlobalsFields: admin.unsavedGlobalsFields,
    isEditing: admin.isEditing,
    saveError: admin.saveError,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OverheadCosts)
);
