import api from "utils/api";

function getGlobals() {
  return api.get("/api/admin/globals");
}

function getUserGlobals() {
  return api.get("/api/admin/globals/userOverrides");
}

function saveGlobals(globals) {
  return api.patch("/api/admin/globals", globals);
}

function saveUserGlobals(userGlobals) {
  return api.patch("/api/admin/globals/userOverrides", userGlobals);
}

function resetEnvData() {
  return api.post("/api/admin/reset");
}

export default {
  getGlobals,
  getUserGlobals,
  saveGlobals,
  saveUserGlobals,
  resetEnvData,
};
