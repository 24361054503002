import { LOGOUT } from "redux/ducks/auth";
import { push } from "connected-react-router";
import { packSuccess } from "utils/redux-pack";

const logoutMiddleware = ({ dispatch }) => next => action => {
  if (packSuccess(action, LOGOUT)) {
    dispatch(push("/"));
  }

  next(action);
};

export default logoutMiddleware;
