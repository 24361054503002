import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Modal, Upload } from "antd";
import api from "utils/api";
import templatePlatformsCSVFile from "assets/Platforms-sample.csv";
import templatePipelinesCSVFile from "assets/Pipelines-sample.csv";
import templateSubseasCSVFile from "assets/Subseas-sample.csv";

import "./style.less";

class UploadFacilities extends Component {
  static propTypes = {
    onUploadDone: PropTypes.func.isRequired, // called to let parent facilities list refresh
    onClose: PropTypes.func.isRequired, // always called when exiting modal
    visible: PropTypes.bool.isRequired,
  };

  state = {
    fileList: [],
    uploading: false,
  };

  handleClose = () => {
    // The user is dismissing the dialog. This is always called, whether
    // the reason is that the user clicked Cancel, or the upload succeeded
    // or failed and the user clicked OK to dimsiss the report modal.
    this.setState({
      fileList: [],
      uploading: false,
    });
    this.props.onClose();
  };

  handleUploadDone = () => {
    // Notify parent to refresh. handleClose will be called later
    // when the user dismisses the report modal, so don't clear
    // the file list state here, because it would just be distracting.
    this.props.onUploadDone();
  };

  handleUpload = () => {
    const { fileList } = this.state;
    if (!fileList.length) {
      return;
    }
    const file = fileList[0];

    this.setState({
      uploading: true,
    });
    api.facilities
      .uploadFacilities(file)
      .then(response => {
        // Keep the file upload modal on the screen while displaying
        // the report modal on top of it, then dismiss them both
        // when the user clicks OK.
        const { imported, skipped } = response.data;
        Modal[imported ? "success" : "warning"]({
          title: `Import from file “${file.name}”`,
          content: `${imported === 0 ? "No" : imported} ${
            imported === 1 ? "facility was" : "facilities were"
          } imported.${
            skipped
              ? ` ${skipped} ${
                  skipped === 1
                    ? "was skipped because its name matched an existing facility"
                    : "were skipped because their names matched existing facilities"
                }.`
              : ""
          }`,
          onOk: this.handleClose,
        });

        // Let the facilities list know to refresh, which occurs as soon
        // as the upload completes.
        this.handleUploadDone();
      })
      .catch(e => {
        // As in the success case, display a report modal, then dismiss both
        // the upload dialog and the report modal when the user clicks OK.
        const errMessage =
          e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
        Modal.error({
          title: `Import of file “${file.name}” failed.`,
          content: errMessage,
          onOk: this.handleClose,
        });

        // Let the facilities list know to refresh, because even though the
        // upload ultimately failed, some facilities may have been modified.
        this.handleUploadDone();
      });
  };

  render() {
    const { visible } = this.props;
    const { fileList, uploading } = this.state;
    return (
      <Modal
        centered
        closable={false}
        confirmLoading={uploading}
        maskClosable={false}
        okButtonProps={{ disabled: uploading || !fileList.length }}
        okText="Import"
        onCancel={this.handleClose}
        onOk={this.handleUpload}
        title="Import Facilities"
        visible={visible}
        wrapClassName="upload-facility-modal"
      >
        <Upload.Dragger
          accept=".csv"
          beforeUpload={file => {
            // Control fileList to ensure that only one file is selected
            this.setState({ fileList: [file] });
            // Return false so that the upload only occurs when the user
            // clicks Import, not immediately, and so it can use the project's
            // existing Axios-based API request initiators and authentication,
            // rather than the upload component's own XHR implementation.
            return false;
          }}
          fileList={fileList}
          name="file"
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Click to select a facility data CSV file, or drag it to this area</p>
          <p className="ant-upload-hint">
            New facilities in the file will be imported. If facilities already exist with the same names, those rows
            will be skipped.
          </p>
        </Upload.Dragger>

        <p className="template-description">
          {"To get started creating a CSV file, download a template for the facility type:"}
        </p>
        <ul>
          <li>
            <a href={templatePlatformsCSVFile} download>
              Platforms.csv template
            </a>
          </li>
          <li>
            <a href={templatePipelinesCSVFile} download>
              Pipelines.csv template
            </a>
          </li>
          <li>
            <a href={templateSubseasCSVFile} download>
              Subseas.csv template
            </a>
          </li>
        </ul>
      </Modal>
    );
  }
}

export default UploadFacilities;
