import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import { get } from "lodash";
import api from "utils/api";

export const GET_TIMEFRAMES = "settings/GET_TIMEFRAMES";

export function getTimeframes() {
  return {
    type: GET_TIMEFRAMES,
    promise: api.settings.getTimeframes(),
  };
}
const initialState = {
  loading: false,
  error: null,
  timeframes: null,
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case GET_TIMEFRAMES:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          loading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, loading: false }),
        failure: prevState => ({ ...prevState, error: message || "could not get timeframes" }),
        success: prevState => ({ ...prevState, timeframes: payload.data }),
      });
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
