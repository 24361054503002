import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Input, Alert } from "antd";
import LabeledNumericInput from "components/LabeledNumericInput";
import { isEmpty, isNaN } from "lodash";

import "./style.less";

class CustomTaskModal extends Component {
  static propTypes = {
    showModal: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onUpdateTask: PropTypes.func.isRequired,
    taskError: PropTypes.string,
    draftTask: PropTypes.shape({
      CustomTaskID: PropTypes.number,
      Name: PropTypes.string,
      Cost: PropTypes.number,
      Duration: PropTypes.number,
    }).isRequired,
  };

  static defaultProps = {
    taskError: "",
  };

  state = {
    errors: {},
  };

  validate = task => {
    const errors = {};
    const { Name, Cost, Duration } = task;

    if (!Name) {
      errors.name = true;
    }

    if (Duration === undefined || Duration === null) {
      errors.duration = true;
    } else if (isNaN(Number(Duration))) {
      errors.durationFormat = true;
    }

    if (Cost === undefined || Cost === null) {
      errors.cost = true;
    } else if (isNaN(Number(Cost))) {
      errors.costFormat = true;
    }

    this.setState({ errors });

    return isEmpty(errors);
  };

  handleOnOk = () => {
    const { draftTask } = this.props;
    if (this.validate(draftTask)) {
      this.props.onOk(draftTask);
      this.setState({ errors: {} });
    }
  };

  handleCancel = () => {
    this.setState({ errors: {} });
    this.props.onCancel();
  };

  handleChange = (field, isNumber) => ({ target: { value } }) => {
    let newValue = value;
    if (isNumber) {
      // Ensure numeric fields receive null or a numeric type (which may be
      // NaN), never a string. Otherwise they would fail propType validation.
      // Ant Design's InputNumber component sometimes sends numbers and other
      // times sends strings (e.g. as the user types, it might send "", then 3,
      // then "3.", then 3.1). Instead, convert empty fields to null, and
      // invalid numbers to NaN.
      newValue = value === "" || value === null ? null : Number(value);
    }
    const newDraftTask = { ...this.props.draftTask, [field]: newValue };
    this.props.onUpdateTask(newDraftTask);
  };

  render() {
    const { showModal, taskError, draftTask } = this.props;
    const { errors } = this.state;
    return (
      <Modal
        visible={showModal}
        className="custom-task-modal"
        width={650}
        onOk={this.handleOnOk}
        onCancel={this.handleCancel}
        title="Custom Task"
        maskClosable={false}
        destroyOnClose={true}
      >
        {taskError && <Alert message="Error" description={taskError} type="error" showIcon />}
        <div className="labeled-field">
          <div className="label">Task Name</div>
          <Input
            className="value"
            autoFocus={true}
            onChange={this.handleChange("Name", false)}
            value={draftTask.Name}
          />
          {errors.name && <div className="error">Name cannot be blank</div>}
        </div>
        <div className="data">
          <div>
            <LabeledNumericInput
              isEditable={true}
              label="Duration"
              min={0}
              onChange={this.handleChange("Duration", true)}
              singularUnit="day"
              pluralUnits="days"
              value={draftTask.Duration}
            />
            {errors.duration && <div className="error">Duration cannot be blank</div>}
            {errors.durationFormat && <div className="error">Duration must be a number</div>}
          </div>
          <div>
            <LabeledNumericInput
              isEditable={true}
              label="Cost"
              min={0}
              onChange={this.handleChange("Cost", true)}
              singularUnit="dollar"
              pluralUnits="dollars"
              value={draftTask.Cost}
            />
            {errors.cost && <div className="error">Cost cannot be blank</div>}
            {errors.costFormat && <div className="error">Cost must be a number</div>}
          </div>
        </div>
      </Modal>
    );
  }
}

export default CustomTaskModal;
