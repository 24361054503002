import React from "react";
import LabeledItem from "components/LabeledItem";
import PropTypes from "prop-types";
import FacilityType from "types/facility";

import "./style.less";

const StrategyPlatformInfo = ({ facility, showStrategyInfo, onToggleStrategyInfo }) => {
  return showStrategyInfo ? (
    <div className="strategy-editor-information">
      <div className="strategy-editor-information-content">
        <div className="strategy-editor-information-content-data">
          <div className="section">
            <h3 className="section-title">{facility.archetype}</h3>
            <div className="section-data">
              <LabeledItem label="Number of Bridge Links:" value={facility.NumBridgeLinks} />
            </div>
          </div>
          <div className="section">
            <h3 className="section-title">Topsides</h3>
            <div className="section-data">
              <LabeledItem
                label="Installation Method:"
                value={facility.platform.topsides.topsidesOriginalInstallationMethod.Name}
              />
              <LabeledItem
                label="Total Weight:"
                singularUnit="Tonne"
                pluralUnits="Tonnes"
                value={facility.platform.topsides.totalWeight}
              />
              <LabeledItem
                label="Max Primary Module Weight:"
                singularUnit="Tonne"
                pluralUnits="Tonnes"
                value={facility.platform.topsides.maxPrimaryModuleWeight}
              />
              <LabeledItem
                label="Max Extra Module Weight:"
                singularUnit="Tonne"
                pluralUnits="Tonnes"
                value={facility.platform.topsides.maxExtraModuleWeight}
              />
            </div>
          </div>
          <div className="section">
            <h3 className="section-title">Substructure</h3>
            <div className="section-data">
              <LabeledItem
                label="Total Height:"
                singularUnit="Meter"
                pluralUnits="Meters"
                value={facility.platform.substructure.TotalHeight}
              />
              <LabeledItem
                label="Total Weight:"
                singularUnit="Tonne"
                pluralUnits="Tonnes"
                value={facility.platform.substructure.totalJacketWeight}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="strategy-editor-information__control">
        <button type="button" onClick={onToggleStrategyInfo}>
          Close
        </button>
      </div>
    </div>
  ) : (
    <div className="strategy-editor-information closed">
      <div className="strategy-editor-information-content">
        <h3 className="strategy-editor-information-content-title">
          {`${facility.archetype} "${facility.Name}" Information`}
        </h3>
      </div>
      <div className="strategy-editor-information__control">
        <button type="button" onClick={onToggleStrategyInfo}>
          Show Info
        </button>
      </div>
    </div>
  );
};

StrategyPlatformInfo.propTypes = {
  facility: FacilityType.isRequired,
  showStrategyInfo: PropTypes.bool.isRequired,
  onToggleStrategyInfo: PropTypes.func.isRequired,
};

export default StrategyPlatformInfo;
