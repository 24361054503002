import PropTypes from "prop-types";

export const CountryRatesType = PropTypes.shape({
  productivityRR: PropTypes.string.isRequired,
  productivityDFPV: PropTypes.string.isRequired,
  productivityTopsidesPrep: PropTypes.string.isRequired,
  productivityTopsidesDecom: PropTypes.string.isRequired,
  productivitySubstructurePrep: PropTypes.string.isRequired,
  directSupportPrePA: PropTypes.string.isRequired,
  supervisionPrePA: PropTypes.string.isRequired,
  directSupportPostPA: PropTypes.string.isRequired,
  supervisionPostPA: PropTypes.string.isRequired,
  fabricationRate: PropTypes.string.isRequired,
  disposalRate: PropTypes.string.isRequired,
  engineeringRate: PropTypes.string.isRequired,
  supplyBoatRate: PropTypes.string.isRequired,
  laborRate: PropTypes.string.isRequired,
  logisticsRate: PropTypes.string.isRequired,
  pieceSmallEquipmentRate: PropTypes.string.isRequired,
  singleLiftPreparationEquipmentRate: PropTypes.string.isRequired,
  multipleLiftPreparationEquipmentRate: PropTypes.string.isRequired,
  shiftDuration: PropTypes.string.isRequired,
  bargeRate_500Te: PropTypes.string.isRequired,
  tugRate_500Te: PropTypes.string.isRequired,
  bargeRate_1500Te: PropTypes.string.isRequired,
  tugRate_1500Te: PropTypes.string.isRequired,
  bargeRate_5000Te: PropTypes.string.isRequired,
  tugRate_5000Te: PropTypes.string.isRequired,
  bargeRate_14000Te: PropTypes.string.isRequired,
  tugRate_14000Te: PropTypes.string.isRequired,
});

export default {
  CountryRatesType,
};
