import { handle } from "redux-pack";
import { LOGOUT } from "redux/ducks/auth";
import { RESET_REDUX_STATE } from "redux/ducks/admin";
import { get } from "lodash";
import api from "utils/api";

export const LOAD_FACILITIES_FOR_COUNTRY = "LOAD_FACILITIES_FOR_COUNTRY";
export const LOAD_FACILITY = "facilities/LOAD_FACILITY";
export const SAVE_FACILITY = "facilities/SAVE_FACILITY";
export const CREATE_FACILITY = "facilities/CREATE_FACILITY";
export const EDIT_FACILITY = "facilites/EDIT_FACILITY";
export const UPDATE_FACILITY = "facilities/UPDATE_FACILITY";

export function loadFacilitiesForCountry(countryID) {
  return {
    type: LOAD_FACILITIES_FOR_COUNTRY,
    promise: api.facilities.getFacilitiesByCountry(countryID),
  };
}

export function loadFacilityDetails(facilityID) {
  return {
    type: LOAD_FACILITY,
    promise: api.facilities.getFacilityDetails(facilityID),
  };
}

export function saveFacilityDetails(id, archetype, facilityData) {
  return {
    type: SAVE_FACILITY,
    promise: api.facilities.saveFacilityDetails(id, archetype, facilityData),
  };
}

export function createFacility(facilityData) {
  return {
    type: CREATE_FACILITY,
    promise: api.facilities.createFacility(facilityData),
  };
}

export function updateFacilityDetails(key, value) {
  return {
    type: UPDATE_FACILITY,
    payload: {
      [key]: value,
    },
  };
}

export function setEditFacility(isEditing) {
  return {
    type: EDIT_FACILITY,
    payload: {
      isEditing,
    },
  };
}

const initialState = {
  isLoading: false,
  list: null,
  facility: null,
  facilityError: "",
  saveError: "",
  error: null,
  isEditing: false,
  unsavedFacilityFields: {
    locations: [],
  },
};

function reducer(state = initialState, action) {
  const { type, payload } = action;
  const message = get(payload, "response.data.message", null);
  switch (type) {
    case LOAD_FACILITIES_FOR_COUNTRY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          isLoading: true,
          error: null,
        }),
        finish: prevState => ({ ...prevState, isLoading: false }),
        failure: prevState => ({ ...prevState, error: message || "Could not load facilities for country" }),
        success: prevState => ({ ...prevState, list: payload.data }),
      });
    case LOAD_FACILITY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          facility: null,
          facilityError: "",
        }),
        failure: prevState => ({
          ...prevState,
          facilityError: message || "Could not load facility data",
        }),
        success: prevState => ({ ...prevState, facility: payload.data }),
      });
    case EDIT_FACILITY:
      return {
        ...state,
        unsavedFacilityFields: initialState.unsavedFacilityFields,
        isEditing: payload.isEditing,
        facilityError: !payload.isEditing ? initialState.facilityError : state.facilityError,
        saveError: !payload.isEditing ? initialState.saveError : state.saveError,
      };
    case UPDATE_FACILITY: {
      const updateKey = Object.keys(payload)[0];
      const updateValue = payload[updateKey];
      let updates = {};
      if (updateKey === "locations") {
        updates.locations = [
          ...state.unsavedFacilityFields.locations.filter(l => l.LocationTypeID !== updateValue.LocationTypeID),
          updateValue,
        ];
      } else {
        updates = {
          [updateKey]: updateValue,
        };
      }
      return {
        ...state,
        unsavedFacilityFields: {
          ...state.unsavedFacilityFields,
          ...updates,
        },
      };
    }
    case SAVE_FACILITY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          saveError: "",
        }),
        failure: prevState => ({
          ...prevState,
          saveError: message || "Could not save facility data",
        }),
        success: prevState => ({
          ...prevState,
          facility: payload.data,
          isEditing: false,
          unsavedFacilityFields: initialState.unsavedFacilityFields,
        }),
      });
    case CREATE_FACILITY:
      return handle(state, action, {
        start: prevState => ({
          ...prevState,
          saveError: "",
        }),
        failure: prevState => ({
          ...prevState,
          saveError: message || "Could not save facility data",
        }),
        success: prevState => ({
          ...prevState,
          facility: payload.data,
          isEditing: false,
          unsavedFacilityFields: initialState.unsavedFacilityFields,
        }),
      });
    case "@@router/LOCATION_CHANGE": {
      // This reducer case will reset the strategy state by checking to see if
      // facility is no longer included in the URL path. The thought is that facility
      // related routes need the facility to be present, otherwise we clear out the
      // facility and strategy state.
      const path = action.payload.location.pathname.split("/");

      if (!path.includes("facility")) {
        return initialState;
      }

      return state;
    }
    case RESET_REDUX_STATE:
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
