import React from "react";
import PropTypes from "prop-types";

const LabeledItem = ({ label, value, singularUnit, pluralUnits }) => (
  <div>
    <span className="label">{label}</span>
    <span className="value">
      {value}
      {value === 1 ? singularUnit && ` ${singularUnit}` : pluralUnits && ` ${pluralUnits}`}
    </span>
  </div>
);

LabeledItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  singularUnit: PropTypes.string,
  pluralUnits: PropTypes.string,
};

LabeledItem.defaultProps = {
  singularUnit: "",
  pluralUnits: "",
};

export default LabeledItem;
