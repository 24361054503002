import React from "react";
import { Icon } from "antd";

import "./style.less";

const HomeIcon = () => (
  <span className="breadcrumbs__home">
    <Icon type="profile" className="icon" />
    Portfolio
  </span>
);

export default HomeIcon;

export const withHomeIcon = (breadcrumb = { path: "/" }) => ({
  title: "Home",
  ...breadcrumb,
  component: <HomeIcon />,
});
