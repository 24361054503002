import api from "utils/api";

function addComplex(CountryID, Name) {
  return api.post("api/complexes", { CountryID, Name });
}

function getComplexes() {
  return api.get("api/complexes");
}

export default {
  addComplex,
  getComplexes,
};
