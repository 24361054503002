import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dropdown, Icon, Input, Menu, Popover, Radio } from "antd";
import CountryType from "types/country";
import { FacilitiesType } from "types/facility";
import FacilitiesFilter, { FiltersType } from "components/FacilitiesFilter";

import "./style.less";

class FacilitiesListHeader extends Component {
  static propTypes = {
    actions: PropTypes.node,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    country: CountryType.isRequired,
    facilities: FacilitiesType.isRequired,
    filteredFacilities: FacilitiesType.isRequired,
    filters: FiltersType.isRequired,
    onCountrySelect: PropTypes.func.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func.isRequired,
    onViewChange: PropTypes.func.isRequired,
    searchText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    viewAsMap: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    actions: null,
  };

  state = {
    showFilters: false,
  };

  handleFilterChange = newFilters => {
    this.setState({ showFilters: false });
    this.props.onFilterChange(newFilters);
  };

  handleShowFiltersChange = () => {
    this.setState(({ showFilters }) => ({
      showFilters: !showFilters,
    }));
  };

  handleCountrySelect = ({ key }) => this.props.onCountrySelect(key);

  render() {
    const {
      actions,
      countries,
      country,
      facilities,
      filteredFacilities,
      filters,
      onSearchChange,
      onViewChange,
      searchText,
      title,
      viewAsMap,
    } = this.props;
    const { showFilters } = this.state;

    return (
      <div className="facilities-list-header">
        <div className="facilities-inner-title">
          {title}
          <div className="item-count">
            Showing
            {`${(filteredFacilities.length === facilities.length && facilities.length > 1 ? " all " : " ") +
              filteredFacilities.length +
              (filteredFacilities.length !== facilities.length ? ` of ${facilities.length}` : "") +
              (facilities.length === 1 ? " facility" : " facilities")} for `}
            <Dropdown
              trigger={["click"]}
              overlay={
                <Menu className="country-list" onClick={this.handleCountrySelect}>
                  {countries.map(c => (
                    <Menu.Item key={c.CountryID}>
                      <div className="country-list-item">
                        <h4>{c.Name}</h4>
                        {c.FacilityCount !== undefined && (
                          <div className="facility-count">
                            {`${c.FacilityCount} ${c.FacilityCount === 1 ? "facility" : "facilities"}`}
                          </div>
                        )}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <span>
                {country.Name || "Select a Country"}
                <Icon type="caret-down" theme="outlined" />
              </span>
            </Dropdown>
          </div>
        </div>
        {actions}
        <Input.Search
          placeholder="find facility name"
          value={searchText}
          onChange={e => {
            onSearchChange(e.target.value);
          }}
        />
        <Radio.Group className="facility-list-toggle" onChange={onViewChange} value={viewAsMap ? "map" : "list"}>
          <Radio.Button className="facility-list-toggle__list" value="list">
            <Icon type="bars" />
          </Radio.Button>
          <Radio.Button className="facility-list-toggle__map" data-testid="map-toggle" value="map">
            <Icon type="global" />
          </Radio.Button>
        </Radio.Group>
        <Popover
          content={<FacilitiesFilter filters={filters} onFilterChange={this.handleFilterChange} />}
          onVisibleChange={this.handleShowFiltersChange}
          placement="bottomRight"
          trigger="click"
          visible={showFilters}
        >
          <Button className="facility-filters-toggle">Filters</Button>
        </Popover>
      </div>
    );
  }
}

export default FacilitiesListHeader;
