import React, { Component } from "react";
import { Button, Collapse } from "antd";
import { subarchetypeForTimeframesType } from "types/settings";

import "./style.less";

const { Panel } = Collapse;

class ActivityTimeframesSubarchetype extends Component {
  static propTypes = {
    subarchetype: subarchetypeForTimeframesType.isRequired,
  };

  state = {
    accordionOpenKeys: [],
  };

  handleAccordionChange = keys => {
    this.setState({ accordionOpenKeys: keys });
  };

  handleOpenAllPhases = () => {
    const {
      subarchetype: { phases },
    } = this.props;
    const keys = phases.map(phase => phase.phaseID.toString());
    this.handleAccordionChange(keys);
  };

  handleCloseAllPhases = () => this.handleAccordionChange([]);

  render() {
    const { accordionOpenKeys } = this.state;
    const { subarchetype } = this.props;

    return (
      <div className="timeframe-subarchetype">
        <div className="subarchetype-header">
          <h3 className="subarchetype-header__title">{subarchetype.name}</h3>
          <div className="subarchetyle-header__controls">
            {subarchetype.phases.length !== accordionOpenKeys.length ? (
              <Button onClick={this.handleOpenAllPhases}>Open All Phases</Button>
            ) : (
              <Button onClick={this.handleCloseAllPhases}>Close All Phases</Button>
            )}
          </div>
        </div>
        <Collapse
          onChange={this.handleAccordionChange}
          activeKey={accordionOpenKeys}
          defaultActiveKey={accordionOpenKeys}
        >
          {subarchetype.phases.map(phase => (
            <Panel header={phase.name} key={phase.phaseID} className="timeframe-phase">
              {phase.activities.map((activity, k) => {
                const {
                  name,
                  earliestFinish,
                  earliestStart,
                  latestFinish,
                  latestStart,
                  maximumOffset,
                  minimumOffset,
                } = activity;

                return (
                  <div key={`${activity.name + k}`} className="timeframe-activity">
                    <div className="name">{name}</div>
                    <div className="formulas">
                      <div className="labeled-field">
                        <div className="label">Earliest Start</div>
                        <div className="value">{earliestStart || "-"}</div>
                      </div>
                      <div className="labeled-field">
                        <div className="label">Earliest Finish</div>
                        <div className="value">{earliestFinish || "-"}</div>
                      </div>
                      <div className="labeled-field">
                        <div className="label">Latest Start</div>
                        <div className="value">{latestStart || "-"}</div>
                      </div>
                      <div className="labeled-field">
                        <div className="label">Latest Finish</div>
                        <div className="value">{latestFinish || "-"}</div>
                      </div>
                      <div className="labeled-field">
                        <div className="label">Minimum Offset</div>
                        <div className="value">{minimumOffset || "-"}</div>
                      </div>
                      <div className="labeled-field">
                        <div className="label">Maximum Offset</div>
                        <div className="value">{maximumOffset || "-"}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Panel>
          ))}
        </Collapse>
      </div>
    );
  }
}

export default ActivityTimeframesSubarchetype;
