import React, { Component } from "react";
import moment from "moment";
import { Button, Card, Icon, Modal, Spin, Table } from "antd";
import { find } from "lodash";
import PropTypes from "prop-types";
import { FacilitiesType, FacilityFunctionPropType } from "types/facility";
import CountryType from "types/country";
import ComplexType from "types/complex";
import SubArchetypeType from "types/subArchetype";
import FacilitiesFilter from "components/FacilitiesFilter";
import FacilitiesListHeader from "components/FacilitiesListHeader";
import CreateFacility from "components/CreateFacility";
import UploadFacilities from "components/UploadFacilities";
import FacilitiesMap from "components/FacilitiesMap";

import "./style.less";

export default class FacilitiesList extends Component {
  static propTypes = {
    complexes: PropTypes.arrayOf(ComplexType).isRequired,
    countries: PropTypes.arrayOf(CountryType).isRequired,
    country: CountryType.isRequired,
    facilities: FacilitiesType.isRequired,
    facilitiesLoading: PropTypes.bool.isRequired,
    facilityFunctions: PropTypes.arrayOf(FacilityFunctionPropType).isRequired,
    isAddingToPortfolio: PropTypes.bool.isRequired,
    onAddComplex: PropTypes.func.isRequired,
    onAddToPortfolio: PropTypes.func.isRequired,
    onCountrySelect: PropTypes.func.isRequired,
    onCreateFacility: PropTypes.func.isRequired,
    onRefreshFacilities: PropTypes.func.isRequired,
    subArchetypes: PropTypes.arrayOf(SubArchetypeType).isRequired,
  };

  state = {
    createModalKey: null,
    filters: FacilitiesFilter.getEmptyFilters(),
    searchText: "",
    selectedRowKeys: [],
    selectedRows: [],
    showCreateModal: false,
    showUploadModal: false,
    sortedInfo: {},
    viewAsMap: false,
  };

  handleRowChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  rowClassName = record => (record.isInPortfolio ? "disabled" : "");

  handleFilterChange = newFilters => {
    this.setState({ filters: newFilters });
  };

  handleSearchChange = newSearch => {
    this.setState({ searchText: newSearch });
  };

  handleViewChange = e => {
    this.setState({ viewAsMap: e.target.value === "map" });
  };

  handleShowCreateModal = () => {
    this.setState({ createModalKey: Math.random(), showCreateModal: true });
  };

  handleCloseCreateModal = () => {
    this.setState({ showCreateModal: false });
  };

  handleCreateConfirm = facility => {
    this.props.onCreateFacility(facility);
    this.setState({ showCreateModal: false });
  };

  handleShowUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  handleCloseUploadModal = () => {
    this.setState({ showUploadModal: false });
  };

  handleUploadDone = () => {
    this.props.onRefreshFacilities();
  };

  formatData = filteredFacilities =>
    filteredFacilities.map(facility => {
      const { facilityFunctions } = this.props;
      const { CoPDate, FacilityFunctionID } = facility;
      const facilityFunction = find(facilityFunctions, ff => ff.FacilityFunctionID === FacilityFunctionID);

      return {
        ...facility,
        key: facility.FacilityID,
        CoPDate: moment.utc(CoPDate).format("YYYY-MM-DD"),
        Function: facilityFunction ? facilityFunction.Name : "no function",
      };
    });

  handleTableChange = (pagination, filters, sorter) => this.setState({ sortedInfo: sorter });

  handleTableSort = key => (a, b) => (a[key] < b[key] ? -1 : 1);

  gatherFacilitiesInRange = selectedRows => {
    const { facilities } = this.props;
    const COP_OFFSET = 3;

    const years = selectedRows.map(row => row.CoPDate.substring(0, 4));
    const earliestCoPYear = Math.min(...years) - COP_OFFSET;
    const latestCoPYear = Math.max(...years) + COP_OFFSET;

    const facilitiesInRange = [];

    facilities.forEach(facility => {
      const { CoPDate, isInPortfolio } = facility;
      const CoPYear = Number(CoPDate.substring(0, 4));

      if (earliestCoPYear <= CoPYear && CoPYear <= latestCoPYear && !isInPortfolio) {
        facilitiesInRange.push(facility);
      }
    });

    return facilitiesInRange;
  };

  handleAddToPortfolio = selectedRows => () => {
    const { onAddToPortfolio } = this.props;
    const selectedIDs = selectedRows.map(r => r.FacilityID);

    const facilitiesInRange = this.gatherFacilitiesInRange(selectedRows);

    const facilityIDsInRange = facilitiesInRange.map(sf => sf.FacilityID);

    const similarFacilityCount = facilitiesInRange.length - selectedIDs.length;
    if (similarFacilityCount > 0) {
      const content =
        similarFacilityCount > 1
          ? `We found ${similarFacilityCount} Facilities with similar CoP Dates. Would you like to add them as well?`
          : "We found a Faclity with a similar CoP Date. Would you like to add it as well?";

      Modal.confirm({
        title: `${similarFacilityCount} Facilities found within CoP Range`,
        content,
        okText: "Yes",
        cancelText: "No",
        onOk() {
          onAddToPortfolio(facilityIDsInRange);
        },
        onCancel() {
          onAddToPortfolio(selectedIDs);
        },
      });
    } else {
      onAddToPortfolio(selectedIDs);
    }
  };

  renderMarkerFooter = facility => {
    return facility.isInPortfolio ? (
      <div className="in-portfolio">This facility cannot be added because it is already in your portfolio.</div>
    ) : (
      <Button type="primary" onClick={this.handleAddToPortfolio([facility])}>
        Add to Portfolio
      </Button>
    );
  };

  render() {
    const {
      complexes,
      countries,
      country,
      facilities,
      facilitiesLoading,
      facilityFunctions,
      isAddingToPortfolio,
      onAddComplex,
      subArchetypes,
    } = this.props;

    const {
      createModalKey,
      filters,
      searchText,
      selectedRowKeys,
      selectedRows,
      showCreateModal,
      showUploadModal,
      sortedInfo,
      viewAsMap,
    } = this.state;

    const filteredFacilities = this.formatData(FacilitiesFilter.applyToList(facilities || [], filters, searchText));

    const rowSelection = {
      getCheckboxProps: record => ({ disabled: record.isInPortfolio, name: record.Name }),
      onChange: this.handleRowChange,
      selectedRowKeys,
    };

    return (
      <Card
        className="facilities-card"
        title={
          <FacilitiesListHeader
            actions={
              <>
                {selectedRows.length >= 1 && (
                  <Button
                    className="facilities-action-button"
                    type="primary"
                    onClick={this.handleAddToPortfolio(selectedRows)}
                  >
                    {selectedRows.length > 1 ? `Add (${selectedRows.length}) to Portfolio` : "Add to Portfolio"}
                  </Button>
                )}
                {country && (
                  <Button className="facilities-action-button" type="primary" onClick={this.handleShowCreateModal}>
                    Create Facility
                  </Button>
                )}
                <Button className="facilities-action-button" onClick={this.handleShowUploadModal}>
                  Import Facilities…
                </Button>
              </>
            }
            countries={countries}
            country={country}
            facilities={facilities}
            filteredFacilities={filteredFacilities}
            filters={filters}
            onCountrySelect={this.props.onCountrySelect}
            onFilterChange={this.handleFilterChange}
            onSearchChange={this.handleSearchChange}
            onViewChange={this.handleViewChange}
            searchText={searchText}
            title="Add Facilities to Portfolio"
            viewAsMap={viewAsMap}
          />
        }
      >
        {viewAsMap ? (
          <FacilitiesMap filteredFacilities={filteredFacilities} renderMarkerFooter={this.renderMarkerFooter} />
        ) : (
          <Table
            className="scrollable-table"
            loading={facilitiesLoading}
            pagination={false}
            rowClassName={this.rowClassName}
            rowSelection={rowSelection}
            dataSource={filteredFacilities}
            onChange={this.handleTableChange}
            useFixedHeader={true}
          >
            <Table.Column
              title="Name"
              dataIndex="Name"
              key="Name"
              render={(text, record) => (
                <span
                  title={
                    record.isInPortfolio ? "This facility cannot be added because it is already in your portfolio." : ""
                  }
                >
                  {text}
                </span>
              )}
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "Name" && sortedInfo.order}
            />
            <Table.Column
              title="COP Date"
              dataIndex="CoPDate"
              key="CoPDate"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "CoPDate" && sortedInfo.order}
              width="10em"
            />
            <Table.Column
              title="Water Depth"
              dataIndex="waterDepth"
              key="waterDepth"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "waterDepth" && sortedInfo.order}
              width="9.5em"
            />
            <Table.Column
              title="Topsides Total Weight"
              dataIndex="topsidesTotalWeight"
              key="topsidesTotalWeight"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "topsidesTotalWeight" && sortedInfo.order}
              width="14em"
            />
            <Table.Column
              title="Substructure Total Jacket Weight"
              dataIndex="substructureTotalJacketWeight"
              key="substructureTotalJacketWeight"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "substructureTotalJacketWeight" && sortedInfo.order}
              width="13em"
            />
            <Table.Column
              title="Function"
              dataIndex="Function"
              key="Function"
              sorter={this.handleTableSort(sortedInfo.columnKey)}
              sortOrder={sortedInfo.columnKey === "Function" && sortedInfo.order}
              width="13em"
            />
          </Table>
        )}
        <Modal
          centered
          visible={isAddingToPortfolio}
          wrapClassName="portfolio-list-modal-loading"
          closable={false}
          footer={null}
        >
          <Spin tip="Adding facilities to portfolio…" indicator={<Icon type="loading" />} />
        </Modal>
        <CreateFacility
          key={createModalKey}
          country={country}
          complexes={complexes}
          subArchetypes={subArchetypes}
          showCreateModal={showCreateModal}
          facilityFunctions={facilityFunctions}
          onAddComplex={onAddComplex}
          onCloseCreateModal={this.handleCloseCreateModal}
          onCreateConfirm={this.handleCreateConfirm}
        />
        <UploadFacilities
          visible={showUploadModal}
          onClose={this.handleCloseUploadModal}
          onUploadDone={this.handleUploadDone}
        />
      </Card>
    );
  }
}
