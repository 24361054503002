import { Input, Modal } from "antd";
import React from "react";

const openAddComplex = (CountryID, onAddComplex, onChangeComplex) => {
  let name = "";
  let modal;

  const handleChangeName = ({ target }) => {
    // Enable OK button only when the name is non-empty
    if (!name !== !target.value) {
      modal.update({ okButtonProps: { disabled: !target.value } });
    }
    name = target.value;
  };

  modal = Modal.confirm({
    title: "Name of new complex:",
    content: <Input onChange={handleChangeName} />,
    okText: "Create",
    okButtonProps: { disabled: true },
    onOk: () => {
      onAddComplex(CountryID, name)
        .then(ComplexID => {
          onChangeComplex(ComplexID);
        })
        .catch(err => {
          Modal.error({
            title: "Creating complex failed.",
            content: String(err.message),
          });
        });
    },
  });
};

export default openAddComplex;
