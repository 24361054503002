import React from "react";
import { Alert } from "antd";

const PasswordHelp = () => (
  <Alert
    message="Password must contain:"
    description={
      <ul>
        <li>A number</li>
        <li>A special character ex. !@#</li>
        <li>An uppercase letter</li>
        <li>A lowercase letter</li>
        <li>A minumum of 8 characters</li>
      </ul>
    }
    type="info"
  />
);

export default PasswordHelp;
